import React from "react";
import { useState } from "react";
import dateIcon from "../assets/images/date-icon.svg";
import { getHTMLdateFormat } from "../component/common";

import Cancel from "../assets/images/round_not_qualified.png";
import RightArrow from "../assets/images/right-arrow.svg";
import {
  getAllStateList,
  updateDOBNState,
  getUserProfile,
  sendOTP,
  validateOTP,
  checkEmail,
  addUserInGameName,
  getUserInGameName,
} from "../apis";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import validator from "validator";
import moment from "moment";
import Picker from "react-mobile-picker";

import { FiX } from "react-icons/fi";

import { addTracker } from "../component/tracker";

import { trackerEventId } from "../config/tracker";

function generateNumberArray(begin, end) {
  let array = [];
  for (let i = begin; i <= end; i++) {
    array.push((i < 10 ? "0" : "") + i);
  }
  return array;
}
function generateArrayOfYears() {
  var max = new Date().getFullYear() - 18;
  var min = max - 70;
  var years = [];

  for (var i = max; i >= min; i--) {
    years.push(i);
  }
  return years;
}
export default class DobNStates extends React.Component {
  constructor(props) {
    super(props);
    let month = ("0" + (new Date().getMonth() + 1)).slice(-2);
    let date = ("0" + new Date().getDate()).slice(-2);
    this.state = {
      dobns_data: [],

      state_data: [],
      timer: 30,
      emailTimer: 30,
      time: [],
      phone: "",
      phoneCode: "",
      otp: "",
      emailotp: "",
      email: "",
      dob_value: "",
      state_value: { _id: "", name: "" },
      in_game_name: "",

      tag_phone: false,
      tag_phone_verified: false,
      tag_send_code: false,
      tag_verify_code: false,
      tag_email: false,
      tag_dob: false,
      tag_state: false,
      tag_in_game_name: false,
      tag_state_popup: false,
      tag_email_send_code: false,
      tag_email_verify_code: false,
      tag_email_verified: false,
      isPickerShow: false,
      valueGroups: {
        year: new Date().getFullYear() - 18,
        month: month.toString(),
        day: date.toString(),
      },
      optionGroups: {
        year: generateArrayOfYears(),
        month: generateNumberArray(1, 12),
        day: generateNumberArray(1, 31),
      },
    };

    this.onClickSubmit = this.onClickSubmit.bind(this);
  }

  componentDidMount() {
    this.count = setInterval(() => {
      if (this.state.timer > 0) {
        this.setState({ timer: this.state.timer - 1 });
      }
    }, 1000);

    this.countEmail = setInterval(() => {
      if (this.state.emailTimer > 0) {
        this.setState({ emailTimer: this.state.emailTimer - 1 });
      }
    }, 1000);

    var temp_dobns_data = JSON.parse(localStorage.getItem("loginData"));

    this.setState({
      dobns_data: temp_dobns_data,
    });
    if (this.props.screen === "tournaments-contests") {
      var temp_in_game_name = null;
      var temp_game_name = false;

      var tempGameData = JSON.parse(localStorage.getItem("userInGameNames"));
      if (tempGameData !== null) {
        let index = tempGameData.findIndex(
          (a) => a.game === localStorage.getItem("activeGameId")
        );
        if (index !== -1) {
          temp_in_game_name = tempGameData[index].userINGameName;
        } else {
          temp_game_name = true;
        }
      } else {
        temp_game_name = true;
      }

      this.setState({
        in_game_name: temp_in_game_name, // temp_in_game_name
        tag_email: false,
        tag_in_game_name: temp_game_name, // temp_game_name
        tag_email_verified: true, //if popup open for ingame name
        tag_phone_verified: true, //if popup open for ingame name
      });

      this.addTrackData(
        `gameCatalogName=${localStorage.getItem(
          "activeGame"
        )}&gameCatalogId=${localStorage.getItem("activeGameId")}&eventId=${
          trackerEventId?.IGN_Popup_Shown
        }`
      );
    } else if (
      this.props.screen === "coin-store-buy-item" ||
      this.props.screen === "coin-store-redeem-item"
    ) {
      var temp_email = temp_dobns_data.item?.email;
      var temp_email_verified = temp_dobns_data.item?.isEmailVerified;
      var temp_tag_email = false;
      var temp_tag_email_verified = false;

      if (temp_email === null || temp_email === undefined) {
        temp_email = "";
        temp_tag_email = true;
      }
      if (temp_email_verified === false) {
        temp_tag_email = true;
        // temp_tag_email_verified = true;
        // this.onClickSendCodeEmail()
      }

      this.setState({
        tag_email_send_code: false,
        email: temp_email, // temp_email
        tag_email: temp_tag_email, // temp_tag_email
        tag_email_verified: temp_email_verified, // temp_tag_email_verified
      });

      // phone
      var temp_phone = temp_dobns_data.item?.phone;
      var temp_phone_verified = temp_dobns_data.item?.isMobileVerified;
      var temp_tag_phone = false;
      var temp_tag_phone_verified = false;

      if (temp_phone === null || temp_phone === undefined) {
        temp_phone = "";
        temp_tag_phone = true;
      }
      if (temp_phone_verified === false) {
        temp_tag_phone = true;
        temp_tag_phone_verified = true;
      }
      this.setState({
        tag_phone_send_code: false,
        phone: temp_phone, // temp_phone
        tag_phone: temp_tag_phone, // temp_tag_phone
        tag_phone_verified: temp_phone_verified, // temp_tag_phone_verified
        tag_in_game_name: false,
      });
    }

    this.getAllStateListAPICall();
  }

  addTrackData = async (string) => {
    if (localStorage.getItem("loginData")) {
      let userData = JSON.parse(localStorage.getItem("loginData"));
      await addTracker(`userid=${userData?.userId}&${string}`);
    }
  };

  onClickSubmit() {
    var check = true;

    if (
      this.state.tag_email &&
      (this.state.email === "" || !validator.isEmail(this.state.email))
    ) {
      check = false;
      this.showError("Please enter valid email address!");
    }

    if (this.state.tag_phone) {
      check = false;
      this.showError("Please verify mobile number");
    }

    if (this.state.tag_dob && this.state.dob_value === "") {
      check = false;

      this.showError("Please enter your date of birth!");
    }

    if (this.state.tag_state && this.state.state_value.name === "") {
      check = false;
      this.showError("Please enter your state!");
    }
    if (
      this.state.tag_in_game_name &&
      (this.state.in_game_name === "" || this.state.in_game_name == null)
    ) {
      check = false;
      this.showError("Please enter game name!");
    }

    if (check) {
      var payload = {
        gamerjiName: this.state.dobns_data.item?.gamerjiName,
      };

      if (this.state.tag_email) {
        payload.email = this.state.email;
      }
      payload.dateOfBirth = getHTMLdateFormat(this.state.dob_value);
      if (this.state.tag_state) {
        payload.address = {
          state: this.state.state_value._id,
        };
      }

      if (this.state.tag_in_game_name) {
        let temp_game_names = this.state.dobns_data?.gameNames || [];

        temp_game_names.push({
          game:
            localStorage.getItem("activeGameId") ||
            JSON.parse(localStorage.getItem("scrimData"))?.gameType,
          uniqueIGN: this.state.in_game_name,
        });
        payload.gameNames = temp_game_names;
      }

      this.addIngameName(this.state.in_game_name);
    }
  }
  async onClickSendCodeEmail() {
    var check = true;
    if (this.state.email === "") {
      check = false;
      this.showError("Please enter your email!");
    } else if (check) {
      var payload = {
        email: this.state.email,
        type: "otpRequest",
      };
      let response = await checkEmail(payload);

      if (response) {
        if (response?.success) {
          this.setState({
            tag_email_send_code: true,
            tag_email_verify_code: true,
            emailTimer: 30,
          });
        } else this.showError(response?.data?.errors?.[0]?.msg);
      }
    }
  }

  handleInputChange = (e) => {
    this.setState({ emailotp: e.target.value });
    const inputValue = e.target.value;

    if (inputValue.length === 6) {
      this.onClickVerifyEmailCode(inputValue);
    }

    this.setState({ inputValue });
  };
  handleInputChangeOTP = (e) => {
    this.setState({ otp: e.target.value });
    const inputValue = e.target.value;
    setTimeout(() => {
      if (inputValue.length === 6) {
        this.onClickVerifyCode();
      }
    }, 1500);

    this.setState({ inputValue });
  };

  onClickSendCode() {
    this.setState({ tag_send_code: true, tag_verify_code: false, timer: 30 });
    var check = true;
    if (this.state.tag_phone && this.state.phone === "") {
      check = false;
      this.showError("Please enter your phone!");
    }

    if (check) {
      var payload = {
        phone: this.state.phone,
        phoneCode: this.state.phoneCode,
      };

      this.sendOTPAPICall(payload);
    }
  }

  onClickVerifyEmailCode(emailotp) {
    var check = true;
    if (this.state.tag_verify_code && emailotp === "") {
      check = false;
      this.showError("Please enter code!");
    }

    if (check) {
      var payload = {
        email: this.state.email,
        otp: emailotp,
        type: "validateOTP",
      };

      this.validateEmailOTPAPICall(payload);
    }
  }
  onClickVerifyCode() {
    var check = true;
    if (this.state.tag_verify_code && this.state.otp === "") {
      check = false;
      this.showError("Please enter code!");
    }

    if (check) {
      var payload = {
        phone: this.state.phone,
        phoneCode: this.state.phoneCode,
        otp: this.state.otp,
      };

      this.validateOTPAPICall(payload);
    }
  }

  async getAllStateListAPICall() {
    let response = await getAllStateList({
      skip: 0,
      limit: 100,
      sortBy: "name",
      sort: "asc",
    });

    if (response && "list" in response)
      this.setState({ state_data: response.list });
  }

  async sendOTPAPICall(payload) {
    let response = await sendOTP(payload);
    if (response) {
      if (response?.success === true) {
        this.setState({
          tag_send_code: false,
          tag_verify_code: true,
          tag_phone_verified: true,
        });
      } else this.showError(response?.data?.errors?.[0]?.msg);
    }
  }

  async sendEmailOTPAPICall(payload) {
    let response = await checkEmail(payload);

    if (response) {
      if (response) {
        this.setState({
          tag_email_send_code: false,
          tag_email_verify_code: true,
        });
      } else this.showError(response?.data?.errors?.[0]?.msg);
    }
  }

  async validateOTPAPICall(payload) {
    let response = await validateOTP(payload);

    if (response) {
      if (response?.success === true) {
        this.setState({
          tag_phone_send_code: false,
          tag_verify_code: false,
          tag_phone_verified: true,
        });

        let res = await getUserProfile();
        if (res) {
          localStorage.setItem("gamerjiLogindetails", JSON.stringify(res));
          this.setState({ tag_phone: false });
          if (
            !this.state.tag_phone &&
            !this.state.tag_dob &&
            !this.state.tag_state &&
            !this.state.tag_in_game_name
          ) {
            this.props.submitDobNstate(false);
          }
        } else {
          this.showError("Please enter required details!");
        }
        this.showSuccess("Code is verified!!");
      } else {
        this.showError(response?.data?.errors?.[0]?.msg);
      }
    }
  }

  async validateEmailOTPAPICall(payload) {
    let response = await checkEmail(payload);

    if (response) {
      if (response?.success === true) {
        this.setState({
          tag_email_send_code: false,
          tag_email_verify_code: false,
          tag_email_verified: true,
        });
        let res = await getUserProfile();
        if (res) {
          localStorage.setItem("gamerjiLogindetails", JSON.stringify(res));
          this.setState({ tag_email: false, tag_email_verified: true });

          if (
            !this.state.tag_email &&
            !this.state.tag_dob &&
            !this.state.tag_state &&
            !this.state.tag_in_game_name
          ) {
            this.props.submitDobNstate(false);
          }
        } else {
          this.showError("Please enter required details!");
        }
        this.showSuccess("Code is verified!!");
      } else {
        this.showError(response?.data?.errors?.[0]?.msg);
      }
    }
  }

  async updateDOBNStateAPICall(data) {
    let response = await updateDOBNState(data);

    let res = await getUserProfile();
    if (res) {
      localStorage.setItem("gamerjiLogindetails", JSON.stringify(res));
    } else {
      this.showError("Please enter required details!");
    }
  }

  async addIngameName(name) {
    var user_data = JSON.parse(localStorage.getItem("loginData"));

    let payload = {
      user: user_data?.user_id,
      game: localStorage.getItem("activeGameId"),
      userINGameName: name,
    };

    let res = await addUserInGameName(payload);
    if (res) {
      this.GetInGameNames();
    }
  }

  async GetInGameNames() {
    var user_data = JSON.parse(localStorage.getItem("loginData"));

    let payload = {
      search: {
        user: user_data?.user_id,
      },
    };

    // let inGameNames = [
    //   {
    //     game: localStorage.getItem("activeGameId"),
    //     userINGameName: this.state.in_game_name,
    //   },
    // ];

    // localStorage.setItem("userInGameNames", JSON.stringify(inGameNames));

    let userInGameNameResponse = await getUserInGameName(payload);
    if (userInGameNameResponse?.success)
      localStorage.setItem(
        "userInGameNames",
        JSON.stringify(userInGameNameResponse?.data)
      );
    this.props.submitDobNstate(false);
  }

  showError(error) {
    toast.error(error, {
      position: "top-left",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  showSuccess(error) {
    toast.success(error, {
      position: "top-left",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  gobacktoHome() {
    this.props.cancelDobNstate();
  }

  createStateInput() {
    return (
      <div className="dobns_state_div">
        <div className="dobns_state_popup">
          <div className="dobns_header">
            <div className=""></div>
            <div className="dobns_header_text">Select State</div>
            <div className="dobns_cancel_icon">
              <img
                src={Cancel}
                onClick={(e) => {
                  this.setState({ tag_state_popup: false });
                }}
              />
            </div>
          </div>

          <div className="dobns_state_body">
            <div className="dobns_state_body_scrollable_component">
              {this.state.state_data.map((value, i) => {
                return (
                  <div
                    className="dobns_state_list_text"
                    key={i}
                    onClick={() => {
                      this.setState({ state_value: value });
                      this.setState({ tag_state_popup: false });
                    }}
                  >
                    {value.name}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
  handleChange = (name, value) => {
    let selectedDate = { ...this.state.valueGroups, [name]: value };
    this.setState({
      dob_value:
        selectedDate.year + "-" + selectedDate.month + "-" + selectedDate.day,
    });
    this.setState(({ valueGroups, optionGroups }) => {
      const nextState = {
        valueGroups: {
          ...valueGroups,
          [name]: value,
        },
      };
      if (name === "year" && valueGroups.month === "02") {
        const yearValue = parseInt(value);
        if (
          (yearValue % 4 === 0 && yearValue % 100 !== 0) ||
          yearValue % 400 === 0
        ) {
          nextState.optionGroups = {
            ...optionGroups,
            day: generateNumberArray(1, 29),
          };
        } else {
          nextState.optionGroups = {
            ...optionGroups,
            day: generateNumberArray(1, 28),
          };
        }
      } else if (name === "month") {
        if (value === "02") {
          nextState.optionGroups = {
            ...optionGroups,
            day: generateNumberArray(1, 28),
          };
        } else if (
          ["01", "03", "05", "07", "08", "10", "12"].indexOf(value) > -1 &&
          ["01", "03", "05", "07", "08", "10", "12"].indexOf(
            valueGroups.month
          ) < 0
        ) {
          nextState.optionGroups = {
            ...optionGroups,
            day: generateNumberArray(1, 31),
          };
        } else if (
          ["01", "03", "05", "07", "08", "10", "12"].indexOf(value) < 0 &&
          ["01", "03", "05", "07", "08", "10", "12"].indexOf(
            valueGroups.month
          ) > -1
        ) {
          nextState.optionGroups = {
            ...optionGroups,
            day: generateNumberArray(1, 30),
          };
        }
      }
      return nextState;
    });
  };

  togglePicker = () => {
    this.setState(({ isPickerShow }) => ({
      isPickerShow: !isPickerShow,
    }));
  };

  render() {
    const { isPickerShow, optionGroups, valueGroups } = this.state;
    const maskStyle = {
      display: isPickerShow ? "block" : "none",
    };
    const pickerModalClass = `picker-modal${
      isPickerShow ? " picker-modal-toggle" : ""
    }`;
    return (
      <div className="dobns_main_div">
        {this.state.tag_state_popup === true ? this.createStateInput() : ""}
        {this.state.tag_email ||
        this.state.tag_phone ||
        this.state.tag_dob ||
        this.state.tag_state ||
        this.state.tag_in_game_name ? (
          // || this.state.tag_email_verified
          <div className="dobns_popup bg-black">
            <div className="dobns_header">
              <div className=""></div>
              <div className="dobns_header_text">Basic Profile</div>
              <div className="dobns_cancel_icon">
                {/* <img
                  src={Cancel}
                  onClick={(e) => {
                    this.gobacktoHome();
                  }}
                /> */}
                <FiX
                  onClick={(e) => {
                    this.gobacktoHome();
                  }}
                />
              </div>
            </div>

            <div className="dobns_body bg-black">
              <div className="all_sub_screens_div_related_to_ads">
                <div className="dobns_body_scrollable_component">
                  {/* Date Of Birth */}

                  {this.state.tag_dob ? (
                    <div className="dobns_basic_profile_inputs">
                      <div className="dobns_text">
                        What's your date of birth ?
                      </div>
                      <div
                        className="dobns_input_component"
                        onClick={this.togglePicker}
                      >
                        <input
                          className="dobns_input"
                          type="date"
                          max={moment()
                            .subtract(18, "years")
                            .format("YYYY-MM-DD")}
                          placeholder="Select your date of birth"
                          // disabled
                          // value={this.state.dob_value}
                          // onChange={this.handleChange}
                          onChange={(e) => {
                            this.setState({ dob_value: e.target.value });
                          }}
                          value={getHTMLdateFormat(this.state.dob_value)}
                        />
                        <div className="dobns_icon">
                          {/* <img src={dateIcon} alt="n" /> */}
                        </div>
                      </div>
                      {/* <div className="picker-modal-container" style={maskStyle}>
                      <div className="picker-modal-mask"  ></div>
                      <div className={pickerModalClass}>
                        <div className="ios-datepicker">
                          <div className="ios-datepicker-title">Select Date of Birth</div>
                          <a href="javascript:;" onClick={this.togglePicker}>Confirm</a>
                        </div>
                        <Picker
                          optionGroups={optionGroups}
                          valueGroups={valueGroups}
                          onChange={this.handleChange} />
                      </div>
                    </div> */}
                      <div className="dobns_error_div">
                        <div
                          id="dobns_error_div_dob"
                          style={{ display: "none" }}
                        >
                          Please enter your date of birth
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {this.state.tag_state ? (
                    <div className="dobns_basic_profile_inputs">
                      <div className="dobns_text">Where are you from?</div>
                      <div
                        className="dobns_input_component"
                        style={{ cursor: "pointer", height: "51px" }}
                        onClick={() => {
                          this.setState({ tag_state_popup: true });
                        }}
                      >
                        <div className="dobns_input">
                          {this.state.state_value.name === "" ||
                          this.state.state_value.name === undefined ||
                          this.state.state_value.name === null
                            ? "Select your state"
                            : this.state.state_value.name}
                        </div>
                        <div className="dobns_icon">
                          <svg
                            className=""
                            width="25"
                            height="25"
                            viewBox="0 0 45 45"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              id=""
                              d="M34 18.34a2 2 0 0 0-2.82 0L24 25.42l-7.08-7.08a2 2 0 1 0-2.82 2.84l8.48 8.48a2.003 2.003 0 0 0 2.19.438c.243-.101.464-.25.65-.438L34 21.18a2 2 0 0 0 0-2.84z"
                              fill="#D3D3D3"
                            ></path>
                          </svg>
                        </div>
                      </div>
                      <div className="dobns_error_div">
                        <div
                          id="dobns_error_div_state"
                          style={{ display: "none" }}
                        >
                          Please enter your state
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.tag_phone && (
                    // (this.state.tag_send_code || this.state.tag_verify_code) ? (
                    <div className="dobns_basic_profile_inputs">
                      <div className="dobns_text">What's your phone?</div>
                      <div className="dobns_input_component">
                        <div
                          className="pr-2 border-right"
                          style={{ fontSize: "18px" }}
                        >
                          {this.state.phoneCode}
                        </div>
                        <input
                          className="dobns_input pl-2"
                          type="number"
                          onKeyDown={(e) =>
                            ["e", "E", "+", "-"].includes(e.key) &&
                            e.preventDefault()
                          }
                          placeholder={`Enter your phone`}
                          onChange={(e) => {
                            this.setState({ phone: e.target.value });
                          }}
                          value={this.state.phone}
                          disabled={
                            this.state.tag_send_code &&
                            this.state.tag_verify_code
                            // this.state.tag_verify_code
                          }
                        />
                        <div
                          className=" verify "
                          onClick={() => this.onClickSendCode()}
                        >
                          Verify
                        </div>
                      </div>
                      <div className="dobns_error_div">
                        <div
                          id="dobns_error_div_game_name"
                          style={{ display: "none" }}
                        >
                          Please enter phone
                        </div>
                      </div>
                    </div>
                  )}

                  {this.state.tag_verify_code ? (
                    <div className="dobns_basic_profile_inputs">
                      <div className="dobns_text">Verification Code</div>
                      <div className="dobns_input_component">
                        <input
                          className="dobns_input"
                          type="text"
                          placeholder={`Enter verification code`}
                          maxLength="6"
                          onChange={this.handleInputChangeOTP}
                          value={this.state.otp}
                        />
                      </div>
                      <div className="dobns_error_div">
                        <div
                          id="dobns_error_div_game_name"
                          style={{ display: "none" }}
                        >
                          Please enter verification code
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {this.state.tag_verify_code && (
                    <div>
                      <p
                        className="text-center"
                        style={{ marginBottom: "0.5rem" }}
                      >
                        Didn't receive the Verification Code?
                      </p>
                      {this.state.timer === 0 ? (
                        <p className="text-center">
                          <a
                            className="text-secondary pointer"
                            onClick={() => this.onClickSendCode()}
                          >
                            <b>
                              <u>Resend Verification Code</u>
                            </b>
                          </a>
                        </p>
                      ) : (
                        <p className="text-center">
                          Request for a new one in {this.state.timer} Seconds
                        </p>
                      )}
                    </div>
                  )}
                  {this.state.tag_email && (
                    <div className="dobns_basic_profile_inputs">
                      <div className="dobns_text">Email </div>
                      <div className="dobns_input_component">
                        <input
                          className="dobns_input pl-2"
                          type="text"
                          placeholder={`Enter your email`}
                          onChange={(e) => {
                            this.setState({ email: e.target.value });
                          }}
                          value={this.state.email}
                          // disabled={
                          //   !this.state.tag_email_send_code &&
                          //   !this.state.tag_email_verify_code
                          // }
                          // disabled={true}
                        />
                        {!this.state.tag_email_send_code && (
                          <div
                            className=" verify "
                            onClick={() => this.onClickSendCodeEmail()}
                          >
                            Verify
                          </div>
                        )}
                        {/* <div
                        className=" verify "
                        onClick={() => this.onClickSendCodeEmail()}
                      >
                        Verify
                      </div> */}
                      </div>
                      <div className="dobns_error_div">
                        <div
                          id="dobns_error_div_game_name"
                          style={{ display: "none" }}
                        >
                          Please enter email
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.tag_email_send_code ? (
                    <div className="dobns_basic_profile_inputs">
                      <div className="dobns_text">Verification Code</div>
                      <div className="dobns_input_component">
                        <input
                          className="dobns_input"
                          type="text"
                          placeholder={`Enter verification code`}
                          // onChange={(e) => {
                          //   this.setState({ emailotp: e.target.value });
                          // }}
                          onChange={this.handleInputChange}
                          maxLength="6"
                          value={this.state.emailotp}
                        />
                      </div>
                      <div className="dobns_error_div">
                        <div
                          id="dobns_error_div_game_name"
                          style={{ display: "none" }}
                        >
                          Please enter verification code
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {this.state.tag_email_verify_code && (
                    <div>
                      <p
                        className="text-center"
                        style={{ marginBottom: "0.5rem" }}
                      >
                        Didn't receive the Verification Code?
                      </p>
                      {this.state.emailTimer === 0 ? (
                        <p className="text-center">
                          <a
                            className="text-secondary pointer"
                            onClick={() => this.onClickSendCodeEmail()}
                          >
                            <b>
                              <u>Resend Verification Code</u>
                            </b>
                          </a>
                        </p>
                      ) : (
                        <p className="text-center">
                          Request for a new one in {this.state.emailTimer}{" "}
                          Seconds
                        </p>
                      )}
                    </div>
                  )}

                  {/* Active Game Name */}
                  {this.state.tag_in_game_name ? (
                    <div className="dobns_basic_profile_inputs">
                      <div className="dobns_text">
                        <span>
                          {" "}
                          Your {localStorage.getItem("activeGame")} Name
                        </span>
                        {/* Your {localStorage.getItem("activeGame")} Name */}
                      </div>
                      <div className="dobns_input_component">
                        <input
                          className="dobns_input"
                          type="text"
                          placeholder={`Enter your ${localStorage.getItem(
                            "activeGame"
                          )} name`}
                          onChange={(e) => {
                            this.setState({ in_game_name: e.target.value });
                          }}
                          value={this.state.in_game_name}
                        />
                      </div>
                      <div className="dobns_error_div">
                        <div
                          id="dobns_error_div_game_name"
                          style={{ display: "none" }}
                        >
                          Please enter game name
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.tag_in_game_name ? (
                    <>
                      <button
                        className="dobns_button cursor d-flex justify-content-center align-items-center bg-dark"
                        onClick={() => this.onClickSubmit()}
                      >
                        Submit
                      </button>
                    </>
                  ) : (
                    (this.state.tag_email ||
                      this.state.tag_dob ||
                      this.state.tag_state ||
                      this.state.tag_phone) && (
                      <>
                        <button
                          className="dobns_button cursor d-flex justify-content-center align-items-center bg-dark"
                          onClick={() => this.onClickSubmit()}
                          disabled={
                            !this.state.tag_email_verified ||
                            !this.state.tag_phone_verified
                          }
                        >
                          Submit
                        </button>
                      </>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

// export default DobNStates;
