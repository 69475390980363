import React, { Fragment, useEffect, useState, useRef } from "react";
import Header from "../component/header";
import Featured from "./featured";
import Allgames from "./allgames";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import {
  getDailyRewards,
  getListofDailyRewards,
  getUserProfile,
  homebanner,
  getCollectDailyRewards,
  getUpdateProfile,
  apkScreensList,
  bannerAdLog,
  getUserInGameName,
  getTournamentList,
  indiggLogin,
  indiggRefreshToken,
  getApplyContestCode,
  getApplyEventCode,
} from "../apis";
import { dateFormat, bannerRedirection } from "../component/common";
import url from "../constants/url";
import Loader from "../component/loader";
import DobNState from "./dob-n-state";
import Close from "../assets/images/close.svg";
import rightArrow from "../assets/images/right-arrow.svg";
import Checkmark from "../assets/images/checkmark.svg";
import Advertisement from "../component/advertisement";
import LanguagePopup from "./LanguagePopup";
import { handleTextAnimation } from "../component/textAnimation";
import roller from "../assets/images/rolling.gif";
// import { toast } from "react-toastify";
import { ToastContainer, toast } from "react-toastify";
import { FiX } from "react-icons/fi";

import ContestStructure from "../component/ContestStructure";
import TournamentsStructure from "../component/tournaments-structure";
import JoinViaPath from "./JoinViaPath";

import { useNavigate } from "react-router";

function HomePage(props) {
  const [allData, setAllData] = useState([]);
  const [allTournamentData, setAllTournamentData] = useState([]);
  const [rewardsList, setRewardsList] = useState([]);
  const [avatarData, setAvatarData] = useState({});
  const [loader, setLoader] = useState(false);
  const [dobnstate, setdobnstate] = useState(false);
  const [joinpopup, setjoinpopup] = useState(true);
  const [showDailyRewards, setShowDailyRewards] = useState(false);
  const [showAvatarInfo, setShowAvatarInfo] = useState(false);
  const [showContacts, setShowContacts] = useState(false);
  const [showPopupYes, setShowPopupYes] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [currentActiveDay, setCurrentActiveDay] = useState(-1);
  const [joinData, setJoinData] = useState({});
  const [tournamentJoin, setTournamnetJoin] = useState(false);

  const [showLanguagePopup, setShowLanguagePopup] = useState(true);
  const [isbusy, setIsBusy] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [currentName, setCurrentName] = useState("");
  const navigate = useNavigate();

  useEffect(async () => {
    refreshTokenApiCall();
  }, []);

  const refreshTokenApiCall = async () => {
    let refreshToken = localStorage.getItem("refreshToken");
    let accessToken = localStorage.getItem("accessToken");

    if (refreshToken) {
      setIsLogin(true);
      let res2 = await indiggRefreshToken(refreshToken);
      if (res2?.data?.access_token) {
        localStorage.setItem("accessToken", res2?.data?.access_token);
        localStorage.setItem("refreshToken", res2?.data?.refresh_token);
      }
    } else if (accessToken) {
      setIsLogin(true);
      let res2 = await indiggRefreshToken(accessToken);
      if (res2?.data?.access_token) {
        localStorage.setItem("accessToken", res2?.data?.access_token);
        localStorage.setItem("refreshToken", res2?.data?.refresh_token);
      }
    } else {
    }
  };

  useEffect(() => {
    // Get the query parameters from the current URL
    const urlSearchParams = new URLSearchParams(window.location.search);

    // Access specific query parameters by name
    const urlToken = urlSearchParams.get("token");
    const urlRefreshToken = urlSearchParams.get("refreshToken");
    const urlShortCode = urlSearchParams.get("path");
    const urlType = urlSearchParams.get("type");

    if (
      urlToken != undefined &&
      urlToken != "undefined" &&
      urlToken != null &&
      urlToken != "null" &&
      urlToken != ""
    ) {
      localStorage.setItem("accessToken", urlToken);
      fetchUserData(urlToken);
    }
    if (
      urlRefreshToken != undefined &&
      urlRefreshToken != "undefined" &&
      urlRefreshToken != null &&
      urlRefreshToken != "null" &&
      urlRefreshToken != ""
    ) {
      localStorage.setItem("refreshToken", urlRefreshToken);
    }
  }, []);

  // useEffect(() => {
  //   if(isLogin){
  //     // Get the query parameters from the current URL
  //     const urlSearchParams = new URLSearchParams(window.location.search);

  //     const urlShortCode = urlSearchParams.get("path");
  //     const urlType = urlSearchParams.get("type");

  //     if (
  //       urlShortCode != undefined &&
  //       urlShortCode != "undefined" &&
  //       urlShortCode != null &&
  //       urlShortCode != "null" &&
  //       urlShortCode != ""
  //     ) {
            
  //       if (JSON.parse(localStorage.getItem("loginData"))?.user_id) {
  //         joinViaInvitedCode(urlShortCode, urlType);
  //       } else {
  //         // window.location.href = "https://indi.gg/";
  //         console.log("log out ---------------------------------------->")
  //       }
  //     }
  //   }
  // }, [isLogin]);

  function removeParamsFromUrl(paramsToRemove) {
    // Get the current URL
    let url = new URL(window.location.href);
  
    // Get URLSearchParams object
    let params = url.searchParams;
  
    // Remove unwanted parameters
    paramsToRemove.forEach(param => params.delete(param));
  
    // Get the updated query string
    let updatedQueryString = params.toString();
  
    // Create a new URL with the updated query string
    let newUrl = url.origin + url.pathname + (updatedQueryString ? '?' + updatedQueryString : '');
  
    // Replace the current URL without triggering a page refresh
    window.history.replaceState(null, '', newUrl);
  }
  
  // const joinViaInvitedCode = async (urlShortCode, urlType) => {
  //   if (
  //     urlType != undefined &&
  //     urlType != "undefined" &&
  //     urlType != null &&
  //     urlType != "null" &&
  //     urlType != "" &&
  //     urlType != "contest"
  //   ) {
  //     joinViaInvitedCodeTournament(urlShortCode);
  //   } else {
  //     joinViaInvitedCodeContest(urlShortCode);
  //   }
  // };

  // const joinViaInvitedCodeContest = async (urlShortCode) => {
  //   setLoader(true);
  //   var user_data = JSON.parse(localStorage.getItem("loginData"));

  //   let payload = {
  //     search: {
  //       user: user_data?.user_id,
  //     },
  //   };

  //   let res = await getApplyContestCode(urlShortCode.toUpperCase(), payload);
  //   if (res?.data && res?.success) {
  //     removeParamsFromUrl(['path','type']);
  //     setLoader(false);

  //     let data = {};

  //     if (res?.data?.isSingle) {
  //       localStorage.setItem("activeGameId", res?.data?.game);
  //       localStorage.setItem("activeGame", res?.data?.gameAry?.[0]?.name);
  //       data = res?.data;
  //     } else {
  //       localStorage.setItem("activeGameId", res?.item?.saasGameId);
  //       localStorage.setItem("activeGame", res?.item?.game?.name);
  //       data = {
  //         id: res?.item?._id,
  //         img: url.imageUrl + res?.item?.featuredImage?.default, // not present
  //         coin: url.imageUrl + res?.item?.currency?.img?.default,
  //         title: res?.item?.title,
  //         contestsList: res?.item?.contestsList, // not present
  //         totalPlayers: res?.item?.totalPlayers,
  //         isJoined: res?.item?.isJoined,
  //         totalJoinedPlayers: res?.item?.totalJoinedPlayers,
  //         shortCode: res?.item?.shortCode,
  //         gameName: res?.item?.game?.name, // not present
  //         levelNote: res?.item?.game?.settings?.levelNote, // not present
  //         currency: res?.item?.currency,
  //         gameId: res?.item?.game?._id, // not present
  //         winners: res?.item?.totalWinners,
  //         gameType: res?.item?.gameType?.name,
  //         gameTypePlayers: res?.item?.gameType?.players,

  //         hostedby: res?.item?.host?.name,
  //         hostrate: res?.item?.host?.rate,
  //         entryFee: res?.item?.entryFee,
  //         titles: res?.item?.titles,
  //         rules: res?.item?.rules, // not present
  //         allrounds: res?.item?.rounds, // not present
  //         round: res?.item?.rounds?.length, // not present
  //         winningCoin: res?.item?.totalWinningsDistributedCoin, // not present
  //         startDate: res?.item?.startDate, // not present
  //         endDate: res?.item?.endDate, // not present
  //         roundDatetime: res?.item?.rounds[0]?.matches, // not present
  //         prizepool: res?.item?.prizepool,
  //         prizePool: res?.item?.prizePool,
  //         pointpool: res?.item?.pointpool,
  //         createdAt: dateFormat(res?.item?.createdAt), // not present
  //         updatedAt: dateFormat(res?.item?.updatedAt), // not present
  //         saasGameId: res?.item?.saasGameId,
  //       };
  //     }

  //     if (res?.type === "scrim") {
  //       toast.error("Contest not found.", {
  //         position: "top-left",
  //         autoClose: 2000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //     } else {
  //       setJoinData(data);
  //       setTournamnetJoin(true);
  //     }
  //   } else {
  //     var errormsg = res?.data?.errors?.[0]?.code == "scrime.validation.not_found" ? "Contest not found." : res.data.errors[0]?.msg
  //     toast.error(errormsg, {
  //       position: "top-left",
  //       autoClose: 2000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //     removeParamsFromUrl(['path','type']);
  //     setLoader(false);
  //   }
  // };

  // const joinViaInvitedCodeTournament = async (urlShortCode) => {
  //   setLoader(true);
  //   let data = {
  //     code: urlShortCode.toUpperCase(),
  //     user: JSON.parse(localStorage.getItem("loginData"))?.user_id,
  //   };

  //   let res = await getApplyEventCode(data);
  //   if (res?.item) {
  //     removeParamsFromUrl(['path','type']);
  //     setLoader(false);
  //     data = {};
  //     if (res?.item?.isSingle) {
  //       data = res?.item;
  //     } else {
  //       localStorage.setItem("activeGameId", res?.item?.gameAry[0]?._id);
  //       localStorage.setItem("activeGame", res?.item?.gameAry[0]?.name);
  //       data = {
  //         id: res?.item?._id,
  //         img: url.imageUrl + res?.item?.featuredImage?.default, // not present
  //         coin: url.imageUrl + res?.item?.currency?.img?.default,
  //         title: res?.item?.title,
  //         contestsList: res?.item?.contestsList, // not present
  //         totalPlayers: res?.item?.totalPlayers,
  //         isJoined: res?.item?.isJoined,
  //         totalJoinedPlayers: res?.item?.totalJoinedPlayers,
  //         shortCode: res?.item?.shortCode,
  //         gameName: res?.item?.gameAry[0]?.name, // not present
  //         levelNote: res?.item?.game?.settings?.levelNote, // not present
  //         currency: res?.item?.currency[0],
  //         gameId: res?.item?.gameAry[0]?._id, // not present
  //         winners: res?.item?.totalWinners,
  //         gameType: res?.item?.gameTypeAry?.name,
  //         gameTypePlayers: res?.item?.gameTypeAry?.players,
  //         gameTypeExtraPlayers: res?.item?.gameTypeAry?.extraPlayers, // not present
  //         hostedby: res?.item?.host?.name,
  //         hostrate: res?.item?.host?.rate,
  //         entryFee: res?.item?.entryFee,
  //         titles: res?.item?.titles,
  //         rules: res?.item?.rules, // not present
  //         allrounds: res?.item?.rounds, // not present
  //         round: res?.item?.rounds?.length, // not present
  //         winningCoin: res?.item?.totalWinningsDistributedCoin, // not present
  //         startDate: res?.item?.startDate, // not present
  //         endDate: res?.item?.endDate, // not present
  //         roundDatetime: res?.item?.rounds[0]?.matches, // not present
  //         prizepool: res?.item?.prizePoolInfo,
  //         prizePool: res?.item?.prizePool,
  //         pointpool: res?.item?.pointpool,
  //         createdAt: dateFormat(res?.item?.createdAt), // not present
  //         updatedAt: dateFormat(res?.item?.updatedAt), // not present
  //         saasGameId: res?.item?.gameAry[0]?._id,
  //       };
  //     }
  //     setJoinData(data);
  //     setTournamnetJoin(true);
  //   } else {
  //     var errormsg = res?.data?.errors?.[0]?.code == "scrime.validation.not_found" ? "Contest not found." : res.data.errors[0]?.msg
  //     toast.error(errormsg, {
  //       position: "top-left",
  //       autoClose: 2000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //     removeParamsFromUrl(['path','type']);
  //     setLoader(false);
  //   }
  // };

  const fetchUserData = async (urlToken) => {
    setLoader(true);
    setIsLogin(false);

    localStorage.clear();

    let res1 = await indiggLogin(urlToken);
    if (res1?.success) {
      removeParamsFromUrl(['token']);
      localStorage.setItem("accessToken", urlToken);
      localStorage.setItem("loginData", JSON.stringify(res1?.data));
      setIsLogin(true);
    } else {
      removeParamsFromUrl(['token']);
      refreshTokenApiCall();
    }

    let allpage = [];
    allpage.push(window.location.pathname);
    localStorage.setItem("currentPage", JSON.stringify(allpage));
    setLoader(false);
  };

  useEffect(() => {
    let tag = localStorage.getItem("languageData")
      ? JSON.parse(localStorage.getItem("languageData"))?.showLanguagePopup
      : true;
    if (tag === false) setShowLanguagePopup(false);
    else setShowLanguagePopup(true);

    // fetchData();
  }, []);

  const getTotalCoin = (wallet) => {
    let index = wallet?.findIndex((obj) => obj.currencyData?.code == "coin");

    if (index > -1) {
      return wallet[index].winningAmount ? wallet[index].winningAmount : 0;
    }
    return 0;
  };

  const getTotalDiamond = (wallet) => {
    let index = wallet?.findIndex((obj) => obj.currencyData?.code == "diamond");

    if (index > -1) {
      return wallet[index].winningAmount ? wallet[index].winningAmount : 0;
    }
    return 0;
  };

  const logout = () => {};

  const GetInGameNames = async () => {
    var user_data = JSON.parse(localStorage.getItem("loginData"));

    let payload = {
      search: {
        user: user_data?.user_id,
      },
    };

    let userInGameNameResponse = await getUserInGameName(payload);
    if (userInGameNameResponse?.success)
      localStorage.setItem(
        "userInGameNames",
        JSON.stringify(userInGameNameResponse.data)
      );
  };

  const onCollectRewards = async () => {
    setIsBusy(true);
    let payload = { dailyReward: selectedId };
    let res = await getCollectDailyRewards(payload);
    if (res) {
      let res1 = await getUserProfile();
      if (res1) {
        setIsBusy(false);
        localStorage.setItem("gamerjiLogindetails", JSON.stringify(res1));
        localStorage.setItem("currentCoin", getTotalCoin(res1?.item?.wallet));
        localStorage.setItem(
          "currentDiamond",
          getTotalDiamond(res1?.item?.wallet)
        );
        toast.success("Daily rewards collected", {
          position: "top-left",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        setIsBusy(false);
      }

      setShowDailyRewards(false);
    }
  };

  const getdobnstate = (val) => {
    setdobnstate(val);
    val === false && setjoinpopup(false);
  };

  const getAvatarInfo = (item) => {
    setAvatarData(item);
    setShowAvatarInfo(true);
  };

  const getShowContacts = (val) => {
    setShowContacts(val);
  };

  const getShowPopupYes = (val) => {
    setShowPopupYes(val);
  };

  // const createAfterApplyCodePopup = () => {
  //   return (
  //     <div className="tc_popup_bg">
  //       <div className="custom-popup home-featured show" id="contact_popup">
  //         <div className="popup-head">
  //           <h3>
  //             {joinData?.isSingle === true
  //               ? joinData.title
  //               : joinData?.gameName}
  //           </h3>
  //           <button
  //             className="close-popup"
  //             onClick={() => setTournamnetJoin(false)}
  //           >
  //             {/* <img src={Close} alt="" /> */}
  //             <FiX />
  //           </button>
  //         </div>
  //         <div className="popup-body mt-3">
  //           {joinData?.isSingle === true
  //             ? createContestSingleBox(joinData, 1)
  //             : createTournamentSingleBox(joinData, 1)}
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  // const createContestSingleBox = (data, index) => {
  //   return <ContestStructure allData={data} key={index} />;
  // };

  // const createTournamentSingleBox = (data, index) => {
  //   return <TournamentsStructure allFeaturedData={data} key={index} />;
  // };

  return (
    <Fragment>
      <JoinViaPath />
      <ToastContainer />
      {loader ? <Loader /> : ""}
      {dobnstate ? (
        <DobNState dobnstate={getdobnstate} />
      ) : (
        <div className="inner-wrap">
          <Header navMenuClick={props.navMenuClick} />
          <div
            className="body mCustomScrollbar _mCS_1"
            id={
              joinpopup === false ||
              showDailyRewards === true ||
              showAvatarInfo === true ||
              showContacts === true ||
              showPopupYes === true
                ? "popup_show"
                : ""
            }
          >
            <div className="all_main_screens_div_related_to_ads">
              {/* Featured Games */}
              {isLogin && (
                <Featured
                  dobnstate={getdobnstate}
                  joinpopup={joinpopup}
                  showContacts={getShowContacts}
                  showPopupYes={getShowPopupYes}
                />
              )}

              {/* Allgames */}
              <Allgames />

              {/* ******************** comment by jaimin start******************* */}
              {showDailyRewards === true && (
                <div
                  className="custom-popup bg-primary home-featured show reward_height"
                  id="contact_popup"
                >
                  <div className="popup-head ">
                    <h3>Daily Login Rewards</h3>
                    <button
                      className="close-popup"
                      onClick={() => setShowDailyRewards(false)}
                    >
                      <img src={Close} alt="" />
                    </button>
                  </div>
                  <div className="popup-body">
                    {rewardsList?.map((item, index) => {
                      var current =
                        parseInt(currentActiveDay) > parseInt(item?.day)
                          ? true
                          : false;
                      return (
                        <div
                          className={`row reward_main ${
                            selectedId === item._id ? "active_reward" : ""
                          }`}
                          key={index}
                        >
                          <div className="col-2 pr-1 reward_first mb-0">
                            <p>Day - {item?.day}</p>
                          </div>
                          <div className="col-2 pr-1 reward_second mb-0">
                            <h5 className="mb-0">
                              {item?.avatar ? (
                                <img
                                  src={
                                    url.imageUrl + item?.avatar?.img?.default
                                  }
                                  className="reward_img"
                                />
                              ) : (
                                <p> {item?.amount} </p>
                              )}
                            </h5>
                          </div>
                          <div
                            className="col-4 pr-1 reward_third mb-0"
                            style={{
                              borderRight:
                                selectedId === item._id || current === true
                                  ? "1px solid #ccc"
                                  : "unset",
                            }}
                          >
                            {item?.avatar ? (
                              <small>
                                {item?.avatar?.name}&nbsp;&nbsp;&nbsp;
                                <i
                                  className="fa fa-info"
                                  onClick={() => getAvatarInfo(item?.avatar)}
                                />
                              </small>
                            ) : (
                              <small> {item?.name} </small>
                            )}
                          </div>
                          <div className="col-4 pr-1 reward_fourth mb-0">
                            {current === true && (
                              <div className="inner last_inner">
                                <img src={Checkmark} alt="" /> Claimed
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <button
                    className="btn btn-dark main-btn next"
                    disabled={isbusy}
                    onClick={() => onCollectRewards()}
                  >
                    COLLECT REWARDS{" "}
                    {!isbusy ? (
                      <img src={rightArrow} alt="" className="mCS_img_loaded" />
                    ) : (
                      <img src={roller} alt="" style={{ maxWidth: 30 }} />
                    )}
                  </button>
                </div>
              )}

              {/* ******************** comment by jaimin end ******************* */}

              {showAvatarInfo === true && (
                <div
                  className="custom-popup bg-primary home-featured show"
                  id="contact_popup"
                >
                  <div className="popup-head ">
                    <h3>Daily Login Rewards</h3>
                    <button
                      className="close-popup"
                      onClick={() => setShowAvatarInfo(false)}
                    >
                      <img src={Close} alt="" />
                    </button>
                  </div>
                  <div className="popup-body avatar_reward">
                    <img src={url.imageUrl + avatarData?.img?.default} />
                    <h2>{avatarData?.name}</h2>
                  </div>
                </div>
              )}
            </div>

            {/* ******************** comment by jaimin start ******************* */}
            <Advertisement
              screen={
                localStorage.getItem("apk_screens_list") === null
                  ? { code: "no_results" }
                  : JSON.parse(localStorage.getItem("apk_screens_list"))
                      .allGames
              }
              screen_div_class_name=".all_main_screens_div_related_to_ads"
              screen_type="main"
            />
            {/* ******************** comment by jaimin end ******************* */}
          </div>
        </div>
      )}

      {/* {tournamentJoin === true && createAfterApplyCodePopup()} */}
    </Fragment>
  );
}

export default HomePage;
