import React, { useEffect, useState } from "react";
import Close from "../assets/images/close.svg";
// import { Link } from "react-router-dom";
import Lottie from "reactjs-lottie";
import { getWalletLimit, checkDateTime, onCreateContest } from "../apis";
import {
  formatAMPM,
  getTournamentDateTime,
  getProfiledateFormat,
  checkDobNstateConditions,
} from "../component/common";
import rightArrow from "../assets/images/right-arrow.svg";
import successCheck from "../assets/images/success.json";
import pay from "../assets/images/pay.svg";
import url from "../constants/url";
import { routehelp } from "../config/routehelp";
import DobNStates from "../screens/dobnstate";
import SquadRegistration from "../screens/SquadRegistration";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Advertisement from "../component/advertisement";
import { constants } from "../constants/constants";

import Loader from "../component/loader";

import { FiX } from "react-icons/fi";
import { useNavigate } from "react-router";

import { addTracker } from "../component/tracker";

import { trackerEventId } from "../config/tracker";

function ContestStructure(props) {
  // DobNState Screen Name
  const dobNstateScreenName = "tournaments-contests";

  const [allData, setAllData] = useState([]);
  const [allWinners, setAllWinners] = useState([]);
  const [walletUsage, setWalletUsageData] = useState(null);
  const [selectedFeaturedData, setselectedFeaturedData] = useState({});

  const [progressWidth, setProgressWidth] = useState(0);
  const [coin, setCoin] = useState(0);

  const [showdobnstate, setShowdobnstate] = useState(false);
  const [showWinners, setShowWinners] = useState(false);
  const [coinStore, setCoinStore] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  // Squad Registartion
  const [selectedPlayerData, setSelectedPlayerData] = useState([]);
  const [total_players, setTotal_players] = useState(0);
  const [extra_players, setExtra_players] = useState(0);
  const [showJoinTeam, setShowJoinTeam] = useState(false);
  const [currentName, setcurrentName] = useState("");
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    // localStorage.setItem("activeGameId", props?.allData?.game?._id);
    // localStorage.setItem("activeGame", props?.allData?.game?.name);
    if (props?.allData) {
      var per =
        (100 * props?.allData?.totalJoinedPlayers) /
        props?.allData?.totalPlayers +
        "%";
      setProgressWidth(per);
      setAllData(props?.allData);
    }
  }, [props?.allData]);

  const addTrackData = async (string) => {
    if (localStorage.getItem("loginData")) {
      let userData = JSON.parse(localStorage.getItem("loginData"));
      await addTracker(`userid=${userData?.userId}&${string}`);
    }
  };

  const getjoin = async () => {
    var uniqueI = "";
    localStorage.setItem("joinTounament", JSON.stringify(allData));
    // localStorage.setItem("activeGameId", allData?.gameAry[0]?._id);
    localStorage.setItem("activeGame", allData?.gameAry[0]?.name);
    let games = JSON.parse(localStorage.getItem("userInGameNames"))?.find(
      (val) => val?.game === allData?.game
    );

    localStorage.setItem("tournamentId", allData._id);
    // localStorage.setItem("activeGameId", allData?.game?._id);
    uniqueI = allData?.userInGameName || games?.userINGameName;
    setcurrentName(uniqueI);

    var gameid =
      window.location.href.split("/")[
      window.location.href.split("/").length - 1
      ];

    //let data = { contest: allData._id, type: "contest" };
    let payload = {
      contest: allData._id,
      type: "contest",
      entryFee: allData.entryFee,
      currencyCode: allData.contestFor?.inCurrency?.value, // change to inCurrency
    };
    // let response = await getWalletLimit(payload);
    // // console.log('Wallet API: '.response);
    // if (response) {
    //   setCoinStore(
    //     response?.item?.winningAmount >= response?.item?.entryFee ? false : true
    //   );
    //   setCoin(response?.item?.winningAmount);
    //   setWalletUsageData(response?.item);
    // }

    var temp_dobns_data = JSON.parse(localStorage.getItem("loginData"));
    setCoin(temp_dobns_data?.walletBalance || 0);

    setselectedFeaturedData(allData);

    // if (uniqueI === "" || uniqueI === undefined) {
    if (checkDobNstateConditions(dobNstateScreenName) || !uniqueI) {
      setShowdobnstate(true);
      // window.location.href = routehelp.dobnstate;
      // window.history.pushState(routehelp.dobnstate, null, '');
    } else {
      addTrackData(
        `gameCatalogName=${localStorage.getItem(
          "activeGame"
        )}&gameCatalogId=${localStorage.getItem("activeGameId")}&tournamentId=${allData._id
        }&eventId=${trackerEventId?.Contest_Join_Popup_Shown}`
      );
      setShowConfirm(true); //
    }
  };

  let findUserName = (gameid) => {
    // console.log("gameid", gameid);
    let gameNames = JSON.parse(localStorage.getItem("userInGameNames"));
    if (gameNames) {
      let index = gameNames.findIndex((obj) => obj.game == gameid);
      if (index > -1) {
        setcurrentName(gameNames[index].userINGameName);
        // return gameNames[index].userINGameName || ""
      } else {
        return "";
      }
    }
  };

  const onJoinTournament = async () => {
    setLoader(true);
    setShowConfirm(false);
    if (coinStore == false) {
      //uniqueIgn

      let allFeaturedData = JSON.parse(localStorage.getItem("joinTounament"));
      let games = JSON.parse(
        localStorage.getItem("gamerjiLogindetails")
      )?.item?.gameNames.find(
        (val) => val?.game === allFeaturedData?.game?._id
      );
      var uniqueI = allData?.userInGameName;
      // let data = {
      //   type: "contest",
      //   contest: selectedFeaturedData?._id,
      //   uniqueIGN: uniqueI,
      // };
      //  console.log("findUserName(gameid)==>currentName", currentName,);
      var user_data = JSON.parse(localStorage.getItem("loginData"));

      let data = {
        // type: 'contest',
        inGameUserName: currentName,
        user: user_data?.user_id,
        contest: allData?._id,
        joinedDate: new Date(),
        JoinedTimestamp: new Date().getTime(),
        isActive: allData?.isActive || true,
        indigg_access_token: localStorage.getItem("accessToken"),
      };

      // {
      //   event: gameid,
      //   contest: selectedFeaturedData?._id,
      //   isActive: true,
      //   type: "event",
      //   uniqueIGN: "Nammu",
      // };

      // Squad Registration
      // console.log(allFeaturedData?.gameTypeAry?.players);
      // console.log(allFeaturedData?.gameTypeAry?.players);
      if (
        allFeaturedData?.gameTypeAry?.players &&
        parseInt(allFeaturedData?.gameTypeAry?.players) > 1
      ) {
        var numArr = [];

        var selectedPlayerArr = [];
        for (
          var i = 2;
          i <= parseInt(allFeaturedData?.gameTypeAry?.players);
          i++
        ) {
          numArr.push(i);

          selectedPlayerArr.push({
            num: i,
            user_id: null,
            gamerjiName: null,
            levelImage: null,
            inGameName: null,
          });
        }
        setSelectedPlayerData(selectedPlayerArr);
        // console.log("selectedPlayerData==>", selectedPlayerArr);
        //console.log("Num==>", parseInt(allFeaturedData?.gameType?.players));
        setTotal_players(selectedPlayerArr.length);
        setExtra_players(allFeaturedData?.gameTypeAry?.extraPlayers);

        // addTrackData(
        //   `gameCatalogName=${localStorage.getItem(
        //     "activeGame"
        //   )}&gameCatalogId=${localStorage.getItem("activeGameId")}&tournamentId=${
        //     allData._id
        //   }&eventId=${trackerEventId?.Team_Registration_Shown}`
        // );

        setShowJoinTeam(true);
        setLoader(false);

        // else {
        //   toast.error(constants.error_duo_squad_tournament_in_maintenance, {
        //     position: "top-left",
        //     autoClose: 2000,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        //   });
        // }
        // props.showJoinTeam(true, selectedPlayerArr, contest);
      } else {
        let response_check_date_time = await checkDateTime(data.contest);
        // console.log("response_check_date_time: ", response_check_date_time);
        if (response_check_date_time && "success" in response_check_date_time) {
          if (response_check_date_time.success === true) {
            addTrackData(
              `gameCatalogName=${localStorage.getItem(
                "activeGame"
              )}&gameCatalogId=${localStorage.getItem("activeGameId")}&tournamentId=${allData._id
              }&eventId=${trackerEventId?.Contest_Join_Popup_Shown}`
            );

            let res = await onCreateContest(data);
            if (res.success === true) {
              setLoader(false);
              window.location.href = "/contest-details/" + allFeaturedData?._id; //routehelp.contestdetails;
            } else {
              setLoader(false);
              toast.error(res.data.errors?.[0]?.msg || res.data?.msg, {
                position: "top-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          } else {
            setLoader(false);
            // console.log('Error');
            toast.error(response_check_date_time.errors?.[0]?.msg, {
              position: "top-left",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
      }
    } else {
      setLoader(false);
      window.location.href = routehelp.coinstore;
    }
  };

  const getdobnstate = (tag) => {
    // setShowjoinpopup(tag);
    var gameid = localStorage.getItem("activeGameId");
    findUserName(gameid);
    setShowdobnstate(tag);

    addTrackData(
      `gameCatalogName=${localStorage.getItem(
        "activeGame"
      )}&gameCatalogId=${localStorage.getItem("activeGameId")}&tournamentId=${allData._id
      }&eventId=${trackerEventId?.IGN_Popup_Shown}`
    );

    setShowConfirm(true);

    // var gameid =
    //   window.location.href.split("/")[
    //   window.location.href.split("/").length - 1
    //   ];

    // let data = {}

    // gameid === 'all' ? data = { contest: selectedFeaturedData._id, type: "event" }
    // : data = { contest: selectedFeaturedData._id, type: "contest" };

    // let data = { contest: selectedFeaturedData._id, type: "contest" };
    // let response = await getWalletLimit(data);
    // console.log('Wallet Response: ', response);
    // if (response) {
    //   setCoinStore(
    //     response?.item?.coinAmount > response?.item?.entryFee ? false : true
    //   );

    //   setCoin(response?.item?.coinAmount);
    //   setWalletUsageData(response?.item);
    // }
  };

  const cancelDobNstate = () => {
    setShowdobnstate(false);
    // setShowjoinpopup(false);
    setShowConfirm(false);
  };

  const getWinnerClick = () => {
    setShowWinners(true);
    setAllWinners(allData);
  };
  const navigate = useNavigate();
  const joinedContest = () => {
    localStorage.setItem("joinTounament", JSON.stringify(allData));
    localStorage.setItem("activeGameId", allData?.gameAry[0]?._id);
    localStorage.setItem("activeGame", allData?.gameAry[0]?.name);
    // window.location.href = `/contest-details/${allData?._id}`;
    navigate(`/contest-details/${allData?._id}`);
  };

  const backButtonSetShowJoinTeam = () => {
    setShowJoinTeam(false);
  };

  return loader ? (
    <Loader />
  ) : (
    <div className="contest-list contest-tournament-list game_tounament">
      {/* <ToastContainer />   */}

      {/* DOBNSTATE Popup */}
      {showdobnstate ? (
        <DobNStates
          submitDobNstate={getdobnstate}
          cancelDobNstate={cancelDobNstate}
          screen={dobNstateScreenName}
        />
      ) : (
        ""
      )}

      {/* Contenst - Tournament/{id} (One box) */}
      <div className="box">
        <h3 style={{ fontSize: "16px", fontWeight: "800" }}>
          {/* <marquee> */}
          {allData?.title}

          {/* </marquee> */}
          <span className="pointer">ID: {allData?.shortCode}</span>
        </h3>
        <div className="row tournament_contest_row">
          <div className="col-12">
            <div className="inner_row bg-dark justify-content-between">
              <div className="inner_item">
                Date <b>{getProfiledateFormat(allData?.date)}</b>
              </div>
              <div className="inner_item">
                Time <b>{formatAMPM(new Date(allData?.time))}</b>
              </div>
              {allData?.titles?.map((itemInner, index) => {
                if (itemInner?.name !== "-" && itemInner?.isSelection) {
                  return (
                    <div className="inner_item" key={index}>
                      {itemInner?.name?.length > 15
                        ? itemInner?.name?.substring(0, 15 - 3) + "..."
                        : itemInner?.name}{" "}
                      <b>
                        {itemInner?.value?.length > 15
                          ? itemInner?.value?.substring(0, 15 - 3) + "..."
                          : itemInner?.value}
                      </b>
                    </div>
                  );
                }
              })}
            </div>
            <div className="inner_row ">
              <div className="inner_item inner_item_medium">
                Prize Pool <br />{" "}
                <b>
                  {/* {allData?.contestFor?.outCurrencyInfo.co}
                  {allData?.contestFor?.outCurrencyInfo?. && ( */}
                  {allData?.currency?.[0]?.outCurrency?.code === "INR" ||
                    allData?.currency?.[0]?.outCurrency?.code == "inr" ? (
                    "₹"
                  ) : (
                    <img
                      src={
                        url.imageUrl +
                        allData?.currency?.[0]?.outCurrency?.img?.default
                      }
                      alt=""
                      className="coin_img"
                    />
                  )}
                  {/* )} */}
                  {/* <img
                        src={
                          url.imageUrl +
                          allData?.currency?.img?.default
                        }
                        className="coin_img"
                      /> */}{" "}
                  {allData?.prizePool}
                </b>
              </div>
              <div className="inner_item inner_item_medium">
                {allData.winningModel === "perRank" ? "Winners" : "Per Kill"}{" "}
                {allData.winningModel === "perRank" ? (
                  <b
                    className="pointer"
                    onClick={() => getWinnerClick(allData)}
                  >
                    {" "}
                    {allData?.totalWinners}{" "}
                    <i className="fas fa-chevron-down"></i>
                  </b>
                ) : (
                  <b className="">
                    {allData?.currency?.[0]?.outCurrency?.code == "INR" ||
                      allData?.currency?.[0]?.outCurrency?.code == "inr" ? (
                      "₹"
                    ) : (
                      <img
                        src={
                          url.imageUrl +
                          allData?.currency?.[0]?.outCurrency?.img?.default
                        }
                        alt=""
                        className="coin_img"
                      />
                    )}{" "}
                    {allData?.killPoints}{" "}
                  </b>
                )}
              </div>
              <div className="inner_item inner_item_medium">
                Join Using <br />{" "}
                {allData?.entryFee > 0 ? (
                  <b>
                    {allData?.currency?.[0]?.inCurrency?.code == "INR" ||
                      allData?.currency?.[0]?.inCurrency?.code == "inr" ? (
                      "₹"
                    ) : (
                      <img
                        src={
                          url.imageUrl +
                          allData?.currency?.[0]?.inCurrency?.img?.default
                        }
                        alt=""
                        className="coin_img"
                      />
                    )}
                    {/* )} */} {allData?.entryFee}
                  </b>
                ) : (
                  <b>Free</b>
                )}
              </div>
            </div>
            <div className="avalible_wrap">
              <div className="inner">
                <span
                  style={{
                    width: progressWidth,
                  }}
                ></span>
              </div>
              <p className="d-flex justify-content-between text-light">
                <span>
                  {allData?.totalPlayers - allData?.totalJoinedPlayers} players
                  remaining
                </span>
                <span>{allData?.totalJoinedPlayers} player joined</span>
              </p>
            </div>

            {allData?.isJoined !== true &&
              allData?.totalJoinedPlayers >= allData?.totalPlayers ? (
              <div className="contest_foot p-2 d-flex justify-content-center align-items-center pointer">
                <div className="inner">
                  <a className="contest_joining_popup">FULL</a>
                </div>
              </div>
            ) : allData?.isJoined === true ? (
              <div
                className="contest_foot p-2 d-flex justify-content-center align-items-center pointer"
                onClick={() => joinedContest()}
              >
                <div className="inner pointer">
                  <a className="d-flex justify-content-center align-items-center">
                    <b className="contest_joining_popup d-flex justify-content-center align-items-center">
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: "25px" }}
                      >
                        JOINED
                      </div>

                      {/* <div
                        style={{
                          width: "20px",
                          height: "25px",
                          marginLeft: "5px",
                        }}
                      >
                        <Lottie
                          options={{
                            animationData: successCheck,
                            w: "20px",
                          }}
                        />
                      </div> */}
                    </b>
                  </a>
                </div>
              </div>
            ) : (
              <div
                className="contest_foot p-2 d-flex justify-content-center align-items-center pointer"
                onClick={() => getjoin(allData)}
              >
                <div className="inner pointer">
                  <a className="contest_joining_popup">JOIN NOW</a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Winning Breakup - Contests */}
      {showWinners === true && (
        <div className="tc_popup_bg">
          <div
            className="custom-popup rules custom_scroll show"
            id="contact_popup"
          >
            <div className="popup-head">
              <h3>Winning Breakup</h3>
              <button
                className="close-popup"
                onClick={() => setShowWinners(false)}
              >
                {/* <img src={Close} alt="" /> */}
                <FiX />
              </button>
            </div>
            <div className="popup-body">
              <div
                className="all_sub_screens_div_related_to_ads"
                style={{ display: "inherit" }}
              >
                {allWinners?.pointpool && allWinners?.pointpool?.length !== 0 && (
                  <div
                    className="list-group main-tab w-90"
                    id="list-tab"
                    role="tablist"
                  >
                    <a
                      className="list-group-item list-group-item-action active"
                      id="list-home-list"
                      data-toggle="list"
                      href="#prize-1"
                    >
                      Prize Pool
                    </a>
                    <a
                      className="list-group-item list-group-item-action"
                      id="list-profile-list"
                      data-toggle="list"
                      href="#point-2"
                    >
                      Points
                    </a>
                  </div>
                )}
                <div className="tab-content" id="nav-tabContent">
                  <div className="tab-pane active" id="prize-1">
                    <div className="prize_pool mb-3 text-center p-2 w-90">
                      <p>Prize Pool</p>
                      <h6>
                        {/* {allWinners?.currency?.code == "INR" && "₹"}
                        {allWinners?.currency?.code == "coin" && ( */}
                        <img
                          src={
                            url.imageUrl +
                            allWinners?.currency?.[0]?.outCurrency?.img?.default
                          }
                          alt=""
                          className="coin_img_big"
                        />
                        {/* )} */} {allWinners?.prizePool}
                      </h6>
                    </div>
                    <div className="table-responsive total_table total_table_border mb-3 w-90">
                      <table>
                        <tbody>
                          {allWinners?.prizePoolInfo?.map((item1, index1) => {
                            return (
                              <tr key={index1}>
                                <td>
                                  Rank:{" "}
                                  {item1?.rankTo !== "0" &&
                                    item1?.rankTo != item1?.rankFrom
                                    ? item1?.rankFrom + "-" + item1?.rankTo
                                    : item1?.rankFrom}
                                </td>
                                <th className="text-right">
                                  {/* {allWinners?.currency?.code == "INR" && "₹"}
                                  {allWinners?.currency?.code == "coin" && ( */}
                                  <img
                                    src={
                                      url.imageUrl +
                                      allWinners?.currency?.[0]?.outCurrency
                                        ?.img?.default
                                    }
                                    alt=""
                                    className="coin_img"
                                  />
                                  {/* )} */} {item1?.amount}
                                </th>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {allWinners?.pointpool && (
                    <div className="tab-pane" id="point-2">
                      <div className="table-responsive total_table total_table_border mb-3 w-90">
                        <table>
                          <tbody>
                            {allWinners?.pointpool?.map((item1, index1) => {
                              return (
                                <tr key={index1}>
                                  <td>
                                    Rank:{" "}
                                    {item1?.rankTo !== "0"
                                      ? item1?.rankFrom + "-" + item1?.rankTo
                                      : item1?.rankFrom}
                                  </td>
                                  <th className="text-right">{item1?.point}</th>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <Advertisement
                screen={
                  localStorage.getItem("apk_screens_list") === null
                    ? { code: "no_results" }
                    : JSON.parse(localStorage.getItem("apk_screens_list"))
                      .prizePoolPopup
                }
                screen_div_class_name=".all_sub_screens_div_related_to_ads"
                screen_type="sub"
              />
            </div>
          </div>
        </div>
      )}

      {/* Join Popup - joinContestWalletPopup */}
      {showConfirm === true && (
        <div className="tc_popup_bg">
          <div
            className="custom-popup rules confirm custom_scroll join show"
            id="contact_popup"
          >
            <div className="popup-head">
              {/* <marquee> */}
              <h3> {selectedFeaturedData?.title} </h3>
              {/* </marquee> */}

              <button
                className="close-popup"
                onClick={() => setShowConfirm(false)}
              >
                {/* <img src={Close} alt="" /> */}
                <FiX />
              </button>
            </div>
            <div className="popup-body">
              <div className="all_sub_screens_div_related_to_ads">
                <p className="game_det">
                  <span>
                    {selectedFeaturedData?.gameAry[0]?.name} -{" "}
                    {selectedFeaturedData?.gameTypeAry?.name}
                  </span>
                  <span className="color-dark">
                    {getTournamentDateTime(
                      selectedFeaturedData?.date,
                      selectedFeaturedData?.time
                    )}
                  </span>
                </p>
                {/* <div className="round_time"> */}
                <div className="confirmation-tournament">
                  <p className="color-dark">Confirmation</p>
                  {/* {selectedFeaturedData.contestFor?.inCurrencyInfo?.code ==
                    "coin" && (
                    <p className="color-grey-3">
                      Wallet Balance ={" "}
                      <img
                        src={
                          url.imageUrl +
                          selectedFeaturedData?.contestFor?.inCurrencyInfo?.img
                            ?.default
                        }
                        style={{ width: "15px" }}
                      />{" "}
                      {coin}{" "}
                    </p>
                  )}
                  {(selectedFeaturedData?.contestFor?.inCurrencyInfo?.code ==
                    "INR" ||
                    selectedFeaturedData?.contestFor?.inCurrencyInfo?.code ==
                      "inr") && (
                    <p className="color-grey-3">
                      Balance = {"₹"} {walletUsage?.winningAmount}{" "}
                    </p>
                  )}
                  {selectedFeaturedData?.contestFor?.inCurrencyInfo?.code ==
                    "diamond" && (
                    <p className="color-grey-3">
                      Diamond Balance ={" "}
                      <img
                        src={
                          url.imageUrl +
                          selectedFeaturedData?.currency[0]?.inCurrency?.img
                            ?.default
                        }
                        style={{ width: "15px" }}
                      />{" "}
                      {walletUsage?.winningAmount}{" "}
                    </p>
                  )} */}
                  {selectedFeaturedData?.currency[0]?.inCurrency?.code && (
                    <p className="color-grey-3">
                      Wallet Balance ={" "}
                      <img
                        src={
                          url.imageUrl +
                          selectedFeaturedData?.currency[0]?.inCurrency?.img
                            ?.default
                        }
                        style={{ width: "15px" }}
                      />{" "}
                      {coin || 0}{" "}
                    </p>
                  )}
                </div>
                <div className="fee">
                  <p>
                    <b>Entry Fee</b>
                    <b>
                      {selectedFeaturedData.entryFee > 0 &&
                        (selectedFeaturedData?.currency[0]?.inCurrency
                          ?.code == "INR" ||
                          selectedFeaturedData?.currency[0]?.inCurrency
                            ?.code == "inr" ? (
                          "₹"
                        ) : (
                          <img
                            src={
                              url.imageUrl +
                              selectedFeaturedData?.currency[0]?.inCurrency?.img?.default
                            }
                            style={{ width: "15px" }}
                            className="coin_img"
                          />
                        ))}{" "}
                      {selectedFeaturedData.entryFee === "0"
                        ? "Free"
                        : selectedFeaturedData.entryFee}
                    </b>
                  </p>
                  <p
                    className="d-flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <span>
                      <img src={pay} alt="" className="mCS_img_loaded mr-2" />
                      To Pay
                    </span>
                    <b>
                      {selectedFeaturedData.entryFee > 0 &&
                        (selectedFeaturedData?.currency[0]?.inCurrency
                          ?.code == "INR" ||
                          selectedFeaturedData?.currency[0]?.inCurrency
                            ?.code == "inr" ? (
                          "₹"
                        ) : (
                          <img
                            src={
                              url.imageUrl +
                              selectedFeaturedData?.currency[0]?.inCurrency
                                ?.img?.default
                            }
                            style={{ width: "15px" }}
                            className="coin_img"
                          />
                        ))}{" "}
                      {selectedFeaturedData.entryFee === "0"
                        ? "Free"
                        : selectedFeaturedData.entryFee}
                    </b>
                  </p>
                </div>
                {selectedFeaturedData?.currency?.joiningNote != "" && (
                  <p className="terms_join" style={{ fontSize: "14px" }}>
                    {selectedFeaturedData?.currency?.joiningNote}
                  </p>
                )}
                {selectedFeaturedData?.gameAry?.[0]?.settings?.levelNote !=
                  "" && (
                    <p className="level_note">
                      {selectedFeaturedData?.gameAry?.[0]?.settings?.levelNote}
                    </p>
                  )}

                <button
                  className="btn btn-dark main-btn next"
                  onClick={() => onJoinTournament()}
                >
                  JOIN CONTEST{" "}
                  {/* <img src={rightArrow} alt="" className="mCS_img_loaded" /> */}
                </button>
              </div>
              <Advertisement
                screen={
                  localStorage.getItem("apk_screens_list") === null
                    ? { code: "no_results" }
                    : JSON.parse(localStorage.getItem("apk_screens_list"))
                      .joinContestWalletPopup
                }
                screen_div_class_name=".all_sub_screens_div_related_to_ads"
                screen_type="sub"
              />
            </div>
          </div>
        </div>
      )}

      {/* SQAUD registrations */}
      {showJoinTeam === true && (
        <SquadRegistration
          selectedPlayerData={selectedPlayerData}
          selectedContest={""}
          backButtonSetShowJoinTeam={backButtonSetShowJoinTeam}
          total_players={total_players}
          extra_players={extra_players}
        />
      )}
    </div>
  );
}

export default ContestStructure;
