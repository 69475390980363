import { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { constants } from "../constants/constants";
import { checkUserDetails } from "../apis";
import url from "../constants/url";

export const SquadPlayerCard = (props) => {
  const {
    total_players,
    extra_players,
    playersData,
    onClickApply,
    onChangePhone,
    onChangeInNameGame,
    onClickRemove,
    onClickAddEmail,
    onClickRemoveEmail,
    disabledCards,
  } = props;

  const [activeTabs, setActiveTabs] = useState({});

  const [phone, setPhone] = useState("");

  const [selectedContest, setSelectedContest] = useState("");
  const countryObj = JSON.parse(localStorage.getItem("userCountryObj"));

  const findUserName = () => {
    let activeGameId = localStorage.getItem("activeGameId");
    let gameNames = JSON.parse(localStorage.getItem("userInGameNames"));
    if (gameNames) {
      let index = gameNames.findIndex((obj) => obj.game === activeGameId);
      if (index >= 0) {
        return gameNames[index].userINGameName || "";
      } else {
        return "";
      }
    }
  };

  useEffect(() => {
    let allFeaturedData = JSON.parse(localStorage.getItem("joinTounament"));
    var uniqueI = "";

    var gameid =
      window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ];

    var game_ID =
      gameid === "all" ? allFeaturedData?.gameId : allFeaturedData?.game?._id;
    let games = JSON.parse(
      localStorage.getItem("gamerjiLogindetails")
    )?.item?.gameNames.find((val) => val?.game === game_ID);
    uniqueI = findUserName();

    for (let i = 0; i < total_players + extra_players; i++) {
      playersData.push({
        user_id: "",
        gamerjiName: "",
        levelImage: "",
        in_game_name: "",
        isClickOnApplyPlayer: false,
        phone: "",
      });
    }

    const user_data = JSON.parse(localStorage.getItem("gamerjiLogindetails"));
    setPlayers_data(playersData);

    setSelectedContest(selectedContest);
  }, []);

  const handleTabClick = (event, cardId) => {
    setActiveTabs((prevTabs) => ({
      ...prevTabs,
      [cardId]: event.target.value,
    }));
  };
  const AddButton = ({ index }) => {
    return (
      <a class="scrim-add-btn pointer" onClick={() => onClickApply(index)}>
        Add
      </a>
    );
  };

  const RemoveButton = ({ index }) => {
    return (
      <a class="scrim-remove-btn pointer" onClick={() => onClickRemove(index)}>
        Remove
      </a>
    );
  };

  const AddEmailButton = ({ index }) => {
    return (
      <a class="scrim-add-btn pointer" onClick={() => onClickAddEmail(index)}>
        Add
      </a>
    );
  };

  const RemoveEmailButton = ({ index }) => {
    return (
      <a
        class="scrim-remove-btn pointer"
        onClick={() => onClickRemoveEmail(index)}
      >
        Remove
      </a>
    );
  };
  const [players_data, setPlayers_data] = useState(() => {
    const initialData = [];
    for (let i = 0; i < total_players + extra_players; i++) {
      initialData.push({
        user_id: "",
        gamerjiName: "",
        levelImage: "",
        in_game_name: "",
        isClickOnApplyPlayer: false,
        phone: "",
      });
    }

    return initialData;
  });

  const onchangeEmail = (e, index) => {
    let phone = (playersData[index].email = e.target.value);
    setPhone(phone);
    setPlayers_data(playersData);
  };

  return (
    <div className="row squad_registration_caption_player_row p-0 gap">
      {playersData?.map((player, index) => {
        const cardId = `card-${index}`;
        const activeTab = "Mobile";
        return (
          <div className="col-12 m-0 p-0 scrim-squad-registration" key={index}>
            <div className="scrim-squad-registration-title flex gap2 ">
              {index < total_players ? (
                <div className="width2 scrim-squad-registration-Caption text-align">
                  Player - {index + 2}
                </div>
              ) : (
                <div className="width2 scrim-squad-registration-Caption text-align">
                  Player - {index + 2}{" "}
                  <span className="optionalLabel">(Optional)</span>
                </div>
              )}
            </div>
            <div className="scrim-squad-registration-title flex flex-direction gap padding10">
              <div className="flex">
                <div className="width3 align-self f14">In Game Name:</div>
                <div className="width7">
                  <input
                    placeholder="Enter in game name"
                    className="scrim-squad-registration-input width"
                    value={player?.inGameName}
                    onChange={(e) => onChangeInNameGame(e, index)}
                  />
                </div>
              </div>
              <div className="flex  ">
                <div className="width3 align-self f14">User Name:</div>
                <div className=" width7 relative">
                  <input
                    placeholder="Enter user name"
                    className="scrim-squad-registration-input width"
                    value={player?.playerPhone}
                    onChange={(e) => onChangePhone(e, index)}
                    type="text"
                    onKeyDown={(e) =>
                      ["+", "-"].includes(e.key) && e.preventDefault()
                    }
                    maxLength={25}
                    disabled={player?.isClickOnApplyPlayer}
                  />
                  {player?.isClickOnApplyPlayer === true ? (
                    <RemoveButton index={index} />
                  ) : (
                    <AddButton index={index} />
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
