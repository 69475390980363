import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  getApplyContestCode,
  getApplyEventCode,
  getContestList,
  getTournamentList,
  getUserInGameName,
  onCreateScrim,
} from "../apis";
import Lottie from "reactjs-lottie";
import {
  getProfiledateFormat,
  formatAMPM,
  checkDobNstateConditions,
} from "../component/common";
import rightArrow from "../assets/images/right-arrow.svg";
import { dateFormat, goback } from "../component/common";
import Previous from "../assets/images/previous.svg";
// import successCheck from "../assets/images/success.json";
import successCheck from "../assets/images/success.json";
import Close from "../assets/images/close.svg";
import DropDownBlue from "../assets/images/drop_down_blue.png";
import NoContests from "../assets/images/Group_8748.png";
import NoTournaments from "../assets/images/Group_8752.png";
import url from "../constants/url";
import Loader from "../component/loader";
import ContestStructure from "../component/ContestStructure";
import TournamentsStructure from "../component/tournaments-structure";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ScrimSquadRegistration } from "./Scrims/ScrimSquadRegistration";
import DobNStates from "./dobnstate";

import { FiX } from "react-icons/fi";

import Header from "../component/header";
import { useNavigate } from "react-router";

import { addTracker } from "../component/tracker";

import { trackerEventId } from "../config/tracker";

import JoinViaPath from "./JoinViaPath";

function Tournaments(props) {
  let { id } = useParams();
  const [loader, setLoader] = useState(false);
  const [loader1, setLoader1] = useState(false);
  const [tournamentJoin, setTournamnetJoin] = useState(false);
  const [progressWidth, setProgressWidth] = useState(0);
  const [scrimJoin, setScrimJoin] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [allFeaturedData, setAllFeaturedData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [joinData, setJoinData] = useState({});
  const [scrimData, setScrimData] = useState({});
  const [showScrimTeam, setShowScrimTeam] = useState(false);
  const [contestName, setContestName] = useState("");
  const [code, setCode] = useState("");
  const [applyMsg, setApplyMsg] = useState("");
  const [currentName, setCurrentName] = useState("");
  const [page, setPage] = useState(1);
  const [gameId, setGameId] = useState("");
  const [isLastPage, setIsLastPage] = useState(false);
  const [players, setPlayers] = useState([]);
  const [showdobnstate, setShowdobnstate] = useState(false);
  useEffect(() => {
    fetchData();
  }, []);

  const addTrackData = async (string) => {
    if (localStorage.getItem("loginData")) {
      let userData = JSON.parse(localStorage.getItem("loginData"));
      await addTracker(`userid=${userData?.userId}&${string}`);
    }
  };

  const getjoin = async () => {
    var gameType = scrimData?.game?._id;
    let ingamename = "";
    let gameNames = JSON.parse(localStorage.getItem("userInGameNames"));
    if (gameNames) {
      let index = gameNames.findIndex((obj) => obj.game === gameType);
      if (index >= 0) {
        ingamename = gameNames[index].userINGameName;
      }
    }
    if (
      checkDobNstateConditions(dobNstateScreenName) ||
      ingamename == "" ||
      ingamename == undefined
    ) {
      setShowdobnstate(true);
    } else {
      setScrimJoin(false);
      setShowConfirm(true); //
    }
  };

  let findUserName = () => {
    let activeGameId = localStorage.getItem("activeGameId");
    let gameNames = JSON.parse(localStorage.getItem("userInGameNames"));
    if (gameNames) {
      let index = gameNames.findIndex((obj) => obj.game === activeGameId);
      if (index >= 0) {
        setCurrentName(gameNames[index].userINGameName);
        return gameNames[index].userINGameName || "";
      } else {
        return "";
      }
    }
  };

  const fetchData = () => {
    if (localStorage.getItem("loginData")) {
      setLoader(true);

      if (localStorage.getItem("currentPage")) {
        let allpage = [...JSON.parse(localStorage.getItem("currentPage"))];
        var count = allpage.findIndex((d) => d === window.location.pathname);
        count === -1 && allpage.push(window.location.pathname);
        localStorage.setItem("currentPage", JSON.stringify(allpage));
      } else {
        let allpage = [];
        allpage.push(window.location.pathname);
        localStorage.setItem("currentPage", JSON.stringify(allpage));
      }

      var gameid = id

      setContestName(gameid === "all" ? "Tournaments" : findGameName(gameid));
      setGameId(gameid);
      gameid === "all"
        ? getTournamentListAPICall(1)
        : getContestListAPICall(gameid, 1);
    }
  };

  const findGameName = (gameid) => {
    let gameType = JSON.parse(localStorage.getItem("activeGameType"));
    let gameName = gameType.find((val) => val?.id === gameid)?.name;
    if (!gameName) gameName = localStorage.getItem("activeGameTypeName");

    return gameName;
  };

  const getTournamentListAPICall = async (page) => {
    let alldata = {
      skip: allFeaturedData?.length,
      limit: 10,
      filter: {
        game: localStorage.getItem("activeGameId"),
        tournamentType: "gamerji",
        user: JSON.parse(localStorage.getItem("loginData"))?.user_id,
      },
    };

    let response = await getTournamentList(alldata);

    setPage(page);

    if (response) {
      let temp_AllFeaturedData = [];
      // console.log("response?.totalPages 2", response?.totalPages, page);
      if (response?.totalPages == page || response?.totalPages == 0)
        setIsLastPage(true);

      addTrackData(
        `gameCatalogName=${localStorage.getItem(
          "activeGame"
        )}&gameCatalogId=${localStorage.getItem("activeGameId")}&eventId=${
          trackerEventId?.Tournaments_List_Shown
        }`
      );

      response?.data?.length > 0 &&
        response?.data?.forEach((item) => {
          temp_AllFeaturedData.push({
            id: item?._id,
            img: url.imageUrl + item?.featuredImage?.default,
            coin: url.imageUrl + item?.currency?.img?.default,
            title: item?.title,
            contestsList: item?.contestsList,
            totalPlayers: item?.totalPlayers,
            isJoined: item?.isJoined,
            totalJoinedPlayers: item?.totalJoinedPlayers,
            shortCode: item?.shortCode,
            gameName: item?.gameAry[0]?.name,
            gameId: item?.gameAry[0]?._id,
            winners: item?.totalWinners,
            gameType: item?.gameTypeAry?.name,
            gameTypePlayers: item?.gameTypeAry?.players, // not present
            gameTypeExtraPlayers: item?.gameTypeAry?.extraPlayers, // not present
            levelNote: item?.gameAry[0]?.settings?.levelNote,
            currency: item?.currency[0],
            hostedby: item?.contestFor?.selectedCompany?.label,
            hostrate: item?.rate,
            entryFee: item?.entryFee,
            titles: item?.titles,
            rules: item?.rules,
            allrounds: item?.rounds,
            round: item?.rounds?.length,
            winningCoin: item?.totalWinningsDistributedCoin,
            startDate: item?.startDate,
            endDate: item?.endDate,
            roundDatetime: item?.rounds[0]?.matches,
            prizepool: item?.prizePooInfo,
            prizePool: item?.prizePool,
            pointpool: item?.pointpool,
            inGameName: findUserName(),
            createdAt: dateFormat(item?.createdAt),
            updatedAt: dateFormat(item?.updatedAt),
            saasGameId: item?.gameType,
          });
        });

      setAllFeaturedData([...allFeaturedData, ...temp_AllFeaturedData]);
    }
    setLoader(false);
  };

  const getContestListAPICall = async (gameid, page) => {
    var user_data = JSON.parse(localStorage.getItem("loginData"));
    setLoader1(true);

    let payload = {
      search: {
        gameType: gameid,
        game: localStorage.getItem("activeGameId"),
        user: user_data?.user_id,
      },
      pagination: {
        pageNo: page,
        recordPerPage: 10,
        sortBy: "dateTime",
        sortDirection: "asc",
      },
    };
    let response = await getContestList(payload);
    setPage(page);
    if (response) {
      addTrackData(
        `gameCatalogName=${localStorage.getItem(
          "activeGame"
        )}&gameCatalogId=${localStorage.getItem("activeGameId")}&eventId=${
          trackerEventId?.Contest_List_Shown
        }`
      );
      // console.log("response?.totalPages 1", response?.totalPages, page);
      if (response && "data" in response)
        if (
          (response?.totalPages > 0 && response?.totalPages == page) ||
          response?.totalPages == 0
        )
          setIsLastPage(true);
      setAllData([...allData, ...response.data]);
    }
    setLoader(false);
    setLoader1(false);
  };

  const applyCode = async () => {
    if (code === "") {
      setApplyMsg("Please enter invite code.");
    } else {
      var user_data = JSON.parse(localStorage.getItem("loginData"));

      let payload = {
        search: {
          user: user_data?.user_id,
        },
      };
      let res = await getApplyContestCode(code.toUpperCase(), payload);
      if (res?.type === "scrim") {
      }
      if (res?.data && res?.success) {
        let data = {};
        if (res?.data?.isSingle) {
          data = res?.data;
        } else {
          localStorage.setItem("activeGameId", res?.item?.saasGameId);
          localStorage.setItem("activeGame", res?.item?.game?.name);
          data = {
            id: res?.item?._id,
            img: url.imageUrl + res?.item?.featuredImage?.default, // not present
            coin: url.imageUrl + res?.item?.currency?.img?.default,
            title: res?.item?.title,
            contestsList: res?.item?.contestsList, // not present
            totalPlayers: res?.item?.totalPlayers,
            isJoined: res?.item?.isJoined,
            totalJoinedPlayers: res?.item?.totalJoinedPlayers,
            shortCode: res?.item?.shortCode,
            gameName: res?.item?.game?.name, // not present
            levelNote: res?.item?.game?.settings?.levelNote, // not present
            currency: res?.item?.currency,
            gameId: res?.item?.game?._id, // not present
            winners: res?.item?.totalWinners,
            gameType: res?.item?.gameType?.name,
            gameTypePlayers: res?.item?.gameType?.players,

            hostedby: res?.item?.host?.name,
            hostrate: res?.item?.host?.rate,
            entryFee: res?.item?.entryFee,
            titles: res?.item?.titles,
            rules: res?.item?.rules, // not present
            allrounds: res?.item?.rounds, // not present
            round: res?.item?.rounds?.length, // not present
            winningCoin: res?.item?.totalWinningsDistributedCoin, // not present
            startDate: res?.item?.startDate, // not present
            endDate: res?.item?.endDate, // not present
            roundDatetime: res?.item?.rounds[0]?.matches, // not present
            prizepool: res?.item?.prizepool,
            prizePool: res?.item?.prizePool,
            pointpool: res?.item?.pointpool,
            createdAt: dateFormat(res?.item?.createdAt), // not present
            updatedAt: dateFormat(res?.item?.updatedAt), // not present
            saasGameId: res?.item?.saasGameId,
          };
        }
        if (res?.type === "scrim") {
          let data = res?.data;
          setScrimJoin(true);
          setScrimData(data);
          localStorage.setItem("activeGameId", data?.game?._id);
          localStorage.setItem("activeGame", data?.game?.name);

          localStorage.setItem("scrimData", JSON.stringify(data));
        } else {
          setJoinData(data);
          setTournamnetJoin(true);
        }

        setCode("");
        setApplyMsg("");
      } else {
        let data = {
          code: code.toUpperCase(),
          user: JSON.parse(localStorage.getItem("loginData"))?.user_id,
        };
        let res = await getApplyEventCode(data);
        if (res?.item) {
          data = {};
          if (res?.item?.isSingle) {
            data = res?.item;
          } else {
            localStorage.setItem("activeGameId", res?.item?.gameAry[0]?._id);
            localStorage.setItem("activeGame", res?.item?.gameAry[0]?.name);
            data = {
              id: res?.item?._id,
              img: url.imageUrl + res?.item?.featuredImage?.default, // not present
              coin: url.imageUrl + res?.item?.currency?.img?.default,
              title: res?.item?.title,
              contestsList: res?.item?.contestsList, // not present
              totalPlayers: res?.item?.totalPlayers,
              isJoined: res?.item?.isJoined,
              totalJoinedPlayers: res?.item?.totalJoinedPlayers,
              shortCode: res?.item?.shortCode,
              gameName: res?.item?.gameAry[0]?.name, // not present
              levelNote: res?.item?.game?.settings?.levelNote, // not present
              currency: res?.item?.currency[0],
              gameId: res?.item?.gameAry[0]?._id, // not present
              winners: res?.item?.totalWinners,
              gameType: res?.item?.gameTypeAry?.name,
              gameTypePlayers: res?.item?.gameTypeAry?.players,
              gameTypeExtraPlayers: res?.item?.gameTypeAry?.extraPlayers, // not present

              hostedby: res?.item?.host?.name,
              hostrate: res?.item?.host?.rate,
              entryFee: res?.item?.entryFee,
              titles: res?.item?.titles,
              rules: res?.item?.rules, // not present
              allrounds: res?.item?.rounds, // not present
              round: res?.item?.rounds?.length, // not present
              winningCoin: res?.item?.totalWinningsDistributedCoin, // not present
              startDate: res?.item?.startDate, // not present
              endDate: res?.item?.endDate, // not present
              roundDatetime: res?.item?.rounds[0]?.matches, // not present
              prizepool: res?.item?.prizePoolInfo,
              prizePool: res?.item?.prizePool,
              pointpool: res?.item?.pointpool,
              inGameName: findUserName(),
              createdAt: dateFormat(res?.item?.createdAt), // not present
              updatedAt: dateFormat(res?.item?.updatedAt), // not present
              saasGameId: res?.item?.gameAry[0]?._id,
            };
          }
          setCode("");
          setApplyMsg("");
          setJoinData(data);
          setTournamnetJoin(true);
        } else {
          setApplyMsg(res?.data?.errors?.[0]?.msg);
        }
      }
    }
  };

  useEffect(() => {
    if (scrimData) {
      var per =
        (100 * scrimData?.noOfJoinedPlayer) / scrimData?.noOfPlayerCapacity +
        "%";
      setProgressWidth(per);
      setScrimData(scrimData);
    }
  }, [scrimData]);

  // Tournaments - Tournament/all (One box)
  const createTournamentSingleBox = (data, index) => {
    return <TournamentsStructure allFeaturedData={data} key={index} />;
  };

  // Tournaments - Tournament/all (all box)
  const createTournaments = () => {
    return allFeaturedData?.length > 0 ? (
      allFeaturedData?.map((item, index) => {
        return createTournamentSingleBox(item, index);
      })
    ) : (
      // No Tournaments
      <div className="no_tournament">
        <img src={NoTournaments} style={{ height: "50%" }} />
      </div>
    );
  };

  // Contests - Tournament/{id} (all box)
  const createContestSingleBox = (data, index) => {
    return <ContestStructure allData={data} key={index} />;
  };

  // Contests - Tournament/{id} (all box)
  const createContests = () => {
    return (
      <>
        {allData?.length > 0 ? (
          allData?.map((item, index) => {
            return createContestSingleBox(item, index);
          })
        ) : (
          // No Contents
          <div className="no_tournament">
            <img src={NoContests} style={{ height: "50%" }} />
          </div>
        )}
      </>
    );
  };

  // Join Now Popup - Click on Apply Code - Tournament/all - Tournament/{id}
  const createAfterApplyCodePopup = () => {
    return (
      <div className="tc_popup_bg">
        <div className="custom-popup home-featured show" id="contact_popup">
          <div className="popup-head">
            <h3>
              {joinData?.isSingle === true
                ? joinData.title
                : joinData?.gameName}
            </h3>
            <button
              className="close-popup"
              onClick={() => setTournamnetJoin(false)}
            >
              {/* <img src={Close} alt="" /> */}
              <FiX />
            </button>
          </div>
          <div className="popup-body mt-3">
            {joinData?.isSingle === true
              ? createContestSingleBox(joinData, 1)
              : createTournamentSingleBox(joinData, 1)}
          </div>
        </div>
      </div>
    );
  };
  const dobNstateScreenName = "tournaments-contests";
  const getdobnstate = (tag) => {
    // setShowjoinpopup(tag);
    var gameid = localStorage.getItem("activeGameId");
    findUserName(gameid);
    setShowdobnstate(tag);
    setShowConfirm(true);
  };
  const cancelDobNstate = () => {
    setShowdobnstate(false);
    // setShowjoinpopup(false);
    setShowConfirm(false);
  };
  const createScrimApplyCodePopup = () => {
    return (
      <div className="tc_popup_bg">
        <div className="custom-popup home-featured show " id="contact_popup">
          <div className="popup-head bg-light">
            <h3>{scrimData?.title}</h3>
            <button className="close-popup" onClick={() => setScrimJoin(false)}>
              {/* <img src={Close} alt="" /> */}
              <FiX />
            </button>
          </div>
          <div className="popup-body">
            <div
              className="contest-list contest-tournament-list game_tounament "
              style={{ margin: "20px auto 0px" }}
            >
              {showdobnstate ? (
                <DobNStates
                  submitDobNstate={getdobnstate}
                  cancelDobNstate={cancelDobNstate}
                  screen={dobNstateScreenName}
                />
              ) : (
                ""
              )}
              <div className="box scrimDetail">
                <h3
                  style={{
                    fontSize: "16px",
                    fontWeight: "800",
                    marginTop: "4px",
                  }}
                >
                  {scrimData?.title}
                  <span className="span">
                    <div to="/create-scrim">
                      {/* <img
                        // src={Pencil}
                        alt="Edit"
                        style={{ color: "black !important" }}
                      /> */}
                    </div>
                  </span>
                </h3>

                <div
                  className="row tournament_contest_row"
                  style={{ marginBottom: "-20px" }}
                >
                  <div className="col-12">
                    <div className="inner_row">
                      <div className="inner_item">
                        Date
                        <b>{getProfiledateFormat(scrimData?.date)}</b>
                      </div>
                      <div className="inner_item">
                        Time <b>{formatAMPM(new Date(scrimData?.time))}</b>
                      </div>

                      <div className="inner_item">
                        {scrimData.title1?.name}{" "}
                        <b>{scrimData.title1?.value}</b>
                      </div>
                      {scrimData?.title2 ? (
                        <div className="inner_item">
                          {scrimData.title2?.name}{" "}
                          <b>{scrimData.title2?.value}</b>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="inner_row bg-dark">
                      <div className="inner_item inner_item_medium">
                        Players <br /> <b>{scrimData.noOfPlayerCapacity}</b>
                      </div>
                      <div className="inner_item inner_item_medium">
                        Winners <br /> <b>{scrimData.noOfWinner}</b>
                      </div>
                      <div className="inner_item inner_item_medium">
                        ID <br /> <b>{scrimData.shortCode}</b>
                      </div>
                    </div>

                    <div className="avalible_wrap">
                      <div className="inner">
                        <span
                          style={{
                            width: progressWidth,
                          }}
                        ></span>
                      </div>
                      <p
                        className="d-flex justify-content-between text-light"
                        style={{ paddingBottom: "1rem" }}
                      >
                        <span className="">
                          {" "}
                          {scrimData?.noOfPlayerCapacity -
                            scrimData?.noOfJoinedPlayer}{" "}
                          players remaining
                        </span>
                        <span className="">
                          {scrimData?.noOfJoinedPlayer} players joined
                        </span>
                      </p>
                    </div>
                    <div className="contest_foot p-2">
                      <div className="inner pl-2">
                        <span className="">Hosted By</span>
                        <b>
                          {" "}
                          {/* {scrimData?.hostedBy} ( */}
                          {scrimData?.hostedBy?.length > 15 ? (
                            <marquee behavior="scroll" scrollamount="4">
                              {scrimData?.hostedBy}
                            </marquee>
                          ) : (
                            scrimData?.hostedBy
                          )}
                          {/* {Number(allData?.rate?.toFixed(2))} */}
                          <i className="fas fa-star text-dark"></i>
                        </b>
                      </div>

                      {scrimData?.noOfJoinedPlayer >=
                      scrimData?.noOfPlayerCapacity ? (
                        <div className="inner pointer">
                          <a className="contest_joining_popup">FULL</a>
                        </div>
                      ) : scrimData?.isJoined === true ? (
                        <div className="inner pointer">
                          <a className="d-flex justify-content-center align-items-center">
                            <b
                              className="contest_joining_popup d-flex justify-content-center align-items-center"
                              // onClick={() => joinedContest()}
                            >
                              <div
                                className="d-flex justify-content-center align-items-center"
                                style={{ height: "25px" }}
                                onClick={() => Scrimdetail()}
                              >
                                JOINED
                              </div>

                              <div
                                style={{
                                  width: "20px",
                                  height: "25px",
                                  marginLeft: "5px",
                                }}
                              >
                                <Lottie
                                  options={{
                                    animationData: successCheck,
                                    w: "20px",
                                  }}
                                />
                              </div>
                            </b>
                          </a>
                        </div>
                      ) : (
                        <div
                          className="inner pointer"
                          onClick={() => getjoin(scrimData)}
                        >
                          <a className="contest_joining_popup">JOIN NOW</a>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const Scrimdetail = () => {
    window.location.href = "/scrim-details/" + scrimData?._id;
  };
  const onJoinScrim = async () => {
    const gameNames = JSON.parse(localStorage.getItem("userInGameNames"));
    var user_data = JSON.parse(localStorage.getItem("gamerjiLogindetails"));
    let data = {
      scrimId: scrimData?._id,
      userId: user_data.item.user_id,
      usersInGameName: gameNames?.[0]?.userINGameName,
      gamerJiName: user_data?.item.gamerjiName,
      mobileNo: user_data?.item?.phone
        ? user_data?.item?.phone
        : user_data?.item?.email,
    };

    if (
      scrimData?.gameType?.players &&
      parseInt(scrimData?.gameType?.players) > 1
    ) {
      var numArray = [];
      var selectedPlayer = [];
      for (var i = 2; i <= parseInt(scrimData?.gameType?.players); i++) {
        numArray.push(i);

        selectedPlayer.push({
          userId: null,
          inGameName: null,
          gamerJiName: user_data?.item.gamerjiName,
          country: null,
          mobileNo: null,
        });
      }
      setPlayers(selectedPlayer.length);
      setShowScrimTeam(true);
      setShowConfirm(false);
    } else {
      let res = await onCreateScrim(data);
      // setPlayers(res.gameType)
      {
        res?.success === true
          ? (window.location.href = "/scrim-details/" + scrimData?._id)
          : toast.error(res.data.data[0]?.msg, {
              position: "top-left",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
      }
    }
  };
  const navigate = useNavigate(-1);
  const setShowTeam = () => {
    setShowScrimTeam(false);
  };

  return (
    // {/* Main Full Screen */}
    <div className="inner-wrap">
      <JoinViaPath />
      {loader ? (
        <Loader />
      ) : (
        <>
          <Header title={contestName} navMenuClick={() => navigate(-1)} />

          {/* Main Body */}
          <div
            className="body mCustomScrollbar _mCS_1"
            style={{ paddingTop: "20px" }}
          >
            <div
              className="all_sub_screens_div_related_to_ads"
              style={{ display: "inherit" }}
            >
              <ToastContainer />
              {/* Join Via Invite Code Box - Tournaments - Tournament/all  */}
              {/* {(allFeaturedData?.length > 0 || allData?.length > 0) && ( */}
                <div className="recent_transaction_items more_tab_items join_code">
                  <div className="accordion" id="accordionExample">
                    <div className="item">
                      <div
                        className="accordion-header"
                        data-toggle="collapse"
                        data-target="#a_6"
                        aria-expanded="false"
                      >
                        <div style={{ fontSize: "16px", fontWeight: "600" }}>
                          Join Via Invite Code
                        </div>
                        <span
                          className="icon"
                          style={{ width: "25px", height: "25px" }}
                        >
                          {/* <img className="tc_jvic_arrow_img" src={DropDownBlue} /> */}
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M17 10L12 15L7 10"
                              stroke="white"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                      </div>
                      <div
                        id="a_6"
                        className="txt mb-0 collapse"
                        data-parent="#accordionExample"
                      >
                        <div className="join_code">
                          <input
                            type="text"
                            placeholder="Enter Invite Code"
                            value={code}
                            onChange={(e) => setCode(e?.target?.value?.trim())}
                          />
                          <button className="apply_btn" onClick={applyCode}>
                            ADD
                          </button>
                        </div>
                        <p className="error">{applyMsg}</p>
                      </div>
                    </div>
                  </div>
                </div>
              {/* )} */}

              {/* Tournament/all or Tournament/{id} */}
              {id === "all"
                ? createTournaments()
                : createContests()}

              {!isLastPage && (
                <div className="load_more text-center mb-3">
                  <button
                    className="btn btn-dark text-white"
                    onClick={() => {
                      gameId === "all"
                        ? getTournamentListAPICall(page + 1)
                        : getContestListAPICall(gameId, page + 1);
                    }}
                  >
                    {loader1 === true ? "Loading ..." : "Load More"}
                  </button>
                </div>
              )}
              {/* Join Now Popup - Click on Apply Code - Tournament/all - Tournament/{id} */}
              {tournamentJoin === true && createAfterApplyCodePopup()}
              {scrimJoin === true && createScrimApplyCodePopup()}
            </div>

            {/* Join Popup - joinContestWalletPopup */}
            {showConfirm === true && (
              <div className="tc_popup_bg">
                <div
                  className="custom-popup rules confirm custom_scroll join show scrim-applyCode-Popup"
                  id="contact_popup"
                >
                  <div className="popup-head">
                    {/* <marquee> */}
                    <h3> {scrimData?.title} </h3>
                    {/* </marquee> */}

                    <button
                      className="close-popup"
                      onClick={() => setShowConfirm(false)}
                    >
                      <FiX />
                    </button>
                  </div>
                  <div className="popup-body bg-yellow">
                    <div className="all_sub_screens_div_related_to_ads">
                      <p className="game_det">
                        <span>
                          {scrimData?.game?.name}-{scrimData?.gameType?.name}
                        </span>
                        <span>
                          {getProfiledateFormat(scrimData?.date)} {}
                          {formatAMPM(new Date(scrimData?.time))}
                        </span>
                      </p>
                      <p className="game_det">
                        <span>Hosted By: {scrimData?.hostedBy}</span>
                        <span></span>
                      </p>

                      <button
                        className="btn btn-dark main-btn next scrim-tournament-btn"
                        onClick={() => onJoinScrim()}
                      >
                        JOIN CONTEST{" "}
                        <img
                          src={rightArrow}
                          alt=""
                          className="mCS_img_loaded"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {showScrimTeam === true && (
              <ScrimSquadRegistration
                players={players}
                setShowTeam={setShowTeam}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default Tournaments;
