import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import TagManager from "react-gtm-module";
import { ProfileProvider } from "./component/ProfileComponent/ProfileContext";

const tagManagerArg = {
  gtmId: "G-NWVPWWCL3N",
};
TagManager.initialize(tagManagerArg);
ReactDOM.render(
  <React.StrictMode>
    <Router>
      {/* <ProfileProvider> */}
      <App />
      {/* </ProfileProvider> */}
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
