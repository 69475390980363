const trackerEventId = {
  All_Games_Page_Shown: "e4bb7e2e-cffd-4317-a61a-841d924e792e",
  Game_Types_Page_Shown: "af270226-c328-44fc-a920-6a51f01891b7",
  IGN_Popup_Shown: "fb335f2f-7f90-4696-946f-084396cd2588",
  Tournaments_List_Shown: "daac2166-0090-425f-ab0c-0faa6b9ca024",
  Tournament_Popup_Shown: "5db997db-fa80-4b64-a705-93464ca0b192",
  Join_Tournament_Popup_Shown: "884d9a79-a76b-4dab-b435-4987fc0adf6b",
  Tournament_Details_Shown: "67fcb144-1efa-4fd2-a518-ec26c55a03f5",
  Tournament_Details_Prize_Pool_Popup_Shown:
    "fb904624-095a-42a5-b4d3-4ebc4b9b4332",
  Tournament_Details_Tournament_Table_Shown:
    "9eae826e-4db5-4659-8c20-9a63b7a0528e",
  Tournament_Player_List_Shown: "d2b27878-4489-4528-b163-aca9e08bafd3",
  Tournament_Rules_Page_Shown: "c752a4f0-4559-4d48-a1da-505704478afb",
  How_To_Join_Tournament_Shown: "6884fd84-b448-4302-a54f-42e37466d8f3",
  Contest_List_Shown: "6e17d071-4b87-4ace-a299-c66b6019ef81",
  Contest_Join_Popup_Shown: "da04d019-40e5-47ec-b6ee-36aafa00b48d",
  Team_Registration_Shown: "529c6e70-def9-4c3d-8a7a-c22d5fbcbe75",
  Contest_Details_Shown: "1e73c7d0-797e-4de5-a02d-742600d02d01",
  Contest_Details_Prize_Pool_Popup_Shown:
    "ae626dcb-ad25-48e6-97a3-7feb457744e9",
  Contest_Player_List_Shown: "3566a36a-5954-40f7-b5f4-90e7ef3121c4",
  Contest_Rules_Page_Shown: "7a6a50ce-8a08-4936-9444-b87a8019de8a",
};

export { trackerEventId };
