const routes = {
  indiggSignin: "auth/login",
  indiggRefreshToken: "auth/refreshToken",
  indiggUserExits: "auth/userExists",
  signin: "login",
  emailotp: "login-email-otp",
  sendemailotp: "send-email-otp",

  signinInternational: "login-international",
  signupInternational: "signup-international",
  signupEmail: "send-email-otp",
  signinEmail: "signin-email",
  checkAppUser: "check-app-user",
  verifyRecaptcha: "verify-recaptcha",
  campaignsCompaniesDetails: "campaigns-companies-details",

  countries: "country-list",
  list: "list",
  gameList: "gameList",
  get: "get/",
  terms: "page/terms",
  privacy: "page/privacy-policy",
  mylist: "my-list",
  profile: "profile",
  updateprofile: "profile/update",
  contestlist: "contest/list",
  walletusage: "wallet-usage-limit",
  createContest: "joinUserContest/join",
  joinScrim: "scrim/joinScrim",
  createTournament: "gamerjiTournament/join",
  contesteventbycode: "contest-event-by-code",
  dailyreward: "daily-rewards",
  listdailyreward: "daily-rewards/list",
  collectdailyreward: "collect-daily-rewards",
  stateList: "state-list",
  signupCode: "apply-singup-code",
  avatarFree: "avatars/free-list",
  coinStoreList: "coin-stores/list",
  coinStoreGet: "coin-stores/get/",
  createRazTransaction: "account/create-raz-transaction",
  createCashfreeTransaction: "account/create-cashfree-transaction",
  createCashfreeTransactionWeb: "account/create-cashfree-transaction-web",
  requestCashfreePaymentlink: "account/get-cashfree-paymentlink-web",
  //requestLinkBankAccount: "account/link-bank-account",
  requestLinkBankAccount: "account/link-bank-account-easebuzz",
  requestLinkUpiAccount: "account/link-upi-account-easebuzz",
  requestVerifyVPA: "account/verify-vpa-easebuzz",
  requestWithdrawal: "account/withdrawal-request",
  getTransactionDetails: "account/get-transaction-details",
  avatarList: "avatar-categories/list",
  rewardCatList: "reward-categories/list",
  rewardProdMyList: "reward-products/my-list",
  leaderboardsList: "event-leaderboards/list",
  levelsList: "levels/list",
  howToJoin: "how-to-play/list",
  myOldContestList: "contests/my-list",
  myContestList: "joinUserContest/list",
  myEventsList: "gamerjiTournament/my-list",
  gameStatistics: "account/game-statistics",
  leaderboard: "leaderboard-",
  applyReward: "apply-reward",
  buyAvatar: "buy-avatar",
  allPoints: "points/list",
  allPointsCategories: "point-categories/list",
  getContestRate: "get-contest-rate",
  contestRate: "contest-rate",

  searchUserList: "users/list",
  collegeList: "colleges/list",

  applyEventCode: "tournaments/contest-event-by-code",
  applyContestCode: "contest/get-by-code/",
  ticketList: "tickets/list",
  categoryList: "customercare/category/list",
  searchGames: "games/search",
  uploadSS: "tickets/upload",
  createTicket: "tickets/create",
  getTicketChat: "tickets/get/",
  updateTicketChat: "tickets/update/",
  rankSummeryByGame: "rank-summery-by-game",
  contestByGame: "contest-by-game",
  followings: "followings",
  followers: "followers",
  followUser: "follow-user",
  checkUser: "check",
  checkUsers: "inGameName/byUser",
  signup: "signup",
  createPaymentIntent: "create-payment-intent",
  createStripeTransactionWeb: "create-stripe-transaction-web",
  createStripePaymentIntentWeb: "create-payment-intent",

  // Word of E-Sports
  woeLiveStreamingsList: "live-streamings/list",
  woeUsersList: "users/list",
  woeBlogsList: "blogs/list",

  // All Screens ID
  apkScreensList: "apk-screens/list",

  // Sponsors Ads Banner Ads
  sponsorAdsList: "sponsor-ads/list",
  sponsorAdLog: "sponsor-ad-log",
  bannerAdLog: "banner-ad-log",

  // Apply Promo Code
  applyPromoCode: "apply-promo-code",

  // How To Play
  howToPlay: "how-to-play",

  // DOBNState
  updateDOBNState: "update-dob-state",
  sendOTP: "send-otp",
  validateOTP: "validate-otp",

  // Contests
  checkDateTime: "contest/check-datetime",

  // Customer Care
  userContestList: "contests/user-contest-list",

  // Free Games
  html5SettingsList: "html5-settings/list",
  html5CategoriesList: "html5-categories/list",
  html5GamesList: "html5-games/list",
  html5GamesGet: "html5-games/get",
  html5GamesUpdateView: "html5-games/update-view",

  // My Recent Transactions
  transactionsList: "transactions/list",
  transactionsSendInvoiceEmail: "transactions/send-invoice-email",
  transactionsExport: "transactions/export",

  // Payment Gateway
  paymentOptions: "payment-options",
  paymentOptionsWeb: "payment-options-web",
  cfBankList: "cf-bank-list",

  // Verify Mobile & Email
  checkEmail: "check-email",
  googleLogin: "google-login",

  // My Videos
  userVideoRequestsList: "user-video-requests/list",
  userVideoRequestsVideoList: "user-video-requests/video-list",
  userVideoRequestsCheckChannel: "user-video-requests/check-channel",
  userVideoRequestsCreate: "user-video-requests/create",
  getInGameName: "inGameName/byUser",
  getInGameNames: "inGameName/byUsers",
  addInGameName: "inGameName/add",
  contestPlayerList: "joinedContestUserByContestId/",
  returnPayment: "return-payment",
  howToCreateScrim: "scrim/getHowToCreateScrim",
  rateScrim: "scrim/updateScrimRating",
  getGameTypeById: "scrim/getGameType",
  createScrim: "scrim/createScrim",
  gameRules: "scrim/GameRules",
  manageScrim: "scrim/viewScrimById",
  updateScrim: "scrim/updateScimById",
  roomIdAndPassword: "scrim/updateRoomIdPassword",
  scrimLeaderBoard: "scrim/getLeaderBorad",
  scrimList: "scrim/ScrimListByUserId",
  getScrimById: "scrim/getScrimById",
  updateWinners: "scrim/updateWinners",
  ssUpload: "scrim/SSupload/",
  statusUpdate: "scrim/updateStatus/",
  getScrimByUserId: "scrim/getScrimByUserId",
  getStatusScrim: "scrim/GetStatus",
  getUserDetail: "scrim/getUserDetail",
  createPhonepeTransaction: "api/account/create-phonepay-transaction",
  getSettings: "settings/getsettings",
  xsollaList: "xsolla/list",
  getAds: "adDownload/getAds",
  updateCoin: "adDownload/updateCoin",
  tournamentList: "tournaments/list",
  tournamentJoin: "gamerjiTournament/join",
  featuredTournament: "tournaments/featuredTournaments",
  indiggTournamentJoin: "indigg/joinUserTournament/join",
  indiggCreateContest: "indigg/joinUserContest/join",
  applyContestCodeType: "contest/get-by-code-type/",
};
export default routes;
