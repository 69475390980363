import url from "../../constants/url";

export const Captioncard = () => {
  var user_data = JSON.parse(localStorage.getItem("loginData"));
  const gameNames = JSON.parse(localStorage.getItem("userInGameNames"));
  const currenctGame = localStorage.getItem("activeGameId");

  const foundUser = gameNames?.find(user => user?.game?.toString() === currenctGame?.toString());

  console.log(gameNames);
  console.log(currenctGame);
  console.log(foundUser);

  const countryObj = JSON.parse(localStorage.getItem("userCountryObj"));
  return (
    <div className="row squad_registration_caption_player_row p-0">
      <div className="col-12 m-0 p-0 scrim-squad-registration">
        <div className="scrim-squad-registration-title flex  gap2 ">
          <div className="width2 scrim-squad-registration-Caption text-align">
            Captain
          </div>
          <div class="margin-left-2-2 mt-1">{user_data?.userName}</div>
        </div>
        <div className="scrim-squad-registration-title flex flex-direction gap padding10">
          <div className="flex ">
            <div className="width3 align-self f14">In Game Name:</div>
            <div className="width7">
              <input
                // placeholder="i am stuti "
                className="scrim-squad-registration-input width "
                value={foundUser?.userINGameName}
                disabled
              />
            </div>
          </div>

          {/* {user_data?.item?.email !== "" &&
          user_data?.item?.phone === undefined ? (
            <div>
              <div className="flex  ">
                <div className="width3 flex input-container">
                  <input
                    className="scrim-squad-registration-input width input-value-scrims"
                    type="text"
                    disabled
                    value="Email"
                  />
                </div>
                <div className=" width7">
                  <input
                    // placeholder="82197644999 "
                    className="scrim-squad-registration-input width"
                    value={user_data?.item?.email}
                    disabled
                  />
                </div>
              </div>
            </div>
          ) : (
            <div>
              {console.log(">>>>>>>>>>>>>", user_data)}
              <div className="flex  ">
                <div className="width3 flex input-container">
                  <span className="input-container-icon">
                    <img
                      style={{
                        width: "16px",
                        height: "16px",
                        marginLeft: "12px",
                      }}
                      src={url.imageUrl + countryObj?.flag?.default}
                    />
                  </span>

                  <input
                    className="scrim-squad-registration-input width input-value-scrims"
                    type="text"
                    disabled
                    value={countryObj?.dialingCode}
                  />
                </div>
                <div className=" width7">
                  <input
                    // placeholder="82197644999 "
                    className="scrim-squad-registration-input width"
                    value={user_data?.item?.phone}
                    disabled
                  />
                </div>
              </div>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};
