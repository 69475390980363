import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  gameTypes,
  getUpdateProfile,
  howToJoinGame,
  getGamesDetails,
  getUserInGameName,
  addUserInGameName,
} from "../apis";
import Previous from "../assets/images/previous.svg";
import url from "../constants/url";
import { dateFormat, goback } from "../component/common";
import allGames from "../assets/images/all-games.svg";
import rightArrow from "../assets/images/right-arrow.svg";
// import Close from "../assets/images/close.svg";
import Loader from "../component/loader";
import { routehelp } from "../config/routehelp";
import ReactHtmlParser from "react-html-parser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { constants } from "../constants/constants";

import { FiX } from "react-icons/fi";

import Header from "../component/header";

import { addTracker } from "../component/tracker";

import { trackerEventId } from "../config/tracker";

import JoinViaPath from "./JoinViaPath";

function SingleGame(props) {
  let { id } = useParams();
  const [loader, setLoader] = useState(false);
  const [allData, setAllData] = useState([]);

  const [gameid, setGameid] = useState("all");
  const [gameData, setGameData] = useState("all");

  const [title, setTitle] = useState("");
  const [showContacts, setShowContacts] = useState(false);
  const [type, setType] = useState("");
  const [tounamentsImg, setTounamentsImg] = useState("");
  const [currentName, setcurrentName] = useState("");
  const [newName, setnewtName] = useState("");
  const [howtoJoinTitle, setHowtoJoinTitle] = useState("");
  const [howtoJoin, setHowtoJoin] = useState("");
  const [showHowtoJoin, setShowHowtoJoin] = useState(false);
  const [error, setError] = useState(false);
  const [gameNames, setGameNames] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const addTrackData = async (string) => {
    if (localStorage.getItem("loginData")) {
      let userData = JSON.parse(localStorage.getItem("loginData"));
      await addTracker(`userid=${userData?.userId}&${string}`);
    }
  };

  let findUserName = (gameid) => {
    let gameNames = JSON.parse(localStorage.getItem("userInGameNames"));
    if (gameNames) {
      let index = gameNames?.findIndex((obj) => obj?.game == gameid);
      if (index > -1) {
        setcurrentName(gameNames[index].userINGameName);
        return gameNames[index].userINGameName || "";
      } else {
        return "";
      }
    }
  };

  const fetchData = async () => {
    if (localStorage.getItem("loginData")) {
      setLoader(true);

      if (localStorage.getItem("currentPage")) {
        let allpage = [...JSON.parse(localStorage.getItem("currentPage"))];
        var count = allpage.findIndex((d) => d === window.location.pathname);
        count === -1 && allpage.push(window.location.pathname);
        localStorage.setItem("currentPage", JSON.stringify(allpage));
      } else {
        let allpage = [];
        allpage.push(window.location.pathname);
        localStorage.setItem("currentPage", JSON.stringify(allpage));
      }

      var gameid = id;
      setGameid(id);
      let allimgs = JSON.parse(localStorage.getItem("tournamentImgs"));
      if (allimgs) {
        let featured = allimgs.find((val) => val.id === gameid);
        setTounamentsImg(featured?.tournamentFeaturedImage);
        // localStorage.setItem("activeGameId", featured?.id);
      }
      setType(
        window.location.href.split("/")[
          window.location.href.split("/").length - 2
        ]
      );

      let payload = {
        search: {
          gameId: gameid,
        },
        pagination: {
          sortby: "order",
          sort: "asc",
        },
      };
      let response = await gameTypes(payload);
      if (response) {
        let temp = [];
        let gametype = [];
        response?.data?.length > 0 &&
          response?.data?.map(async (item, index) => {
            gametype.push({ id: item?._id, name: item?.name });
            temp.push({
              id: item._id,
              img: url.imageUrl + item?.displayImage?.default,
              name: item?.name,
              createdAt: dateFormat(item?.createdAt),
              updatedAt: dateFormat(item?.updatedAt),
            });
          });

        localStorage.setItem("activeGameType", JSON.stringify(gametype));

        setAllData(temp);
      }

      response = await getGamesDetails(gameid);

      if (response) {
        setGameData(response?.data);
        setTitle(response?.data?.name);
        localStorage.setItem("activeGame", response?.data?.name);
        localStorage.setItem("activeGameId", response?.data?._id);
        localStorage.setItem(
          "activeTournamnetId",
          response?.data?.tournamentGameID
        );
        localStorage.setItem("Game_Details", JSON.stringify(response?.data));

        var user_data = JSON.parse(localStorage.getItem("loginData"));

        payload = {
          search: {
            user: user_data?.user_id,
          },
        };

        let userInGameNameResponse = await getUserInGameName(payload);
        setGameNames(userInGameNameResponse.data);
        localStorage.setItem(
          "userInGameNames",
          JSON.stringify(userInGameNameResponse.data)
        );

        findUserName(response?.data?._id);
        //add event code comment 
        // addTrackData(
        //   `gameCatalogName=${response?.data?.name}&gameCatalogId=${response?.data?._id}&eventId=${trackerEventId?.Game_Types_Page_Shown}`
        // );
        //setcurrentName(igname ? igname?.uniqueIGN : "");
        setLoader(false);
      }
    }
  };

  const fetchDataOpen = () => {
    fetchData();
    setShowContacts(true);
  };

  const onSubmit = async () => {
    if (newName !== "") {
      var user_data = JSON.parse(localStorage.getItem("loginData"));

      let payload = {
        user: user_data?.user_id,
        game: localStorage.getItem("activeGameId"),
        userINGameName: newName,
      };

      let res = await addUserInGameName(payload);
      if (res) {
        GetInGameNames();
        setcurrentName(newName);
        setShowContacts(false);
        toast.success(constants.success_Game_Username_updated_successfully, {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        addTrackData(
          `gameCatalogName=${localStorage.getItem(
            "activeGame"
          )}&gameCatalogId=${localStorage.getItem("activeGameId")}&eventId=${
            trackerEventId?.IGN_Popup_Shown
          }`
        );
      }
      setnewtName("");
    } else {
      //   setError(true);
      toast.error(constants.success_Please_enter_Gamerji_username, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false;
    }
  };

  const GetInGameNames = async () => {
    var user_data = JSON.parse(localStorage.getItem("loginData"));

    let payload = {
      search: {
        user: user_data?.user_id,
      },
    };

    let userInGameNameResponse = await getUserInGameName(payload);
    if (userInGameNameResponse?.success)
      localStorage.setItem(
        "userInGameNames",
        JSON.stringify(userInGameNameResponse.data)
      );
  };

  const onClickJoin = async () => {
    setShowHowtoJoin(true);
    var gameid =
      window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ];

    let res = await howToJoinGame({
      filter: { game: gameData.tournamentGameID },
    });

    // addTrackData(
    //   `gameCatalogName=${localStorage.getItem(
    //     "activeGame"
    //   )}&gameCatalogId=${localStorage.getItem("activeGameId")}&eventId=${
    //     trackerEventId?.How_To_Join_Tournament_Shown
    //   }`
    // );

    if (res) {
      setHowtoJoin(res?.list[0]?.content);
      setHowtoJoinTitle(res?.list[0]?.title);
    }
  };

  const toggleContact = () => {
    setShowContacts(false);
    setError(false);
  };
  const navigate = useNavigate(-1);
  return (
    <>
      {showHowtoJoin === false ? (
        <div className="inner-wrap">
          <JoinViaPath />
          <ToastContainer />
          <Header title={title} navMenuClick={() => navigate(-1)} />
          <div
            className="body mCustomScrollbar _mCS_1 bottom-btn-body"
            id={showContacts === true ? "popup_show" : ""}
            style={{ paddingTop: "20px" }}
          >
            <h2
              className="title mt-3"
              style={{ cursor: "pointer" }}
              onClick={onClickJoin}
            >
              <span className="icon">
                <img src={allGames} alt="" />
              </span>
              How to Join?
            </h2>
            {loader ? (
              <Loader />
            ) : (
              <div className="games_items games_big_items">
                {gameData.isTournament === true && (
                  <div className="item">
                    {/* // eslint-disable-next-line */}
                    <Link to={`/tournament/all`} className="d-block">
                      <div className="box">
                        <div className="img pt-2 pl-2 pr-2">
                          <img src={tounamentsImg} alt="" />
                        </div>
                        <h3>TOURNAMENTS</h3>
                      </div>
                    </Link>
                  </div>
                )}
                {allData?.map((item, index) => {
                  return (
                    <div className="item" key={index}>
                      {/* // eslint-disable-next-line */}
                      <Link to={`/tournament/${item.id}`} className="d-block">
                        <div className="box">
                          <div className="img pt-2 pl-2 pr-2">
                            <img src={item.img} alt="" />
                          </div>
                          <h3 style={{ textTransform: "uppercase" }}>
                            {item.name}
                          </h3>
                        </div>
                      </Link>
                    </div>
                  );
                })}
              </div>
            )}

            {localStorage.getItem("loginData") && (
              <div className="bottom_btn games_big_items_bottom_btn">
                <p
                  className="text-center w-100"
                  onClick={() => fetchDataOpen()}
                  style={{ fontSize: "19px" }}
                >
                  <span className="border-bottom-dark">
                    Add/Change {title} Username
                  </span>{" "}
                  <br />
                </p>
                <Link
                  to={routehelp.mycontest}
                  className="btn btn-dark main-btn text-center"
                >
                  MY CONTESTS{" "}
                </Link>
              </div>
            )}

            {showContacts === true && (
              <div className="custom-popup show" id="contact_popup">
                <div className="popup-head">
                  <h3>Add/Change {title} Username</h3>
                  <button
                    className="close-popup"
                    onClick={() => toggleContact()}
                  >
                    {/* <img src={Close} alt="" /> */}
                    <FiX />
                  </button>
                </div>
                <div className="popup-body" style={{ marginBottom: "0px" }}>
                  <div className="all_sub_screens_div_related_to_ads">
                    {currentName && (
                      <div className="form-group">
                        <label style={{ fontSize: "16px" }}>
                          {/* Current {title} Username */}
                          Current Username
                        </label>
                        <input
                          type="text"
                          value={currentName}
                          disabled={true}
                          className="form-control"
                          placeholder=""
                        />
                      </div>
                    )}
                    <div className="form-group">
                      <label style={{ fontSize: "16px" }}>
                        {/* New {title} Username */}
                        New Username
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={newName}
                        maxLength={32}
                        onChange={(e) => setnewtName(e.target.value)}
                        placeholder="Enter New Username"
                      />
                      {error === true && (
                        <p className="text-secondary">
                          Please enter user name.
                        </p>
                      )}
                    </div>
                    <div className="form-group">
                      <p
                        className="color-grey-2 text-center form-note pt-3"
                        style={{ fontSize: "14px", fontWeight: "300" }}
                      >
                        Note: You can change username only once!
                      </p>
                    </div>
                    <button
                      className="btn btn-dark main-btn"
                      onClick={onSubmit}
                    >
                      SUBMIT
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="inner-wrap">
          <JoinViaPath />
          <div className="header">
            <div className="back-btn">
              <a onClick={() => setShowHowtoJoin(false)}>
                <img src={Previous} alt="" />
              </a>
            </div>
            <h2>{howtoJoinTitle}</h2>
            <div className="wallet-btn"></div>
          </div>
          <div className="body mCustomScrollbar _mCS_1 bottom-btn-body">
            <div className="all_sub_screens_div_related_to_ads">
              {howtoJoin?.length > 0 ? (
                howtoJoin?.map((item, index) => {
                  return (
                    <div className="mb-1 how-to-join-ui" key={index}>
                      {item?.type === "text" ? (
                        <li>
                          <span>{ReactHtmlParser(item?.content)}</span>
                        </li>
                      ) : (
                        <img
                          className="mb-3"
                          src={item?.content}
                          style={{ width: "100%" }}
                        />
                      )}
                    </div>
                  );
                })
              ) : (
                <p>no data found</p>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default SingleGame;
