const helperFunc = {
  getCountryId: function () {
    if (localStorage.getItem("countryId") !== undefined) {
      const countryId = JSON.parse(localStorage.getItem("countryId"));

      return countryId;
    } else {
      return "";
    }
  },
  getUserCountry: function () {
    if (localStorage.getItem("userCountryObj") !== undefined) {
      const userCountryObj = JSON.parse(localStorage.getItem("userCountryObj"));

      return userCountryObj;
    } else {
      return { code: "IN" };
    }
  },
  getStripeAPIKey: function () {
    return "pk_test_51KezD8SB4g2oK7dcsmm8TRlmVJFipgCPClcZnnO2xfywtgLG0UKyxEQ99qSO0fPXDWVOO4zQVlifn5uvSwZoldYE00PqEStE5G";
    // return "pk_live_51KezD8SB4g2oK7dckoygbLuLc4BaWXbhII7G0AYuInwvl4QMK3Pw8avz9qtY4g3FWIHgTVG3wByrt78z6AijsGZy006G7sNjV1";
  },
  getUserData: function () {
    const user = JSON.parse(localStorage.getItem("userdata"));
    return user;
  },
  authData: function () {
    const token = JSON.parse(localStorage.getItem("token"));
    return token;
  },
  isLoginUser: function () {
    const token = JSON.parse(localStorage.getItem("token"));
    if (token && (token !== "" || token != null)) {
      return true;
    } else {
      return false;
    }
  },
  isRegisterContest: function (contestId) {
    const userdata = JSON.parse(localStorage.getItem("userdata"));
    if (userdata.contests && userdata.contests.includes(contestId)) {
      return false;
    }
    return true;
  },
  socialMediaLinks: [
    { type: "facebook", url: "http://www.fb.com/" },
    { type: "instagram", url: "https://www.instagram.com/" },
    { type: "youtube", url: "https://www.youtube.com/" },
    { type: "linkedin", url: "https://in.linkedin.com/" },
    { type: "twitch", url: "https://www.twitch.tv/" },
    { type: "twitter", url: "https://twitter.com/?lang=en" },
  ],
};

export default helperFunc;
