import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { FiX } from "react-icons/fi";

import {
  getContestRate,
  getContestDetails,
  getContestPlayerList,
  contestRate,
} from "../apis";
import { getProfiledateFormat, formatAMPM } from "../component/common";
import Friendship from "../assets/images/friendship.svg";
import { useRanger } from "react-ranger";
import Gamer from "../assets/images/gamer.svg";
import Gun2 from "../assets/images/gun-2.svg";
import Podium from "../assets/images/podium.svg";
import TrophyYellow from "../assets/images/ic_trophy_yellow.png";
import url from "../constants/url";
import ReactHtmlParser from "react-html-parser";
import { RWebShare } from "react-web-share";
import Loader from "../component/loader";

import Header from "../component/header";

import { addTracker } from "../component/tracker";

import { trackerEventId } from "../config/tracker";

import JoinViaPath from "./JoinViaPath";

function ContestDetails(props) {
  const [loader, setLoader] = useState(false);
  const [allData, setAllData] = useState(null);
  const [values, setValues] = React.useState([10]);
  const [progressWidth, setProgressWidth] = useState(0);
  const [captainData, setCaptainData] = useState([]);
  const [allPlayersData, setAllPlayersData] = useState([]);
  const [showWinners, setShowWinners] = useState(false);
  const [allWinners, setAllWinners] = useState([]);
  const [page, setPage] = useState(0);
  const [isLastPage, setIsLastPage] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const addTrackData = async (string) => {
    if (localStorage.getItem("loginData")) {
      let userData = JSON.parse(localStorage.getItem("loginData"));
      await addTracker(`userid=${userData?.userId}&${string}`);
    }
  };

  const fetchData = async () => {
    setLoader(true);
    if (localStorage.getItem("currentPage")) {
      let allpage = [...JSON.parse(localStorage.getItem("currentPage"))];
      var count = allpage.findIndex((d) => d === window.location.pathname);
      count === -1 && allpage.push(window.location.pathname);
      localStorage.setItem("currentPage", JSON.stringify(allpage));
    } else {
      let allpage = [];
      allpage.push(window.location.pathname);
      localStorage.setItem("currentPage", JSON.stringify(allpage));
    }

    var gameid = JSON.parse(localStorage.getItem("joinTounament"))?.shortCode;
    var contestid =
      window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ];
    let response = await getContestDetails(contestid);
    if (response) {
      var per =
        (100 * response?.data?.totalJoinedPlayers) /
          response?.data?.totalPlayers +
        "%";
      setProgressWidth(per);
      setAllData(response?.data);

      // addTrackData(
      //   `gameCatalogName=${localStorage.getItem(
      //     "activeGame"
      //   )}&gameCatalogId=${localStorage.getItem("activeGameId")}&tournamentId=${
      //     response?.data?._id
      //   }&eventId=${trackerEventId?.Contest_Details_Shown}`
      // );

      setLoader(false);
    }
    getLoadMore();

    var gameid =
      window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ];
  };

  const getLoadMore = async () => {
    setLoader(true);

    var user_data = JSON.parse(localStorage.getItem("loginData"));
    var contestid =
      window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ];

    var payloadPlayer = {
      search: {
        user: user_data?.user_id,
      },
      pagination: {
        pageNo: page + 1,
        recordPerPage: 10,
        sortBy: "rank",
        sortDirection: "asc",
      },
    };

    let playerResponse = await getContestPlayerList(contestid, payloadPlayer);
    if (playerResponse && playerResponse?.data?.length < 10) {
      setIsLastPage(true);
    }
    if (playerResponse && playerResponse?.data) {
      if (page === 0) setCaptainData(playerResponse?.captainData);
      setAllPlayersData([...allPlayersData, ...playerResponse?.data]);
    }

    setLoader(false);
    setPage(page + 1);
  };
  const navigate = useNavigate(-1);

  const getWinnerClick = (item) => {
    // addTrackData(
    //   `gameCatalogName=${localStorage.getItem(
    //     "activeGame"
    //   )}&gameCatalogId=${localStorage.getItem("activeGameId")}&tournamentId=${
    //     allData._id
    //   }&eventId=${trackerEventId?.Contest_Details_Prize_Pool_Popup_Shown}`
    // );

    setShowWinners(true);
    setAllWinners(item);
  };

  return (
    <div className="inner-wrap">
      <JoinViaPath />
      <ToastContainer />
      <Header title={"Contest Details"} navMenuClick={() => navigate(-1)} />
      {allData === null ? (
        <Loader />
      ) : (
        <div
          className="body mCustomScrollbar _mCS_1 bottom-btn-body"
          id={`${showWinners === true ? "popup_show" : ""}`}
          style={{ paddingTop: "10px" }}
        >
          <div className="all_sub_screens_div_related_to_ads">
            <div className="list-group main-tab" id="list-tab" role="tablist">
              <a
                className="list-group-item list-group-item-action active"
                id="list-home-list"
                data-toggle="list"
                href="#list-1"
              >
                Details
              </a>
              <a
                className="list-group-item list-group-item-action"
                id="list-profile-list"
                data-toggle="list"
                href="#list-2"
                onClick={(e) => {
                  // addTrackData(
                  //   `gameCatalogName=${localStorage.getItem(
                  //     "activeGame"
                  //   )}&gameCatalogId=${localStorage.getItem(
                  //     "activeGameId"
                  //   )}&tournamentId=${allData._id}&eventId=${
                  //     trackerEventId?.Contest_Player_List_Shown
                  //   }`
                  // );
                }}
              >
                Players
              </a>
              <a
                className="list-group-item list-group-item-action"
                id="list-profile-list"
                data-toggle="list"
                href="#list-3"
                onClick={(e) => {
                  // addTrackData(
                  //   `gameCatalogName=${localStorage.getItem(
                  //     "activeGame"
                  //   )}&gameCatalogId=${localStorage.getItem(
                  //     "activeGameId"
                  //   )}&tournamentId=${allData._id}&eventId=${
                  //     trackerEventId?.Contest_Rules_Page_Shown
                  //   }`
                  // );
                }}
              >
                Rules
              </a>
            </div>
            <div className="tab-content" id="nav-tabContent">
              <div className="tab-pane active" id="list-1">
                <div className="contest-list contest-tournament-list game_tounament">
                  <div className="box" style={{ maxHeight: "13.5rem" }}>
                    <h3 style={{ fontSize: "16px", fontWeight: "800" }}>
                      {/* <marquee> */}
                      {allData?.title}
                      {/* </marquee> */}
                      <span className="pointer">ID: {allData?.shortCode}</span>
                    </h3>
                    <div
                      className="row tournament_contest_row"
                      style={{ marginBottom: "-37px" }}
                    >
                      <div className="col-12">
                        <div
                          className="inner_row contest bg-dark"
                          style={{ justifyContent: "space-between" }}
                        >
                          <div className="inner_item">
                            Date <b>{getProfiledateFormat(allData?.date)}</b>
                          </div>
                          <div className="inner_item">
                            Time <b>{formatAMPM(new Date(allData?.time))}</b>
                          </div>
                          {allData?.titles?.map((item, index) => {
                            if (item?.name !== "-") {
                              return (
                                <div className="inner_item" key={index}>
                                  {item?.name} <b>{item?.value}</b>
                                </div>
                              );
                            }
                          })}
                        </div>
                        <div
                          className="inner_row"
                          style={{ justifyContent: "space-between" }}
                        >
                          <div className="inner_item">
                            Prize Pool <br />{" "}
                            <b>
                              {allData?.contestFor?.outCurrencyInfo?.code ==
                                "INR" ||
                              allData?.contestFor?.outCurrencyInfo?.code ==
                                "inr" ? (
                                "₹"
                              ) : (
                                <img
                                  src={
                                    url.imageUrl +
                                    allData?.contestFor?.outCurrencyInfo?.img
                                      ?.default
                                  }
                                  className="coin_img"
                                />
                              )}{" "}
                              {allData?.prizePool}
                            </b>
                          </div>
                          <div className="inner_item">
                            {allData.winningModel === "perRank"
                              ? "Winners"
                              : "Per Kill"}{" "}
                            {allData.winningModel === "perRank" ? (
                              <b
                                className="pointer"
                                onClick={() => getWinnerClick(allData)}
                              >
                                {" "}
                                {allData?.totalWinners}{" "}
                                <i className="fas fa-chevron-down"></i>
                              </b>
                            ) : (
                              <b className="">
                                {allData?.contestFor?.outCurrencyInfo?.code ==
                                "INR" ? (
                                  "₹"
                                ) : (
                                  <img
                                    src={
                                      url.imageUrl +
                                      allData?.contestFor?.outCurrencyInfo?.img
                                        ?.default
                                    }
                                    alt=""
                                    className="coin_img"
                                  />
                                )}{" "}
                                {allData?.killPoints}{" "}
                              </b>
                            )}
                          </div>
                          <div className="inner_item">
                            Join Using <br />{" "}
                            {allData?.entryFee > 0 ? (
                              <b>
                                <img
                                  src={
                                    url.imageUrl +
                                    allData?.contestFor?.inCurrencyInfo?.img
                                      ?.default
                                  }
                                  className="coin_img"
                                />{" "}
                                {allData?.entryFee}
                              </b>
                            ) : (
                              <b>Free</b>
                            )}
                          </div>
                        </div>
                        <div className="avalible_wrap">
                          <div className="inner">
                            <span
                              style={{
                                width: progressWidth,
                              }}
                            ></span>
                          </div>
                          <p className="d-flex justify-content-between text-light">
                            <span className="">
                              {allData?.totalPlayers -
                                allData?.totalJoinedPlayers}{" "}
                              players remaining
                            </span>
                            <span className="">
                              {allData?.totalJoinedPlayers} player joined
                            </span>
                          </p>
                        </div>
                        <div className="contest_foot contest_single p-2">
                          <div className="inner">
                            <span>Room ID</span>
                            <b style={{ fontSize: "12px" }}>
                              {allData?.roomId === "" ? "-" : allData?.roomId}
                            </b>
                          </div>
                          <div className="inner">
                            <span>Password</span>
                            <b style={{ fontSize: "12px" }}>
                              {allData?.roomPassword === ""
                                ? "-"
                                : allData?.roomPassword}
                            </b>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: "3rem" }}>
                  <div className="player-icons-captain">
                    <div className="row no-gutters">
                      <div className="col-8">
                        <div className="item" style={{ width: "95px" }}>
                          <div className="icon">
                            <img src={Gamer} alt="" />
                          </div>
                          Username
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="item">
                          <div className="icon">
                            <img src={Gun2} alt="" />
                          </div>
                          Kills
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="item">
                          <div className="icon">
                            <img src={Podium} alt="" />
                          </div>
                          Rank
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="player_captain contest mb-3">
                    {captainData && (
                      <div className="item">
                        <div className="col-8">
                          <div className="row m-0 p-0">
                            <div className="col-12 d-flex justify-content-start align-items-center">
                              <b style={{ fontSize: "16px" }}>
                                {captainData?.userData?.gamerjiName
                                  ? captainData?.userData?.gamerjiName
                                  : captainData?.inGameUserName}
                              </b>
                            </div>
                            <div className="col-12 d-flex justify-content-start align-items-center">
                              {allData?.status == "completed" ? (
                                captainData?.totalAmount > 0 ? (
                                  <div className="" style={{ marginTop: 5 }}>
                                    <img
                                      src={TrophyYellow}
                                      alt=""
                                      className="coin_img mr-1"
                                    />{" "}
                                    <span
                                      className="mr-1"
                                      style={{ fontSize: "13px" }}
                                    >
                                      Won{" "}
                                    </span>
                                    {(allData?.contestFor?.outCurrencyInfo
                                      ?.code === "INR" ||
                                      allData?.contestFor?.outCurrencyInfo
                                        ?.code === "inr") &&
                                      "₹"}
                                    {allData?.contestFor?.outCurrencyInfo
                                      ?.code && (
                                      <img
                                        src={
                                          url.imageUrl +
                                          allData?.contestFor?.outCurrencyInfo
                                            ?.img?.default
                                        }
                                        alt=""
                                        className="coin_img mr-1"
                                      />
                                    )}{" "}
                                    <span
                                      className="mr-1"
                                      style={{ fontSize: "13px" }}
                                    >
                                      {captainData?.totalAmount}
                                    </span>
                                  </div>
                                ) : (
                                  ""
                                )
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-2 d-flex justify-content-center align-items-center"
                          style={{ height: "40px" }}
                        >
                          <b className="">
                            {captainData?.kills === null ||
                            captainData?.kills === 0
                              ? "-"
                              : captainData?.kills}
                          </b>
                        </div>
                        <div
                          className="col-2 d-flex justify-content-center align-items-center"
                          style={{ height: "40px" }}
                        >
                          <b className="">
                            {captainData?.rank === null ||
                            captainData?.rank === 0
                              ? "-"
                              : captainData?.rank}
                          </b>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <RWebShare
                  data={{
                    text: "Hey! Join me on Indigg for this exciting match. Click on hte following link and let's play together",
                    url: `${url.shareUrl}${allData?.shortCode}&type=contest`,
                    title: "Join me on Indigg",
                  }}
                  onClick={() => console.info("share successful!")}
                >
                  <div className="row friendship_items">
                    <div className="col-12">
                      <div className="box winning_breakup_popup_toggle">
                        <div className="icon">
                          <img src={Friendship} alt="" />
                        </div>
                        Share with Friends
                      </div>
                    </div>
                  </div>
                </RWebShare>

                {showWinners === true && (
                  <div
                    className="custom-popup rules custom_scroll show"
                    id="contact_popup"
                  >
                    <div className="popup-head">
                      <h3>Winning Breakup</h3>
                      <button
                        className="close-popup"
                        onClick={() => setShowWinners(false)}
                      >
                        <FiX />
                      </button>
                    </div>
                    <div className="popup-body">
                      <div className="all_sub_screens_div_related_to_ads">
                        {allWinners?.pointpool &&
                          allWinners?.pointpool?.length !== 0 && (
                            <div
                              className="list-group main-tab w-90"
                              id="list-tab"
                              role="tablist"
                            >
                              <a
                                className="list-group-item list-group-item-action active"
                                id="list-home-list"
                                data-toggle="list"
                                href="#prize-1"
                              >
                                Prize Pool
                              </a>
                              <a
                                className="list-group-item list-group-item-action"
                                id="list-profile-list"
                                data-toggle="list"
                                href="#point-2"
                              >
                                Points
                              </a>
                            </div>
                          )}
                        <div className="tab-content" id="nav-tabContent">
                          <div className="tab-pane active" id="prize-1">
                            <div className="prize_pool mb-3 text-center p-2 w-90">
                              <p className="color-dark">Prize Pool</p>
                              <h6>
                                {(allData?.contestFor?.outCurrencyInfo?.code ===
                                  "INR" ||
                                  allData?.contestFor?.outCurrencyInfo?.code ===
                                    "inr") &&
                                  "₹"}
                                {allData?.contestFor?.outCurrencyInfo?.code && (
                                  <img
                                    src={
                                      url.imageUrl +
                                      allData?.contestFor?.outCurrencyInfo?.img
                                        ?.default
                                    }
                                    alt=""
                                    className="coin_img_big"
                                  />
                                )}{" "}
                                {allWinners?.prizePool}
                              </h6>
                            </div>
                            <div className="table-responsive total_table total_table_border mb-3 w-90">
                              <table>
                                <tbody>
                                  {allWinners?.prizePoolInfo?.map(
                                    (item1, index1) => {
                                      return (
                                        <tr key={index1}>
                                          <td>
                                            Rank:{" "}
                                            {item1?.rankTo !== "0" &&
                                            item1?.rankTo !== item1?.rankFrom
                                              ? item1?.rankFrom +
                                                "-" +
                                                item1?.rankTo
                                              : item1?.rankFrom}
                                          </td>
                                          <th className="text-right">
                                            {(allData?.contestFor
                                              ?.outCurrencyInfo?.code ===
                                              "INR" ||
                                              allData?.contestFor
                                                ?.outCurrencyInfo?.code ===
                                                "inr") &&
                                              "₹"}
                                            {allData?.contestFor
                                              ?.outCurrencyInfo?.code && (
                                              <img
                                                src={
                                                  url.imageUrl +
                                                  allData?.contestFor
                                                    ?.outCurrencyInfo?.img
                                                    ?.default
                                                }
                                                alt=""
                                                className="coin_img"
                                              />
                                            )}{" "}
                                            {item1?.amount}
                                          </th>
                                        </tr>
                                      );
                                    }
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          {allWinners?.pointpool && (
                            <div className="tab-pane" id="point-2">
                              <div className="table-responsive total_table total_table_border mb-3 w-90">
                                <table>
                                  <tbody>
                                    {allWinners?.pointpool?.map(
                                      (item1, index1) => {
                                        return (
                                          <tr key={index1}>
                                            <td>
                                              Rank:{" "}
                                              {item1?.rankTo !== "0"
                                                ? item1?.rankFrom +
                                                  "-" +
                                                  item1?.rankTo
                                                : item1?.rankFrom}
                                            </td>
                                            <th className="text-right">
                                              {item1?.point}
                                            </th>
                                          </tr>
                                        );
                                      }
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="tab-pane" id="list-2">
                <div className="player_icons">
                  <div className="row no-gutters">
                    <div className="col-3">
                      <div className="item" style={{ width: "95px" }}>
                        <div className="icon">
                          <img src={Gamer} alt="" />
                        </div>
                        Username
                      </div>
                    </div>
                    <div className="col-5">&nbsp; </div>
                    <div className="col-2">
                      <div className="item">
                        <div className="icon">
                          <img src={Gun2} alt="" />
                        </div>
                        Kills
                      </div>
                    </div>
                    <div className="col-2">
                      <div className="item">
                        <div className="icon">
                          <img src={Podium} alt="" />
                        </div>
                        Rank
                      </div>
                    </div>
                  </div>
                </div>

                <div className="player_list contest">
                  {captainData && (
                    <div className="item active">
                      <div
                        className="inner_big"
                        style={{ height: "30px", display: "flex" }}
                      >
                        <div className="row m-0 p-0">
                          <div className="col-12 d-flex justify-content-start align-items-center">
                            <b
                              style={{
                                alignSelf: "center",
                                justifyContent: "center",
                                fontSize: "16px",
                              }}
                            >
                              {captainData?.userData?.gamerjiName
                                ? captainData?.userData?.gamerjiName
                                : captainData?.inGameUserName}
                            </b>
                          </div>
                          <div className="col-12 d-flex justify-content-start align-items-center">
                            {allData?.status == "completed" ? (
                              captainData?.totalAmount > 0 ? (
                                <div style={{ marginTop: 5 }}>
                                  <img
                                    src={TrophyYellow}
                                    alt=""
                                    className="coin_img mr-1"
                                  />{" "}
                                  <span
                                    className="mr-1"
                                    style={{ fontSize: "13px" }}
                                  >
                                    Won
                                  </span>
                                  {(allData?.contestFor?.outCurrencyInfo
                                    ?.code === "INR" ||
                                    allData?.contestFor?.outCurrencyInfo
                                      ?.code === "inr") &&
                                    "₹"}
                                  {allData?.contestFor?.outCurrencyInfo
                                    ?.code && (
                                    <img
                                      src={
                                        url.imageUrl +
                                        allData?.contestFor?.outCurrencyInfo
                                          ?.img?.default
                                      }
                                      alt=""
                                      className="coin_img mr-1"
                                    />
                                  )}{" "}
                                  <span
                                    className="mr-1"
                                    style={{ fontSize: "13px" }}
                                  >
                                    {captainData?.totalAmount}
                                  </span>
                                </div>
                              ) : (
                                ""
                              )
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="inner">
                        <b>
                          {captainData?.kills === null ||
                          captainData?.kills === 0
                            ? "-"
                            : captainData?.kills}
                        </b>
                      </div>
                      <div className="inner">
                        <b>
                          {captainData?.rank === null || captainData?.rank === 0
                            ? "-"
                            : captainData?.rank}
                        </b>
                      </div>
                    </div>
                  )}
                  {allPlayersData?.map((item, index) => {
                    if (captainData && captainData?._id !== item?._id) {
                      return (
                        <div className="item" key={index}>
                          <div className="inner inner_big">
                            <div className="row m-0 p-0">
                              <div className="col-12 d-flex justify-content-start align-items-center">
                                <b>
                                  {item?.userData.gamerjiName
                                    ? item?.userData.gamerjiName
                                    : item?.inGameUserName}
                                </b>
                              </div>
                              <div className="col-12 d-flex justify-content-start align-items-center">
                                {allData?.status == "completed" ? (
                                  item?.totalAmount > 0 ? (
                                    <div>
                                      <img
                                        src={TrophyYellow}
                                        alt=""
                                        className="coin_img mr-1"
                                      />{" "}
                                      <span className="mr-1">Won</span>
                                      {allData?.contestFor?.outCurrencyInfo
                                        ?.code === "INR" && "₹"}
                                      {allData?.contestFor?.outCurrencyInfo
                                        ?.code && (
                                        <img
                                          src={
                                            url.imageUrl +
                                            allData?.contestFor?.outCurrencyInfo
                                              ?.img?.default
                                          }
                                          alt=""
                                          className="coin_img mr-1"
                                        />
                                      )}{" "}
                                      <span className="mr-1">
                                        {item?.totalAmount}
                                      </span>
                                    </div>
                                  ) : (
                                    ""
                                  )
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="inner">
                            <b>
                              {item?.kills === null || item?.kills === 0
                                ? "-"
                                : item?.kills}
                            </b>
                          </div>
                          <div className="inner">
                            <b>
                              {item?.rank === null || item?.rank === 0
                                ? "-"
                                : item?.rank}
                            </b>
                          </div>
                        </div>
                      );
                    }
                  })}
                  {!isLastPage && allPlayersData.length > 0 && (
                    <div
                      className="load_more text-center"
                      style={{ bottom: "-7px" }}
                    >
                      <button
                        className="btn btn-dark"
                        onClick={() => getLoadMore()}
                      >
                        {loader === true ? "Loading ..." : "Load More"}
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className="tab-pane" id="list-3">
                <div className="tab_content">
                  <p>{ReactHtmlParser(allData?.rules)}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ContestDetails;
