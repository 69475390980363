import React, { Fragment, useEffect, useState } from "react";
import AllGames from "../assets/images/all-games.svg";
import GameIconSmall1 from "../assets/images/game-icon-small3.png";
import { Link } from "react-router-dom";
import { allIndiggGames } from "../apis";
import { convertNumberToMillions, dateFormat } from "../component/common";
import url from "../constants/url";
import Loader from "../component/loader";
import { addTracker } from "../component/tracker";

import { trackerEventId } from "../config/tracker";

function Allgames(props) {
  const [loader, setLoader] = useState(false);
  const [allData, setAllData] = useState([]);

  useEffect(() => {
    fetchData();
   // addTrackData();
  }, []);

  const addTrackData = async () => {
    if (localStorage.getItem("loginData")) {
      let userData = JSON.parse(localStorage.getItem("loginData"));
      await addTracker(
        `userid=${userData?.userId}&eventId=${trackerEventId?.All_Games_Page_Shown}`
      );
    }
  };

  const fetchData = async () => {
    setLoader(true);
    let temp = [];

    let data = {
      pagination: {
        pageNo: 1,
        recordPerPage: 100,
        sortBy: "order",
        sortDirection: "asc",
      },
    };
    let response = await allIndiggGames(data);
    if (response) {
      response?.games?.length &&
        response?.games?.map(async (item, index) => {
          temp.push({
            id: item._id,
            img: url.imageUrl + item?.featuredImage?.default,
            tournamentFeaturedImage:
              url.imageUrl + item?.tournamentFeaturedImage?.default,
            name: item?.name,
            tournaments: "tournaments",
            join: convertNumberToMillions(
              item && "playedCount" in item ? item?.playedCount : 0
            ),
            prize: item?.totalprizePool,
            createdAt: dateFormat(item?.createdAt),
            updatedAt: dateFormat(item?.updatedAt),
          });
        });
      let featured = [];
      response?.games?.length &&
        response?.games?.map(async (item, index) => {
          featured.push({
            id: item._id,
            tournamentFeaturedImage:
              url.imageUrl + item?.tournamentFeaturedImage?.default,
          });
        });
      localStorage.setItem("tournamentImgs", JSON.stringify(featured));

      setLoader(false);
    }

    setAllData(temp);
  };

  return (
    <Fragment>
      <h2 className="icon-title">
        <span className="icon">
          <img src={AllGames} alt="" />
        </span>
        All Games
      </h2>

      {loader ? (
        <Loader />
      ) : (
        <div className="games_items">
          {allData?.map((item, index) => {
            return (
              <div className="item" key={index}>
                {/* // eslint-disable-next-line */}
                <Link
                  to={`/${item.tournaments}/${item.id}`}
                  className="d-block"
                >
                  <div className="box">
                    {/* <marquee> */}
                    <b className="all_games_page_all_games_title text_animation_container pb-4">
                      {item.name}
                    </b>
                    {/* </marquee> */}
                    <div className="img pl-1 pr-1">
                      <img src={item.img} alt="" />
                      <div className="img-caption">
                        <div className="img-caption-item text_animation_container">
                          <img src={GameIconSmall1} alt="" />
                          <p style={{ marginLeft: "5px" }}>
                            <span style={{ fontWeight: "700" }}>
                              {item.join}
                            </span>{" "}
                            <span>Played</span>{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      )}
    </Fragment>
  );
}
export default Allgames;
