import React, { Fragment, useEffect, useState } from "react";
import Previous from "../assets/images/previous.svg";
import Wallet from "../assets/images/wallet.svg";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CopyIcon from "../assets/images/copy-icon.svg";
import Close from "../assets/images/close.svg";
import {
  getAllLeaderboardsList,
  getContestEventByCode,
  getContestRate,
  getContestDetails,
  getOldContestDetails,
} from "../apis";
import {
  dateFormat,
  getProfiledateFormat,
  formatAMPM,
  goback,
} from "../component/common";
import Friendship from "../assets/images/friendship.svg";
import R2 from "../assets/images/r-2.svg";
import R3 from "../assets/images/r-3.svg";
import R4 from "../assets/images/r-4.svg";
import R5 from "../assets/images/r-5.svg";
import { useRanger } from "react-ranger";
import Gamer from "../assets/images/gamer.svg";
import Gun2 from "../assets/images/gun-2.svg";
import Podium from "../assets/images/podium.svg";
import R1 from "../assets/images/r-1.svg";
import Picon1 from "../assets/images/p_icon_1.png";
import TrophyBlue from "../assets/images/ic_trophy_blue.png";
import TrophyYellow from "../assets/images/ic_trophy_yellow.png";
import url from "../constants/url";

import ReactHtmlParser from "react-html-parser";
import { RWebShare } from "react-web-share";
import Loader from "../component/loader";
import { constants } from "../constants/constants";

import Header from "../component/header";

function ContestDetails(props) {
  const [loader, setLoader] = useState(false);
  const [allData, setAllData] = useState(null);
  const [allRound, setAllRound] = useState([true, false, false]);
  const [values, setValues] = React.useState([10]);
  const [progressWidth, setProgressWidth] = useState(0);
  const [captainData, setCaptainData] = useState([]);
  const [captainLevelData, setCaptainLevelData] = useState({});
  const [allPlayersData, setAllPlayersData] = useState([]);
  const [rate, setRate] = React.useState(0);
  const [showWinners, setShowWinners] = useState(false);
  const [allWinners, setAllWinners] = useState([]);

  const { getTrackProps, handles } = useRanger({
    min: 0,
    max: 50,
    stepSize: 10,
    values,
    onChange: setValues,
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoader(true);
    if (localStorage.getItem("gamerjiToken")) {
      if (localStorage.getItem("currentPage")) {
        let allpage = [...JSON.parse(localStorage.getItem("currentPage"))];
        var count = allpage.findIndex((d) => d === window.location.pathname);
        count === -1 && allpage.push(window.location.pathname);
        localStorage.setItem("currentPage", JSON.stringify(allpage));
      } else {
        let allpage = [];
        allpage.push(window.location.pathname);
        localStorage.setItem("currentPage", JSON.stringify(allpage));
      }

      var gameid = JSON.parse(localStorage.getItem("joinTounament"))?.shortCode;
      var contestid =
        window.location.href.split("/")[
          window.location.href.split("/").length - 1
        ];
      let response = await getOldContestDetails(contestid);
      if (response) {
        var per =
          (100 * response?.item?.totalJoinedPlayers) /
            response?.item?.totalPlayers +
          "%";
        setProgressWidth(per);
        setAllData(response?.item);
        setCaptainData(response?.captainData);
        setCaptainLevelData(response?.CaptainLevelData);

        setAllPlayersData(response?.contestUsers);
        setLoader(false);
      }

      var gameid =
        window.location.href.split("/")[
          window.location.href.split("/").length - 1
        ];
    } else {
      window.location.href = "/sign-in";
    }
  };

  const onChangeRate = async (rate) => {
    setRate(rate);
    var gameid =
      window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ];

    const data = { rate: 5, contest: gameid };
    let res = await getContestRate(data);
    if (res) {
    }
  };

  const getWinnerClick = (item) => {
    setShowWinners(true);
    setAllWinners(item);
  };

  return (
    <div className="inner-wrap">
      <ToastContainer />
      {/* <div className="header">
        <div className="back-btn">
          <a onClick={() => goback()}>
            <img src={Previous} alt="" />
          </a>
        </div>
        <h2>Contest Details</h2>
        <div className="wallet-btn">
        </div>
      </div> */}
      <Header title={"Contest Details"} navMenuClick={goback} />
      {allData === null ? (
        <Loader />
      ) : (
        <div
          className="body mCustomScrollbar _mCS_1 bottom-btn-body"
          id={`${showWinners === true ? "popup_show" : ""}`}
          style={{ paddingTop: "10px" }}
        >
          <div className="all_sub_screens_div_related_to_ads">
            <div className="list-group main-tab" id="list-tab" role="tablist">
              <a
                className="list-group-item list-group-item-action active"
                id="list-home-list"
                data-toggle="list"
                href="#list-1"
              >
                Details
              </a>
              <a
                className="list-group-item list-group-item-action"
                id="list-profile-list"
                data-toggle="list"
                href="#list-2"
              >
                Players
              </a>
              <a
                className="list-group-item list-group-item-action"
                id="list-profile-list"
                data-toggle="list"
                href="#list-3"
              >
                Rules
              </a>
            </div>
            <div className="tab-content" id="nav-tabContent">
              <div className="tab-pane active" id="list-1">
                <div className="contest-list contest-tournament-list game_tounament">
                  <div className="box">
                    <h3 style={{ fontSize: "16px", fontWeight: "800" }}>
                      {/* <marquee> */}
                      {allData?.title}
                      {/* </marquee> */}
                      <span className="pointer">ID: {allData?.shortCode}</span>
                    </h3>
                    <div
                      className="row tournament_contest_row"
                      style={{ marginBottom: "-37px" }}
                    >
                      <div className="col-12">
                        <div className="inner_row contest">
                          <div className="inner_item">
                            Date <b>{getProfiledateFormat(allData?.date)}</b>
                          </div>
                          <div className="inner_item">
                            Time <b>{formatAMPM(new Date(allData?.time))}</b>
                          </div>
                          {allData?.titles?.map((item, index) => {
                            if (item?.name !== "-") {
                              return (
                                <div className="inner_item" key={index}>
                                  {item?.name} <b>{item?.value}</b>
                                </div>
                              );
                            }
                          })}
                        </div>
                        <div className="inner_row bg-dark">
                          <div className="inner_item">
                            Prize Pool <br />{" "}
                            <b>
                              <img
                                src={
                                  url.imageUrl + allData?.currency?.img?.default
                                }
                                className="coin_img"
                              />{" "}
                              {allData?.prizePool}
                            </b>
                          </div>
                          <div className="inner_item">
                            Winners{" "}
                            <b
                              className="pointer"
                              onClick={() => getWinnerClick(allData)}
                            >
                              {" "}
                              {allData?.totalWinners}{" "}
                              <i className="fas fa-chevron-down"></i>
                            </b>
                          </div>
                          <div className="inner_item">
                            Join Using <br />{" "}
                            {allData?.entryFee > 0 ? (
                              <b>
                                <img
                                  src={
                                    url.imageUrl +
                                    allData?.currency?.img?.default
                                  }
                                  className="coin_img"
                                />{" "}
                                {allData?.entryFee}
                              </b>
                            ) : (
                              <b>Free</b>
                            )}
                          </div>
                        </div>
                        <div className="avalible_wrap">
                          <div className="inner">
                            <span
                              style={{
                                width: progressWidth,
                              }}
                            ></span>
                          </div>
                          <p className="d-flex justify-content-between text-light">
                            <span className="">
                              {allData?.totalPlayers -
                                allData?.totalJoinedPlayers}{" "}
                              players remaining
                            </span>
                            <span className="">
                              {allData?.totalJoinedPlayers} player joined
                            </span>
                          </p>
                        </div>
                        <div className="contest_foot contest_single p-2">
                          <div className="inner pl-4">
                            <span>Room ID</span>
                            <b style={{ fontSize: "12px" }}>
                              {allData?.roomId === "" ? "-" : allData?.roomId}{" "}
                              {allData?.roomId != "" && (
                                <img
                                  src={CopyIcon}
                                  alt=""
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      allData?.roomId
                                    );
                                    toast.info(constants.info_Copied, {
                                      position: "top-left",
                                      autoClose: 5000,
                                      hideProgressBar: false,
                                      closeOnClick: true,
                                      pauseOnHover: true,
                                      draggable: true,
                                      progress: undefined,
                                    });
                                  }}
                                />
                              )}
                            </b>
                          </div>
                          <div className="inner pl-4">
                            <span>Password</span>
                            <b style={{ fontSize: "12px" }}>
                              {allData?.roomPassword === ""
                                ? "-"
                                : allData?.roomPassword}{" "}
                              {allData?.roomPassword != "" && (
                                <img
                                  src={CopyIcon}
                                  alt=""
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      allData?.roomPassword
                                    );
                                    toast.info(constants.info_Copied, {
                                      position: "top-left",
                                      autoClose: 5000,
                                      hideProgressBar: false,
                                      closeOnClick: true,
                                      pauseOnHover: true,
                                      draggable: true,
                                      progress: undefined,
                                    });
                                  }}
                                />
                              )}
                            </b>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="player-icons-captain">
                    <div className="row no-gutters">
                      <div className="col-8">
                        <div className="item" style={{ width: "95px" }}>
                          <div className="icon">
                            <img src={Gamer} alt="" />
                          </div>
                          Gamerji Username
                        </div>
                      </div>
                      {/* <div className="col-5">&nbsp; </div> */}
                      <div
                        className="col-2"
                        style={{ borderLeft: "1px solid #707070" }}
                      >
                        <div className="item">
                          <div className="icon">
                            <img src={Gun2} alt="" />
                          </div>
                          Kills
                        </div>
                      </div>
                      <div
                        className="col-2"
                        style={{ borderLeft: "1px solid #707070" }}
                      >
                        <div className="item">
                          <div className="icon">
                            <img src={Podium} alt="" />
                          </div>
                          Rank
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="player_captain contest mb-3">
                    {captainData?.map((item, index) => {
                      return (
                        <div className="item" key={index}>
                          <div className="col-8">
                            <div className="row m-0 p-0">
                              <div className="icon">
                                <img
                                  src={
                                    url.imageUrl +
                                    captainLevelData?.level?.featuredImage
                                      ?.default
                                  }
                                  alt=""
                                  style={{ width: "40px", marginRight: "10px" }}
                                />
                              </div>
                              <div
                                className="d-flex my-auto h-100"
                                style={{ flexDirection: "column" }}
                              >
                                <b style={{ fontSize: "16px" }}>
                                  {item?.user?.gamerjiName}
                                </b>
                                {/* <br /> */}
                                <small>
                                  {item?.user && item?.user?.phone
                                    ? "xxxxx" +
                                      item?.user?.phone.slice(
                                        item?.user?.phone?.length - 5
                                      )
                                    : ""}
                                </small>
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-2 d-flex justify-content-center align-items-center"
                            style={{ height: "40px" }}
                          >
                            <b className="">
                              {item?.kills === null || item?.kills === 0
                                ? "-"
                                : item?.kills}
                            </b>
                          </div>
                          <div
                            className="col-2 d-flex justify-content-center align-items-center"
                            style={{ height: "40px" }}
                          >
                            <b className="">
                              {item?.rank === null || item?.rank === 0
                                ? "-"
                                : item?.rank}
                            </b>
                          </div>
                        </div>
                      );
                    })}

                    {/* <div className="load_more text-center">
                  <Link to="/" className="btn btn-primary">
                    Load More
                  </Link>
                </div> */}
                  </div>
                </div>

                <RWebShare
                  data={{
                    text: "Hey! Join me on Gamerji for this exciting match. Click on hte following link and let's play together",
                    url: `${url.siteUrl}contest-details/${
                      window.location.href.split("/")[
                        window.location.href.split("/").length - 1
                      ]
                    }`,
                    title: "Join me on Gamreji",
                  }}
                  onClick={() => console.info("share successful!")}
                >
                  <div className="row friendship_items">
                    <div className="col-12">
                      <div className="box winning_breakup_popup_toggle">
                        <div className="icon">
                          <img src={Friendship} alt="" />
                        </div>
                        Share with Friends
                      </div>
                    </div>
                  </div>
                </RWebShare>

                {showWinners === true && (
                  <div
                    className="custom-popup rules custom_scroll show"
                    id="contact_popup"
                  >
                    <div className="popup-head">
                      <h3>Winning Breakup</h3>
                      <button
                        className="close-popup"
                        onClick={() => setShowWinners(false)}
                      >
                        <img src={Close} alt="" />
                      </button>
                    </div>
                    <div className="popup-body bg-yellow">
                      <div className="all_sub_screens_div_related_to_ads">
                        {allWinners?.pointpool &&
                          allWinners?.pointpool?.length !== 0 && (
                            <div
                              className="list-group main-tab w-90"
                              id="list-tab"
                              role="tablist"
                            >
                              <a
                                className="list-group-item list-group-item-action active"
                                id="list-home-list"
                                data-toggle="list"
                                href="#prize-1"
                              >
                                Prize Pool
                              </a>
                              <a
                                className="list-group-item list-group-item-action"
                                id="list-profile-list"
                                data-toggle="list"
                                href="#point-2"
                              >
                                Points
                              </a>
                            </div>
                          )}
                        <div className="tab-content" id="nav-tabContent">
                          <div className="tab-pane active" id="prize-1">
                            <div className="prize_pool mb-3 text-center p-2 w-90">
                              <p>Prize Pool</p>
                              <h6>
                                {allWinners?.currency?.code == "INR" && "₹"}
                                {allWinners?.currency?.code == "coin" && (
                                  <img
                                    src={
                                      url.imageUrl +
                                      allWinners?.currency?.img?.default
                                    }
                                    alt=""
                                    className="coin_img_big"
                                  />
                                )}{" "}
                                {allWinners?.prizePool}
                              </h6>
                            </div>
                            <div className="table-responsive total_table total_table_border mb-3 w-90">
                              <table>
                                <tbody>
                                  {allWinners?.prizepool?.map(
                                    (item1, index1) => {
                                      return (
                                        <tr key={index1}>
                                          <td>
                                            Rank:{" "}
                                            {item1?.rankTo !== "0"
                                              ? item1?.rankFrom +
                                                "-" +
                                                item1?.rankTo
                                              : item1?.rankFrom}
                                          </td>
                                          <th className="text-right">
                                            {allWinners?.currency?.code ==
                                              "INR" && "₹"}
                                            {allWinners?.currency?.code ==
                                              "coin" && (
                                              <img
                                                src={
                                                  url.imageUrl +
                                                  allWinners?.currency?.img
                                                    ?.default
                                                }
                                                alt=""
                                                className="coin_img"
                                              />
                                            )}{" "}
                                            {item1?.amount}
                                          </th>
                                        </tr>
                                      );
                                    }
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          {allWinners?.pointpool && (
                            <div className="tab-pane" id="point-2">
                              <div className="table-responsive total_table total_table_border mb-3 w-90">
                                <table>
                                  <tbody>
                                    {allWinners?.pointpool?.map(
                                      (item1, index1) => {
                                        return (
                                          <tr key={index1}>
                                            <td>
                                              Rank:{" "}
                                              {item1?.rankTo !== "0"
                                                ? item1?.rankFrom +
                                                  "-" +
                                                  item1?.rankTo
                                                : item1?.rankFrom}
                                            </td>
                                            <th className="text-right">
                                              {item1?.point}
                                            </th>
                                          </tr>
                                        );
                                      }
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {rate === 0 ? (
                  <Fragment>
                    <h2 className="title mb-3 mt-4">
                      Rate Gamerji e-Sports Private Limited{" "}
                    </h2>
                    <div className="r-wrap">
                      <div className="r-image">
                        <div className="img" onClick={() => onChangeRate(1)}>
                          <img src={R1} alt="" />
                        </div>
                        <div className="img" onClick={() => onChangeRate(2)}>
                          <img src={R2} alt="" />
                        </div>
                        <div className="img" onClick={() => onChangeRate(3)}>
                          <img src={R3} alt="" />
                        </div>
                        <div className="img" onClick={() => onChangeRate(4)}>
                          <img src={R4} alt="" />
                        </div>
                        <div className="img" onClick={() => onChangeRate(5)}>
                          <img src={R5} alt="" />
                        </div>
                      </div>
                    </div>
                  </Fragment>
                ) : (
                  <Fragment>
                    <h2 className="title mb-3 mt-4">You Provided Ratings </h2>
                    <div className="r-wrap">
                      <div className="text-center">
                        <div className="img">
                          <img
                            src={
                              rate === 1
                                ? R1
                                : rate === 2
                                ? R2
                                : rate === 3
                                ? R3
                                : rate === 4
                                ? R4
                                : R5
                            }
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </Fragment>
                )}
              </div>

              <div className="tab-pane" id="list-2">
                <div className="player_icons">
                  <div className="row no-gutters">
                    <div className="col-3">
                      <div className="item" style={{ width: "95px" }}>
                        <div className="icon">
                          <img src={Gamer} alt="" />
                        </div>
                        Gamerji Username
                      </div>
                    </div>
                    <div className="col-5">&nbsp; </div>
                    <div className="col-2">
                      <div className="item">
                        <div className="icon">
                          <img src={Gun2} alt="" />
                        </div>
                        Kills
                      </div>
                    </div>
                    <div className="col-2">
                      <div className="item">
                        <div className="icon">
                          <img src={Podium} alt="" />
                        </div>
                        Rank
                      </div>
                    </div>
                  </div>
                </div>

                <div className="player_list contest">
                  {captainData?.map((item, index) => {
                    return (
                      <div className="item active" key={index}>
                        <div className="inner inner_big">
                          <div className="icon">
                            <img
                              style={{ width: "30px", height: "30px" }}
                              src={
                                url.imageUrl +
                                captainLevelData?.level?.featuredImage?.default
                              }
                              alt=""
                            />
                          </div>
                          <b>{item?.user?.gamerjiName}</b>
                          <br />
                          <small>
                            {item?.user && item?.user?.phone
                              ? "xxxxx" +
                                item?.user?.phone.slice(
                                  item?.user?.phone?.length - 5
                                )
                              : ""}
                          </small>
                          {allData?.status == "completed" ? (
                            item?.totalAmount > 0 ? (
                              <div>
                                <img
                                  src={TrophyYellow}
                                  alt=""
                                  className="coin_img"
                                />{" "}
                                Won {allData?.currency?.code == "INR" && "₹"}
                                {allData?.currency?.code == "coin" && (
                                  <img
                                    src={
                                      url.imageUrl +
                                      allData?.currency?.img?.default
                                    }
                                    alt=""
                                    className="coin_img"
                                  />
                                )}{" "}
                                {item?.totalAmount}
                              </div>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="inner">
                          <b>
                            {item?.kills === null || item?.kills === 0
                              ? "-"
                              : item?.kills}
                          </b>
                        </div>
                        <div className="inner">
                          <b>
                            {item?.rank === null || item?.rank === 0
                              ? "-"
                              : item?.rank}
                          </b>
                        </div>
                      </div>
                    );
                  })}
                  {allPlayersData?.map((item, index) => {
                    if (
                      captainData.length > 0 &&
                      captainData[0]?._id !== item._id
                    ) {
                      return (
                        <div className="item" key={index}>
                          <div className="inner inner_big">
                            <div className="icon">
                              <img
                                src={
                                  url.imageUrl +
                                  item?.level?.featuredImage?.default
                                }
                                alt=""
                              />
                            </div>
                            <b>{item?.user?.gamerjiName}</b>
                            <br />
                            <small>
                              {item?.user && item?.user?.phone
                                ? "xxxxx" +
                                  item?.user?.phone.slice(
                                    item?.user?.phone?.length - 5
                                  )
                                : ""}
                            </small>
                            {allData?.status == "completed" ? (
                              item?.totalAmount > 0 ? (
                                <div>
                                  <img
                                    src={TrophyBlue}
                                    alt=""
                                    className="coin_img"
                                  />{" "}
                                  Won {allData?.currency?.code == "INR" && "₹"}
                                  {allData?.currency?.code == "coin" && (
                                    <img
                                      src={
                                        url.imageUrl +
                                        allData?.currency?.img?.default
                                      }
                                      alt=""
                                      className="coin_img"
                                    />
                                  )}{" "}
                                  {item?.totalAmount}
                                </div>
                              ) : (
                                ""
                              )
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="inner">
                            <b>
                              {item?.kills === null || item?.kills === 0
                                ? "-"
                                : item?.kills}
                            </b>
                          </div>
                          <div className="inner">
                            <b>
                              {item?.rank === null || item?.rank === 0
                                ? "-"
                                : item?.rank}
                            </b>
                          </div>
                        </div>
                      );
                    }
                  })}

                  {/* <div className="load_more text-center">
                  <Link to="/" className="btn btn-primary">
                    Load More
                  </Link>
                </div> */}
                </div>
              </div>
              <div className="tab-pane" id="list-3">
                <div className="tab_content">
                  <p>{ReactHtmlParser(allData?.rules)}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ContestDetails;
