import { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { Co2Sharp, PhoneAndroid } from "@mui/icons-material";
import { ConsoleView } from "react-device-detect";
import { constants } from "../../constants/constants";
import { checkUserDetails } from "../../apis";
import validator from "validator";
import url from "../../constants/url";
export const PlayerCard = ({ players }) => {
  // const [activeTab, setActiveTab] = useState("Mobile");
  const [activeTabs, setActiveTabs] = useState({});
  const [phone, setPhone] = useState("");
  const [disabledCards, setDisabledCards] = useState([]);
  const [players_data, setPlayers_data] = useState(() => {
    const initialData = [];
    let extra = JSON.parse(localStorage.getItem("scrimData"))?.gameType
      ?.isExtraPlayers
      ? JSON.parse(localStorage.getItem("scrimData"))?.gameType?.extraPlayers
      : 0;
    for (let i = 0; i < players + extra; i++) {
      initialData.push({
        userId: "",
        inGameName: "",
        gamerJiName: "",
        country: "",
        mobileNo: "",
        isClickOnApplyPlayer: false,
        email: "",
      });
    }

    return initialData;
  });

  const user_data = JSON.parse(localStorage.getItem("gamerjiLogindetails"));
  var scrim_data = JSON.parse(localStorage.getItem("scrimData"));
  const showError = (err) => {
    toast.error(err, {
      position: "top-left",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const AddButton = ({ index }) => {
    return (
      <a class="scrim-add-btn pointer" onClick={() => onClickAdd(index)}>
        Add
      </a>
    );
  };

  const RemoveButton = ({ index }) => {
    return (
      <a class="scrim-remove-btn pointer" onClick={() => onClickRemove(index)}>
        Remove
      </a>
    );
  };

  const AddEmailButton = ({ index }) => {
    return (
      <a class="scrim-add-btn pointer" onClick={() => onClickAddEmail(index)}>
        Add
      </a>
    );
  };

  const RemoveEmailButton = ({ index }) => {
    return (
      <a
        class="scrim-remove-btn pointer"
        onClick={() => onClickRemoveEmail(index)}
      >
        Remove
      </a>
    );
  };

  const onClickAddEmail = async (index) => {
    if (
      players_data[index]?.email === undefined ||
      !players_data[index].email
    ) {
      showError("Please enter the email!");
    } else if (!validator.isEmail(players_data[index]?.email)) {
      showError(constants.error_Please_enter_a_valid_email_address);
    } else if (user_data?.item?.email === players_data[index].email) {
      showError(constants.error_enter_other_than_yours);
    } else if (checkEmailWithTeam(players_data[index].email)) {
      showError(constants.error_enter_already_added_email);
    } else {
      let payload = {
        search: {
          phone: players_data[index].email,
          game: scrim_data.game._id,
        },
      };
      let res = await checkUserDetails(payload);

      if (res && res.success === true) {
        if (res.data.length > 0) {
          if (checkUserIdWithTeam(res?.data[0]?.user)) {
            showError("Already added this user");
          } else {
            var updated_players_data = [...players_data];
            updated_players_data[index] = {
              userId: res?.data[0]?.user,
              inGameName: res?.data[0]?.userINGameName,
              gamerJiName: res?.data[0]?.gamerjiName,
              // country: "ss",
              mobileNo: players_data[index].mobileNo,
              isClickOnApplyPlayer: true,
              email: players_data[index].email,
            };

            setPlayers_data(updated_players_data);
            setDisabledCards([...disabledCards, index]);
          }
        } else {
          showError("Please ask user to add game name!");
        }
      } else {
        showError(res.data.errors[0]?.msg);
      }
    }
  };

  const onClickAdd = async (index) => {
    if (
      players_data[index]?.mobileNo === undefined ||
      !players_data[index].mobileNo
    ) {
      showError("Please enter the phone no!");
    } else if (user_data?.item?.phone === players_data[index].mobileNo) {
      showError(constants.error_enter_other_than_yours);
    } else if (checkMobileWithTeam(players_data[index].mobileNo)) {
      showError(constants.error_enter_already_added);
    } else {
      let payload = {
        search: {
          phone: players_data[index].mobileNo,
          phoneCode: user_data?.item?.phoneCode,
          game: scrim_data.game._id,
        },
      };
      let res = await checkUserDetails(payload);

      if (res && res.success === true) {
        if (res.data.length > 0) {
          if (checkUserIdWithTeam(res?.data[0]?.user)) {
            showError("Already added this user");
          } else {
            var updated_players_data = [...players_data];
            updated_players_data[index] = {
              userId: res?.data[0]?.user,
              inGameName: res?.data[0]?.userINGameName,
              gamerJiName: res?.data[0]?.gamerjiName,
              // country: "ss",
              mobileNo: players_data[index].mobileNo,
              isClickOnApplyPlayer: true,
            };

            setPlayers_data(updated_players_data);
            setDisabledCards([...disabledCards, index]);
          }
        } else {
          showError("Please ask user to add game name!");
        }
      } else {
        showError(res.data.errors[0]?.msg);
      }
    }
  };

  const onClickRemove = (index) => {
    var players_dataTemp = [...players_data];

    players_dataTemp[index] = {
      userId: "",
      inGameName: "",
      gamerJiName: "",
      country: "",
      mobileNo: "",
      isClickOnApplyPlayer: false,
      email: "",
    };
    setPlayers_data(players_dataTemp);
    setDisabledCards(disabledCards.filter((cardIndex) => cardIndex !== index));
  };

  const onClickRemoveEmail = (index) => {
    var players_dataTemp = [...players_data];

    players_dataTemp[index] = {
      userId: "",
      inGameName: "",
      gamerJiName: "",
      country: "",
      mobileNo: "",
      isClickOnApplyPlayer: false,
      email: "",
    };
    setPlayers_data(players_dataTemp);
    setDisabledCards(disabledCards.filter((cardIndex) => cardIndex !== index));
    // setPhone('');
  };

  // onClickRemove(index) {
  //   var players_data = this.state.players_data;
  //   players_data[index] = {
  //     user_id: "",
  //     gamerjiName: "",
  //     levelImage: "",
  //     inGameName: "",
  //     isClickOnApplyPlayer: false,
  //     playerPhone: "",
  //   };

  //   this.setState({ players_data: players_data });
  // }

  const handleTabClick = (event, cardId, index) => {
    setActiveTabs((prevTabs) => ({
      ...prevTabs,
      [cardId]: event.target.value,
    }));

    // if (!disabledCards.includes(index)) {
    //   setDisabledCards([...disabledCards, index]); // Disable the clicked card
    // }
  };

  const onchangePhone = (e, index) => {
    let phone = (players_data[index].mobileNo = e.target.value);
    setPhone(phone);
    setPlayers_data(players_data);
  };

  const onchangeEmail = (e, index) => {
    let phone = (players_data[index].email = e.target.value);
    setPhone(phone);
    setPlayers_data(players_data);
  };

  const checkMobileWithTeam = (number) => {
    let record = players_data.filter((obj) => obj?.mobileNo === number);

    if (record.length > 1) {
      return true;
    } else {
      return false;
    }
  };
  const checkEmailWithTeam = (email) => {
    let record = players_data.filter((obj) => obj?.email === email);

    if (record.length > 1) {
      return true;
    } else {
      return false;
    }
  };
  const checkUserIdWithTeam = (userId) => {
    let record = players_data.filter((obj) => {
      if (userId !== "" && obj?.userId === userId) return true;
      else return false;
    });
    if (record.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  localStorage.setItem("players_data", JSON.stringify(players_data));

  return (
    <div className="row squad_registration_caption_player_row p-0 gap">
      {players_data?.map((player, index) => {
        const cardId = `card-${index}`;
        const activeTab = activeTabs[cardId] || "Mobile";

        return (
          <div className="col-12 m-0 p-0 scrim-squad-registration" key={index}>
            <div className="scrim-squad-registration-title flex gap2 ">
              {index < players ? (
                <div className="width2 scrim-squad-registration-Caption text-align">
                  Player - {index + 2}
                </div>
              ) : (
                <div className="width2 scrim-squad-registration-Caption text-align">
                  Player - {index + 2} (Optional)
                </div>
              )}
              <div className="flex gap">
                <label>
                  <input
                    type="radio"
                    name={`option-${cardId}`}
                    value="Mobile"
                    onChange={(event) => handleTabClick(event, cardId, index)}
                    checked={activeTab === "Mobile"}
                    disabled={disabledCards.includes(index)}
                  />
                  <span className="rd-btn">Via Mobile</span>
                </label>
                <label>
                  <input
                    type="radio"
                    name={`option-${cardId}`}
                    value="Email"
                    // onClick={() => handleTabClick("Email", cardId)}
                    onChange={(event) => handleTabClick(event, cardId, index)}
                    checked={activeTab === "Email"}
                    disabled={disabledCards.includes(index)}
                  />
                  <span className="rd-btn">Via Email</span>
                </label>
              </div>
            </div>
            <div className="scrim-squad-registration-title flex flex-direction gap padding10">
              {activeTab === "Email" && (
                <div className="flex">
                  <div className="width3 flex input-container">
                    <input
                      className="scrim-squad-registration-input input-value-scrims"
                      type="text"
                      value="Email"
                      disabled
                    />
                  </div>
                  <div className="width7 relative">
                    <input
                      className="scrim-squad-registration-input width"
                      placeholder="Enter Email "
                      value={player?.email}
                      onChange={(e) => onchangeEmail(e, index)}
                      type="text"
                      disabled={player?.isClickOnApplyPlayer}
                    />
                    {player?.isClickOnApplyPlayer === true ? (
                      <RemoveEmailButton index={index} />
                    ) : (
                      <AddEmailButton index={index} />
                    )}
                  </div>
                </div>
              )}
              {activeTab === "Mobile" && (
                <div className="flex  ">
                  <div className="width3 flex input-container">
                    <span className="input-container-icon">
                      <img
                        style={{
                          width: "16px",
                          height: "16px",
                          marginLeft: "12px",
                        }}
                        src={
                          url.imageUrl +
                          JSON.parse(localStorage.getItem("userCountryObj"))
                            ?.flag?.default
                        }
                      />
                    </span>
                    <input
                      className="scrim-squad-registration-input  input-value-scrims"
                      type="text"
                      disabled
                      value={user_data?.country?.dialingCode}
                    />
                  </div>
                  <div className=" width7 relative">
                    <input
                      placeholder="Enter mobile number "
                      className="scrim-squad-registration-input width"
                      value={player?.mobileNo}
                      onChange={(e) => onchangePhone(e, index)}
                      type="number"
                      onKeyDown={(e) =>
                        ["e", "E", "+", "-"].includes(e.key) &&
                        e.preventDefault()
                      }
                      maxLength={10}
                      disabled={player?.isClickOnApplyPlayer}
                    />
                    {player?.isClickOnApplyPlayer === true ? (
                      <RemoveButton index={index} />
                    ) : (
                      <AddButton index={index} />
                    )}
                  </div>
                </div>
              )}
              <div className="flex">
                <div className="width3 align-self rd-btn1">InGameName:</div>
                <div className="width7">
                  <input
                    className="scrim-squad-registration-input width"
                    value={
                      player?.inGameName === null ||
                      player?.inGameName === undefined
                        ? "-"
                        : player?.inGameName
                    }
                  />
                </div>
              </div>
              <div className="flex">
                <div className="width3 align-self rd-btn1">Gamerji Name:</div>
                <div className="width7">
                  <input
                    className="scrim-squad-registration-input width"
                    value={
                      player?.gamerJiName === null ||
                      player?.gamerJiName === undefined
                        ? "-"
                        : player?.gamerJiName
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
