import React, { Fragment, useEffect } from "react";
import Logo from "../assets/images/Gamerji_Text_logo_With_Tagline_White.png";
import Subtract from "../assets/images/Subtract.png";
import diamond from "../assets/images/BLUE_Diamond.png";
import coin from "../assets/images/kcash_icon.png";
import Wallet from "../assets/images/wallet.svg";
import Previous from "../assets/images/previous.svg";
import { Link, useNavigate } from "react-router-dom";
import { routehelp } from "../config/routehelp";
import Tooltip from "@mui/material/Tooltip";

function Header(props) {
  const navigate = useNavigate();
  const navigationClick = () => {
    props.navMenuClick();
  };
  const redirectToCoinStore = () => {
    window.location.href = routehelp.coinstore;
  };

  const abbreviate_number = (num, fixed) => {
    if (num === null) {
      return null;
    } // terminate early
    if (num === 0) {
      return "0";
    } // terminate early
    fixed = !fixed || fixed < 0 ? 0 : fixed; // number of decimal places to show
    var b = num.toPrecision(2).split("e"), // get power
      k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
      c =
        k < 1
          ? num.toFixed(0 + fixed)
          : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
      d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
      e = d + ["", "K", "M", "B", "T"][k]; // append power
    return e;
  };

  let loginUserData = localStorage.getItem("loginData");
  loginUserData = JSON.parse(loginUserData);

  const redirectToIndigg = () => {
    window.location.href = "https://indi.gg/";
  };

  const getUrl = () => {
    const queryString = window.location.href.split("?");
    let url = queryString;
    if (queryString.length > 0) {
      url = queryString[0];
    }

    return url.substring(url.lastIndexOf("/") + 1);
  };

  return (
    <Fragment>
      <div className="header d-flex align-items-center justify-content-between">
        {/* <!-- Mobile Menu Start --> */}
        {/* <div className="">
							<div id="burgerBtn" onClick={navigationClick}></div>
							<ul id="nav">
									<li><Link to="">All Games</Link></li>
									 <li><Link to="">All Games</Link></li>
									<li><Link to="">All Games</Link></li>
								<li>	<Link to="">All Games</Link></li>
									 <li><Link to="">All Games</Link></li>
							</ul>
						</div> */}
        {getUrl() != undefined &&
          getUrl() != "undefined" &&
          getUrl() != null &&
          getUrl() != "null" &&
          getUrl() != "" ? (
          <div className="d-flex" style={{ width: "60%" }}>
            <div className="back-btn">
              {/* <Link to={navigate(-1)}
              onClick={() => props?.navMenuClick()}
              > */}
              <a onClick={() => props?.navMenuClick()}>
                <img src={Previous} alt="" />
              </a>
            </div>
            <h2 style={{ textTransform: "uppercase", }}>{props?.title}</h2>
          </div>
        ) : (
          // <div className="logo" style={{ marginLeft: 0 }}>
          //   <Link to="" style={{ fontSize: "24px" }}>
          //     <img src={Subtract} alt="" /> {loginUserData?.userName || ""}
          //   </Link>
          // </div>
          <div className="d-flex" style={{ width: "60%" }}>
            <div className="back-btn">
              {/* <Link to={navigate(-1)}
              onClick={() => props?.navMenuClick()}
              > */}
              <a onClick={() => redirectToIndigg()}>
                <img src={Previous} alt="" />
              </a>
            </div>
            <h2 style={{ textTransform: "uppercase" }}>
              <img src={Subtract} alt="" /> BashArena
            </h2>
          </div>
        )}

        <a
          className="header-responsive header-icons"
          style={{ minWidth: "20%" }}
        >
          <div
            className="header-wallet"
            style={{
              // padding: 10,
              display: "flex",
              borderRadius: "50px",
              border: "1px solid #fff",
              flexGrow: "1",
              textAlign: "center",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          // to={routehelp.coinstore}
          >
            <img
              src={coin}
              alt=""
              style={{ width: "15px", marginRight: "5px" }}
            />
            {/* <Tooltip title={localStorage.getItem("currentCoin")}> */}
            <a
              style={{
                marginLeft: 3,
                color: "#fff",

                alignSelf: "center",
                display: "inline-block",
                maxWidth: "100%",
              }}
            >
              {"  "}
              {/* {abbreviate_number(loginUserData?.walletBalance || 0)}{" "} */}
              {loginUserData?.walletBalance || 0}{" "}
            </a>
            {/* </Tooltip> */}
          </div>
          {/* <Link
            className="header-wallet"
            style={{
              // padding: 10,
              marginLeft: 10,
              display: "flex",
              borderRadius: "50px",
              border: "1px solid #fff",
              flexGrow: "1",
              textAlign: "center",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            to={routehelp.coinstore}
          >
            <img
              src={diamond}
              alt=""
              style={{ width: "28px", marginRight: "5px" }}
            />
            <a
              style={{
                marginLeft: 5,
                color: "#fff",
                alignSelf: "center",
                display: "inline-block",
                maxWidth: "100%",
              }}
            >
              {"  "}
              {localStorage.getItem("currentDiamond")}{" "}
            </a>
          </Link> */}
        </a>

        {/* <div className="wallet-btn"> */}
        {/*	<Link to="">
								<img src={Wallet} alt=""/>
								Wallet
							</Link> */}
        {/* </div> */}
      </div>
    </Fragment>
  );
}

export default Header;
