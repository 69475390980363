import React, { Fragment, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Previous from "../assets/images/previous.svg";
import CopyIcon from "../assets/images/copy-icon.svg";
import {
  contestRate,
  getAllLeaderboardsList,
  getContestRate,
  getevents,
} from "../apis";
import {
  dateFormat,
  formatAMPM,
  goback,
  getProfiledateFormat,
  getProfiletimeFormat,
} from "../component/common";
import Friendship from "../assets/images/friendship.svg";
import R2 from "../assets/images/r-2.svg";
import R3 from "../assets/images/r-3.svg";
import R4 from "../assets/images/r-4.svg";
import R5 from "../assets/images/r-5.svg";
import Close from "../assets/images/close.svg";
import Gamer from "../assets/images/gamer.svg";
import Gun2 from "../assets/images/gun-2.svg";
import Podium from "../assets/images/podium.svg";
import R1 from "../assets/images/r-1.svg";
import Picon1 from "../assets/images/p_icon_1.png";
import RoundPending from "../assets/images/round_pending.png";
import RoundQualified from "../assets/images/round_qualified.png";
import RoundNotQualified from "../assets/images/round_not_qualified.png";
import url from "../constants/url";
import ReactHtmlParser from "react-html-parser";
import ContesX from "../assets/images/contest_x.svg";
import ContesY from "../assets/images/contest_y.svg";
import ContesI from "../assets/images/contest_i.svg";
import TrophyBlue from "../assets/images/ic_trophy_blue.png";
import TrophyYellow from "../assets/images/ic_trophy_yellow.png";
import List from "../assets/images/list.svg";
import { RWebShare } from "react-web-share";
import Loader from "../component/loader";

import Advertisement from "../component/advertisement";
import { constants } from "../constants/constants";

import { FiX } from "react-icons/fi";

import Header from "../component/header";
import { useNavigate } from "react-router";

import { addTracker } from "../component/tracker";

import { trackerEventId } from "../config/tracker";

import JoinViaPath from "./JoinViaPath";

function TournamentDetails(props) {
  const [loader, setLoader] = useState(false);
  const [allData, setAllData] = useState(null);
  const [allRound, setAllRound] = useState([true, false, false]);
  const [rate, setRate] = React.useState(0);
  const [showWinners, setShowWinners] = useState(false);
  const [showWinnersContests, setShowWinnersContests] = useState(false);
  const [captainData, setCaptainData] = useState([]);
  const [captainLevelData, setCaptainLevelData] = useState({});

  const [selectedContestData, setSelectedContestData] = useState({});
  const [allPlayersData, setAllPlayersData] = useState([]);
  const [progressWidth, setProgressWidth] = useState(0);
  const [showRules, setShowRules] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const addTrackData = async (string) => {
    if (localStorage.getItem("loginData")) {
      let userData = JSON.parse(localStorage.getItem("loginData"));
      await addTracker(`userid=${userData?.userId}&${string}`);
    }
  };

  const fetchData = async () => {
    if (localStorage.getItem("currentPage")) {
      let allpage = [...JSON.parse(localStorage.getItem("currentPage"))];
      var count = allpage.findIndex((d) => d === window.location.pathname);
      count === -1 && allpage.push(window.location.pathname);
      localStorage.setItem("currentPage", JSON.stringify(allpage));
    } else {
      let allpage = [];
      allpage.push(window.location.pathname);
      localStorage.setItem("currentPage", JSON.stringify(allpage));
    }

    var gameid =
      window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ];
    let response = await getevents(
      {
        filter: {
          tournamentType: "gamerji",
          user: JSON.parse(localStorage.getItem("loginData"))?.user_id,
        },
      },
      gameid
    );
    if (response) {
      let round = [];
      response?.contests?.map((item, index) => {
        round.push(1);
      });
      round.length > 0 ? (round[round.length - 1] = 0) : (round = round);

      addTrackData(
        `gameCatalogName=${localStorage.getItem(
          "activeGame"
        )}&gameCatalogId=${localStorage.getItem(
          "activeGameId"
        )}&tournamentId=${gameid}&eventId=${
          trackerEventId?.Tournament_Details_Shown
        }`
      );

      setAllRound(round);
      setAllData(response);
      setCaptainData(response?.captainData);
      setCaptainLevelData(response?.captainLevelData);
      setLoader(false);
    }
    let payload = {
      skip: 0,
      limit: 100000,
      event: gameid,
      user: JSON.parse(localStorage.getItem("loginData"))?.user_id,
      sortBy: "createdAt",
      sort: "desc",
    };
    let res = await getAllLeaderboardsList(payload);
    if (res) {
      setCaptainData(res?.captainData);
      setAllPlayersData(res?.list);
    }
  };

  const fetchRating = async () => {
    var gameid =
      window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ];

    const res = await getContestRate({
      contest: gameid,
    });
    if (res?.data) {
      setRate(res?.data?.rate);
    }
  };

  const toggleRound = (number) => {
    let temp = [...allRound];
    temp[number] = temp[number] === 1 ? 0 : 1;
    setAllRound(temp);
  };

  const onChangeRate = async (rate) => {
    setRate(rate);
    var gameid =
      window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ];

    const data = { rate: rate, contest: gameid };
    let res = await contestRate(data);
    if (res) {
    }
  };

  let getRoundStatus = (contest, index) => {
    if (contest.isFinalRound) {
      return undefined;
    }

    if (contest.status != "completed") {
      return "pending";
    }
    if (allData.contests.length > index) {
      return "qualified";
    }
    return "notQualified";
  };

  let getRoundStatusDesc = (contest, index) => {
    if (contest.isFinalRound) {
      return undefined;
    }

    if (getRoundStatus(contest, index) == "pending") {
      return "Pending";
    }
    if (getRoundStatus(contest, index) == "qualified") {
      return "Qualified for Next Round";
    }
    return "Sorry! You are not Qualified";
  };

  let getRoundName = (rounds, contest) => {
    if (rounds.length > 0) {
      for (let i = 0; i < rounds.length; i++) {
        let round = rounds[i];

        for (let j = 0; j < round.matches.length; j++) {
          const match = round.matches[j];

          if (match.matchId == contest.code) {
            return round.name;
          }
        }
      }
    }
  };

  let getProgressValue = (contest) => {
    if (contest.totalJoinedPlayers && contest.totalPlayers) {
      return (100 * contest.totalJoinedPlayers) / contest.totalPlayers + "%";
    } else {
      return 0 + "%";
    }
  };

  const getQulified = (item) => {
    //add event comment
    // addTrackData(
    //   `gameCatalogName=${localStorage.getItem(
    //     "activeGame"
    //   )}&gameCatalogId=${localStorage.getItem("activeGameId")}&tournamentId=${
    //     item?._id
    //   }&eventId=${trackerEventId?.Tournament_Player_List_Shown}`
    // );

    setShowWinnersContests(true);
    setSelectedContestData(item);
  };

  const bannerImg = url.imageUrl + allData?.item?.featuredImage?.default;
  const navigate = useNavigate();
  useEffect(() => {
    var per =
      (100 * allData?.item?.totalJoinedPlayers) / allData?.item?.totalPlayers +
      "%";
    setProgressWidth(per);
  }, [allData]);

  return (
    <div className="inner-wrap">
      <JoinViaPath />
      <ToastContainer />
      <Header title={"Tournament Details"} navMenuClick={() => navigate(-1)} />
      {allData === null ? (
        <Loader />
      ) : (
        <div
          className="body mCustomScrollbar _mCS_1 bottom-btn-body"
          style={{ paddingTop: "10px" }}
          id={`${
            showWinners === true || showWinnersContests === true
              ? "popup_show"
              : ""
          }`}
        >
          <div
            className="all_sub_screens_div_related_to_ads"
            style={{ height: "100%" }}
          >
            <div className="list-group main-tab" id="list-tab" role="tablist">
              <a
                className="list-group-item list-group-item-action active font-weight-400"
                id="list-home-list"
                data-toggle="list"
                href="#list-1"
              >
                Details
              </a>
              <a
                className="list-group-item list-group-item-action font-weight-400"
                id="list-profile-list"
                data-toggle="list"
                href="#list-2"
                onClick={(e) => {
                  //add event comment
                  // addTrackData(
                  //   `gameCatalogName=${localStorage.getItem(
                  //     "activeGame"
                  //   )}&gameCatalogId=${localStorage.getItem(
                  //     "activeGameId"
                  //   )}&tournamentId=${allData?.item?._id}&eventId=${
                  //     trackerEventId?.Tournament_Details_Tournament_Table_Shown
                  //   }`
                  // );
                }}
              >
                Table
              </a>
              <a
                className="list-group-item list-group-item-action font-weight-400"
                id="list-profile-list"
                data-toggle="list"
                href="#list-3"
                onClick={(e) => {
                  //add event comments
                  // addTrackData(
                  //   `gameCatalogName=${localStorage.getItem(
                  //     "activeGame"
                  //   )}&gameCatalogId=${localStorage.getItem(
                  //     "activeGameId"
                  //   )}&tournamentId=${allData?.item?._id}&eventId=${
                  //     trackerEventId?.Tournament_Player_List_Shown
                  //   }`
                  // );
                }}
              >
                Players
              </a>
              <a
                className="list-group-item list-group-item-action font-weight-400"
                id="list-profile-list"
                data-toggle="list"
                href="#list-4"
                onClick={(e) => {
                 
                  // addTrackData(
                  //   `gameCatalogName=${localStorage.getItem(
                  //     "activeGame"
                  //   )}&gameCatalogId=${localStorage.getItem(
                  //     "activeGameId"
                  //   )}&tournamentId=${allData?.item?._id}&eventId=${
                  //     trackerEventId?.Tournament_Rules_Page_Shown
                  //   }`
                  // );
                }}
              >
                Rules
              </a>
            </div>
            <div className="tab-content" id="nav-tabContent">
              <div className="tab-pane active" id="list-1">
                <div
                  className="contest-list contest-tournament-list tournament_details"
                  id="tournament_main"
                >
                  <div className="box" style={{ paddingBottom: "25px" }}>
                    <h3 className="rulesBudget">
                      {allData?.item?.title.length > 42 ? (
                        <marquee behavior="scroll" scrollamount="4">
                          {allData?.item?.title}
                        </marquee>
                      ) : (
                        allData?.item?.title
                      )}
                    </h3>
                    <div className="row tournament_contest_row">
                      <div className="col-4 pr-1">
                        <div className="img">
                          <img
                            src={
                              url.imageUrl +
                              allData?.item?.featuredImage?.default
                            }
                            alt=""
                          />
                          <div className="img-caption">
                            <h5>{allData?.item?.gameTypeAry?.name}</h5>
                          </div>
                        </div>
                      </div>
                      <div className="col-8 pb-3">
                        <div className="inner_row pt-0">
                          <div className="inner_item inner_item_medium">
                            Date{" "}
                            <b>
                              {getProfiledateFormat(allData?.item?.startDate)}
                            </b>
                          </div>
                          <div className="inner_item inner_item_medium">
                            Rounds <b>{allData?.item?.rounds?.length}</b>
                          </div>
                          <div className="inner_item inner_item_medium">
                            ID <b>{allData?.item?.shortCode}</b>
                          </div>
                        </div>
                        <div className="inner_row bg-primary-linear-gradient">
                          {allData?.item?.titles?.map((item, index) => {
                            if (item?.name !== "-") {
                              return (
                                <div
                                  className="inner_item"
                                  key={index}
                                  style={{ width: "40%" }}
                                >
                                  {item?.name} <b>{item?.value}</b>
                                </div>
                              );
                            }
                          })}
                        </div>
                        <div className="inner_row">
                          <div className="inner_item inner_item_medium">
                            Prize Pool{" "}
                            <b>
                              {(allData?.currency?.code == "INR" ||
                                allData?.currency?.code == "INR") &&
                                "₹"}
                              {allData?.currency?.code && (
                                <img
                                  src={
                                    url.imageUrl +
                                    allData?.item?.currency[0]?.outCurrency?.img
                                      ?.default
                                  }
                                  alt=""
                                  className="coin_img"
                                />
                              )}{" "}
                              {allData?.item?.prizePool}
                            </b>
                          </div>
                          <div
                            className="inner_item pointer inner_item_medium"
                            onClick={() => {

                              // add event comment
                              // addTrackData(
                              //   `gameCatalogName=${localStorage.getItem(
                              //     "activeGame"
                              //   )}&gameCatalogId=${localStorage.getItem(
                              //     "activeGameId"
                              //   )}&tournamentId=${allData?.item?._id}&eventId=${
                              //     trackerEventId?.Tournament_Details_Prize_Pool_Popup_Shown
                              //   }`
                              // );
                              setShowWinners(true);
                            }}
                          >
                            Winners{" "}
                            <b>
                              {" "}
                              {allData?.item?.totalWinners}{" "}
                              <i className="fas fa-chevron-down"></i>
                            </b>
                          </div>
                          <div className="inner_item inner_item_medium">
                            Join Using{" "}
                            {allData?.item?.entryFee === 0 ? (
                              <b>Free</b>
                            ) : (
                              <b>
                                {(allData?.item?.currency[0]?.inCurrency
                                  ?.code == "INR" ||
                                  allData?.item?.currency[0]?.inCurrency
                                    ?.code == "inr") &&
                                  "₹"}
                                {allData?.item?.currency[0]?.inCurrency
                                  ?.code && (
                                  <img
                                    src={
                                      url.imageUrl +
                                      allData?.item?.currency[0]?.inCurrency
                                        ?.img?.default
                                    }
                                    alt=""
                                    className="coin_img"
                                  />
                                )}{" "}
                                {allData?.item?.entryFee}
                              </b>
                            )}
                          </div>
                        </div>
                        <div className="avalible_wrap">
                          <div className="inner">
                            <span style={{ width: progressWidth }}></span>
                          </div>
                          <p className="d-flex justify-content-between">
                            <span>
                              {allData?.item?.totalPlayers -
                                allData?.item?.totalJoinedPlayers}{" "}
                              players remaining
                            </span>
                            <span>
                              {allData?.item?.totalJoinedPlayers} player joined
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <h2 className="title mb-3 text-left">Tournament Rounds</h2>
                <div className="contest-list contest-toggle tournament_details">
                  {allData?.contests?.map((item, index) => {
                    return (
                      <div key={index}>
                        <div
                          className={`box ${allRound[index] === 0 && "show"}`}
                        >
                          <div
                            className="contest_toggle_btn row"
                            onClick={() => toggleRound(index)}
                          >
                            <div className="col-5">
                              {getRoundName(allData?.item?.rounds, item)}
                            </div>
                            <div
                              className="col-6 p-0"
                              style={{ fontSize: "14px", textAlign: "right" }}
                            >
                              {getRoundStatus(item, index) == "pending" && (
                                <img
                                  src={RoundPending}
                                  style={{ width: "16px" }}
                                />
                              )}
                              {getRoundStatus(item, index) == "qualified" && (
                                <img
                                  src={RoundQualified}
                                  style={{ width: "16px" }}
                                />
                              )}
                              {getRoundStatus(item, index) ==
                                "notQualified" && (
                                <img
                                  src={RoundNotQualified}
                                  style={{ width: "16px" }}
                                />
                              )}{" "}
                              {getRoundStatusDesc(item, index)}
                            </div>
                            <div className="col-1 p-0">
                              <span></span>
                            </div>
                          </div>
                          <div className="contest_toggle_txt">
                            <div
                              className="inner_row bg-dark"
                              style={{ justifyContent: "start" }}
                            >
                              <div
                                className="inner_item inner_item_medium"
                                style={{ width: "50%" }}
                              >
                                Date <b>{getProfiledateFormat(item?.date)}</b>
                              </div>
                              <div
                                className=" inner_item_medium "
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  flexFlow: "row",
                                }}
                              >
                                <div style={{ width: "40%" }}></div>
                                <div
                                  className=" inner_item inner_item_medium"
                                  style={{
                                    borderLeft: "solid 1px",
                                    display: "flex",
                                    alignItems: "start",
                                    flexFlow: "column",
                                  }}
                                >
                                  {" "}
                                  Time <b>{getProfiletimeFormat(item?.time)}</b>
                                </div>
                              </div>
                              <div
                                className=" inner_item_medium"
                                style={{
                                  display: "flex",
                                  alignItems: "start",
                                  flexFlow: "column",
                                }}
                              >
                                {/* Time <b>{getProfiletimeFormat(item?.time)}</b> */}
                              </div>
                              <div
                                className=" inner_item_medium"
                                style={{
                                  display: "flex",
                                  alignItems: "start",
                                  flexFlow: "column",
                                }}
                              ></div>
                            </div>
                            <div className="inner_row">
                              <div className="inner_item inner_item_medium">
                                Prize Pool{" "}
                                <b>
                                  {(allData?.item?.currency[0]?.outCurrency
                                    ?.code == "INR" ||
                                    allData?.item?.currency[0]?.outCurrency
                                      ?.code == "INR") &&
                                    "₹"}
                                  {allData?.item?.currency[0]?.outCurrency
                                    ?.code && (
                                    <img
                                      src={
                                        url.imageUrl +
                                        allData?.item?.currency[0]?.outCurrency
                                          ?.img?.default
                                      }
                                      alt=""
                                      className="coin_img"
                                    />
                                  )}{" "}
                                  {allData?.item?.prizePool}
                                </b>
                              </div>
                              {item.isFinalRound ? (
                                <div
                                  className="inner_item pointer inner_item_medium"
                                  onClick={() => {
                                    //add event comments
                                    // addTrackData(
                                    //   `gameCatalogName=${localStorage.getItem(
                                    //     "activeGame"
                                    //   )}&gameCatalogId=${localStorage.getItem(
                                    //     "activeGameId"
                                    //   )}&tournamentId=${
                                    //     allData?.item?._id
                                    //   }&eventId=${
                                    //     trackerEventId?.Tournament_Details_Prize_Pool_Popup_Shown
                                    //   }`
                                    // );
                                    setShowWinners(true);
                                  }}
                                >
                                  Winners{" "}
                                  <b>
                                    {" "}
                                    {allData?.item?.totalWinners}{" "}
                                    <i className="fas fa-chevron-down"></i>
                                  </b>
                                </div>
                              ) : (
                                <div
                                  className="inner_item pointer inner_item_medium"
                                  onClick={() => getQulified(item)}
                                >
                                  Qualification{" "}
                                  <b>
                                    {" "}
                                    {item?.winnerCount}{" "}
                                    <i className="fas fa-chevron-down"></i>
                                  </b>
                                </div>
                              )}

                              {/* <div className="inner_item inner_item_medium">
                                Join Using
                                {allData?.item?.entryFee === 0 ? (
                                  <b>Free</b>
                                ) : (
                                  <b>
                                    {(allData?.item?.currency[0]?.inCurrency
                                      ?.code == "INR" ||
                                      allData?.item?.currency[0]?.inCurrency
                                        ?.code == "inr") &&
                                      "₹"}
                                    {allData?.item?.currency[0]?.inCurrency
                                      ?.code && (
                                      <img
                                        src={
                                          url.imageUrl +
                                          allData?.item?.currency[0]?.inCurrency
                                            ?.img?.default
                                        }
                                        alt=""
                                        className="coin_img"
                                      />
                                    )}{" "}
                                    {allData?.item?.entryFee}
                                  </b>
                                )}
                              </div> */}
                              <div className="inner_item inner_item_medium">
                                ID <b>{allData?.item?.shortCode}</b>
                              </div>
                            </div>
                            <div className="avalible_wrap">
                              <div className="inner">
                                <span
                                  style={{ width: getProgressValue(item) }}
                                ></span>
                              </div>
                              <p className="d-flex justify-content-between">
                                <span>
                                  {item?.totalPlayers -
                                    item?.totalJoinedPlayers}{" "}
                                  player remaining
                                </span>
                                <span>
                                  {item?.totalJoinedPlayers} player joined
                                </span>
                              </p>
                            </div>
                            <div
                              className="contest_foot contest_details_foot p-2"
                              style={{
                                margin: "0 -12px -12px",
                                borderRadius: "0 0 15px 15px",
                                marginTop: "15px",
                              }}
                            >
                              <div className="inner inner-text pl-4">
                                <span>Room ID</span>
                                <b style={{ fontSize: "12px" }}>
                                  {item?.roomId === "" ? "-" : item?.roomId}
                                </b>
                              </div>
                              <div className="inner inner-text pl-4">
                                <span>Password</span>
                                <b style={{ fontSize: "12px" }}>
                                  {item?.roomPassword === ""
                                    ? "-"
                                    : item?.roomPassword}
                                </b>
                              </div>
                            </div>
                          </div>
                        </div>
                        {allRound[index] === 0 && (
                          <div className="player-icons-captain">
                            <div className="row no-gutters">
                              <div className="col-6">
                                <div className="item" style={{ width: "95px" }}>
                                  <div className="icon">
                                    <img src={Gamer} alt="" />
                                  </div>
                                  Username
                                </div>
                              </div>
                              <div className="col-2">
                                <div className="item">
                                  <div className="icon">
                                    <img src={Gun2} alt="" />
                                  </div>
                                  Kills
                                </div>
                              </div>
                              <div className="col-2">
                                <div className="item">
                                  <div className="icon">
                                    <img src={Podium} alt="" />
                                  </div>
                                  Rank
                                </div>
                              </div>
                              <div className="col-2">
                                <div className="item">
                                  <div className="icon">
                                    <img src={List} alt="" />
                                  </div>
                                  Points
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {allRound[index] === 0 && (
                          <div
                            className="player_captain contest mb-3"
                            style={{ padding: "10px 15px" }}
                          >
                            <div className="row no-gutters">
                              <div className="col-6">
                                <div className="d-flex">
                                  <div className="d-flex flex-column">
                                    <b>{item?.contestUserItem?.uniqueIGN}</b>

                                    <div className="d-flex">
                                      {allData?.status == "completed" ? (
                                        item?.totalAmount > 0 ? (
                                          <div>
                                            <img
                                              src={TrophyYellow}
                                              alt=""
                                              className="coin_img"
                                            />{" "}
                                            Won{" "}
                                            {allData?.currency?.code == "INR" &&
                                              "₹"}
                                            {allData?.currency?.code ==
                                              "coin" && (
                                              <img
                                                src={
                                                  url.imageUrl +
                                                  allData?.currency?.img
                                                    ?.default
                                                }
                                                alt=""
                                                className="coin_img"
                                              />
                                            )}{" "}
                                            {item?.contestUserItem?.totalAmount}
                                          </div>
                                        ) : (
                                          ""
                                        )
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-2 text-center m-auto">
                                <b>
                                  {item?.contestUserItem?.kills === undefined ||
                                  item?.contestUserItem?.kills === null ||
                                  item?.contestUserItem?.kills === 0
                                    ? "-"
                                    : item?.contestUserItem?.kills}
                                </b>
                              </div>
                              <div className="col-2 text-center m-auto">
                                <b>
                                  {item?.contestUserItem?.rank === undefined ||
                                  item?.contestUserItem?.rank === null ||
                                  item?.contestUserItem?.rank === 0
                                    ? "-"
                                    : item?.contestUserItem?.rank}
                                </b>
                              </div>
                              <div className="col-2 text-center m-auto">
                                <b>
                                  {item?.contestUserItem?.totalPoint ===
                                    undefined ||
                                  item?.contestUserItem?.totalPoint === null ||
                                  item?.contestUserItem?.totalPoint === 0
                                    ? "-"
                                    : item?.contestUserItem?.totalPoint}
                                </b>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>

                {showWinnersContests === true && (
                  <div
                    className="custom-popup rules winner custom_scroll show"
                    id="contact_popup"
                  >
                    <div className="popup-head">
                      <h3>Winning Breakup</h3>
                      <button
                        className="close-popup"
                        onClick={() => setShowWinnersContests(false)}
                      >
                        <FiX />
                      </button>
                    </div>
                    <div className="popup-body bg-black">
                      <div className="all_sub_screens_div_related_to_ads">
                        <div className="tab-content" id="nav-tabContent">
                          <div className="tab-pane active" id="prize-1">
                            <div className="ml-1 prize_pool row">
                              <div className="col-6">
                                <b>
                                  Rank 1 - {selectedContestData.winnerCount}
                                </b>
                              </div>
                              <div className="col-6">
                                <b>Qualified for Next Round</b>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Advertisement
                        screen={
                          localStorage.getItem("apk_screens_list") === null
                            ? { code: "no_results" }
                            : JSON.parse(
                                localStorage.getItem("apk_screens_list")
                              ).prizePoolPopup
                        }
                        screen_div_class_name=".all_sub_screens_div_related_to_ads"
                        screen_type="sub"
                      />
                    </div>
                  </div>
                )}

                {showWinners === true && (
                  <div
                    className="custom-popup rules winner custom_scroll show"
                    id="contact_popup"
                  >
                    <div className="popup-head">
                      <h3>Winning Breakup</h3>
                      <button
                        className="close-popup"
                        onClick={() => setShowWinners(false)}
                      >
                        <FiX />
                      </button>
                    </div>
                    <div className="popup-body bg-black">
                      <div className="all_sub_screens_div_related_to_ads">
                        {allData?.item?.pointpool &&
                          allData?.item?.pointpool?.length !== 0 && (
                            <div
                              className="list-group main-tab w-90"
                              id="list-tab"
                              role="tablist"
                            >
                              <a
                                className="list-group-item list-group-item-action active"
                                id="list-home-list"
                                data-toggle="list"
                                href="#prize-1"
                              >
                                Prize Pool
                              </a>
                              <a
                                className="list-group-item list-group-item-action"
                                id="list-profile-list"
                                data-toggle="list"
                                href="#point-2"
                              >
                                Points
                              </a>
                            </div>
                          )}

                        <div className="tab-content" id="nav-tabContent">
                          <div className="tab-pane active" id="prize-1">
                            <div className="prize_pool mb-3 text-center p-2 w-90">
                              <h5 className="text-center">Prize Pool</h5>
                              <h6
                                className="text-center"
                                style={{ fontWeight: "800" }}
                              >
                                {(allData?.item?.currency?.[0]?.outCurrency
                                  ?.code == "INR" ||
                                  allData?.item?.currency?.[0]?.outCurrency
                                    ?.code == "INR") &&
                                  "₹"}
                                {allData?.item?.currency?.[0]?.outCurrency
                                  ?.code && (
                                  <img
                                    src={
                                      url.imageUrl +
                                      allData?.item?.currency?.[0]?.outCurrency
                                        ?.img?.default
                                    }
                                    alt=""
                                    className="coin_img_big mr-1"
                                  />
                                )}{" "}
                                {allData?.item?.prizePool}
                              </h6>

                              <div className="fee prizePoolFee">
                                {allData?.item?.prizePoolInfo?.map(
                                  (item, index) => {
                                    return (
                                      <p key={index}>
                                        <b>
                                          Rank:{" "}
                                          {item?.rankTo !== "0" &&
                                          item?.rankTo != item?.rankFrom
                                            ? item?.rankFrom +
                                              "-" +
                                              item?.rankTo
                                            : item?.rankFrom}
                                        </b>
                                        <b>
                                          {(allData?.item?.currency[0]
                                            ?.outCurrency?.code == "INR" ||
                                            allData?.item?.currency[0]
                                              ?.outCurrency?.code == "INR") &&
                                            "₹"}
                                          {allData?.item?.currency[0]
                                            ?.outCurrency?.code && (
                                            <img
                                              src={
                                                url.imageUrl +
                                                allData?.item?.currency[0]
                                                  ?.outCurrency?.img?.default
                                              }
                                              alt=""
                                              className="coin_img"
                                            />
                                          )}{" "}
                                          {item?.amount}
                                        </b>
                                      </p>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          </div>
                          {allData?.item?.pointpool && (
                            <div className="tab-pane" id="point-2">
                              <div className="table-responsive total_table total_table_border mb-3 w-90">
                                <table>
                                  <tbody>
                                    {allData?.item?.pointpool?.map(
                                      (item1, index1) => {
                                        return (
                                          <tr key={index1}>
                                            <td>
                                              <b>
                                                Rank:{" "}
                                                {item1?.rankTo !== "0"
                                                  ? item1?.rankFrom +
                                                    "-" +
                                                    item1?.rankTo
                                                  : item1?.rankFrom}
                                              </b>
                                            </td>
                                            <th className="text-right">
                                              {item1?.point}
                                            </th>
                                          </tr>
                                        );
                                      }
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <Advertisement
                        screen={
                          localStorage.getItem("apk_screens_list") === null
                            ? { code: "no_results" }
                            : JSON.parse(
                                localStorage.getItem("apk_screens_list")
                              ).prizePoolPopup
                        }
                        screen_div_class_name=".all_sub_screens_div_related_to_ads"
                        screen_type="sub"
                      />
                    </div>
                  </div>
                )}
                <RWebShare
                  data={{
                    text: "Hey! Join me on Indigg for this exciting match. Click on hte following link and let's play together",
                    url: `${url.shareUrl}${allData?.item?.shortCode}&type=tournament`,
                    title: "Join me on Indigg",
                  }}
                  onClick={() => console.info("share successful!")}
                >
                  <div className="row friendship_items">
                    <div className="col-12">
                      <div className="box winning_breakup_popup_toggle">
                        <div className="icon">
                          <img src={Friendship} alt="" />
                        </div>
                        Share with Friends
                      </div>
                    </div>
                  </div>
                </RWebShare>
              </div>

              <div className="tab-pane" id="list-2">
                <img
                  src={url.imageUrl + allData?.item?.eventTableImage?.default}
                />
              </div>

              <div className="tab-pane" id="list-3">
                <div className="player_icons">
                  <div className="row no-gutters">
                    <div className="col-6">
                      <div className="item" style={{ width: "95px" }}>
                        <div className="icon">
                          <img src={Gamer} alt="" />
                        </div>
                        Username
                      </div>
                    </div>
                    {/* <div className="col-2">&nbsp; </div> */}
                    <div className="col-2">
                      <div className="item">
                        <div className="icon">
                          <img src={Gun2} alt="" />
                        </div>
                        Kills
                      </div>
                    </div>
                    <div className="col-2">
                      <div className="item">
                        <div className="icon">
                          <img src={Podium} alt="" />
                        </div>
                        Rank
                      </div>
                    </div>
                    <div className="col-2">
                      <div className="item">
                        <div className="icon">
                          <img src={List} alt="" />
                        </div>
                        Points
                      </div>
                    </div>
                  </div>
                </div>

                <div className="player_list contest">
                  <div className="item active">
                    <div
                      className="inner inner_big"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <b>{captainData?.uniqueIGN}</b>
                      <br />
                      <small>
                        {captainData?.user && captainData?.user?.phone
                          ? "xxxxx" +
                            captainData?.user?.phone.slice(
                              captainData?.user?.phone?.length - 5
                            )
                          : ""}
                        {allData?.status == "completed" ? (
                          captainData?.totalAmount > 0 ? (
                            <div>
                              <img
                                src={TrophyYellow}
                                alt=""
                                className="coin_img"
                              />{" "}
                              Won {allData?.currency?.code == "INR" && "₹"}
                              {allData?.currency?.code == "coin" && (
                                <img
                                  src={
                                    url.imageUrl +
                                    allData?.currency?.img?.default
                                  }
                                  alt=""
                                  className="coin_img"
                                />
                              )}{" "}
                              {captainData?.totalAmount}
                            </div>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                      </small>
                    </div>
                    <div className="inner">
                      <b>
                        {captainData?.kills === null || captainData?.kills === 0
                          ? "-"
                          : captainData?.kills}
                      </b>
                    </div>
                    <div className="inner">
                      <b>
                        {captainData?.rank === null || captainData?.rank === 0
                          ? "-"
                          : captainData?.rank}
                      </b>
                    </div>
                    <div className="inner">
                      <b>
                        {captainData?.points === null ||
                        captainData?.points === 0
                          ? "-"
                          : captainData?.points}
                      </b>
                    </div>
                  </div>
                  {/* );
                    // } */}
                  {/* })} */}
                  {allPlayersData?.map((item, index) => (
                    <div className="item" key={index}>
                      <div className="inner inner_big">
                        <b>{item?.uniqueIGN}</b>
                        <br />
                        <small>
                          {allData?.status == "completed" ? (
                            item?.totalAmount > 0 ? (
                              <div>
                                <img
                                  src={TrophyBlue}
                                  alt=""
                                  className="coin_img"
                                />{" "}
                                Won {allData?.currency?.code == "INR" && "₹"}
                                {allData?.currency?.code == "coin" && (
                                  <img
                                    src={
                                      url.imageUrl +
                                      allData?.currency?.img?.default
                                    }
                                    alt=""
                                    className="coin_img"
                                  />
                                )}{" "}
                                {item?.totalAmount}
                              </div>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                        </small>
                      </div>
                      <div className="inner">
                        <b>
                          {item?.kills === null || item?.kills === 0
                            ? "-"
                            : item?.kills}
                        </b>
                      </div>
                      <div className="inner">
                        <b>
                          {item?.rank === null || item?.rank === 0
                            ? "-"
                            : item?.rank}
                        </b>
                      </div>
                      <div className="inner">
                        <b>
                          {item?.points === null || item?.points === 0
                            ? "-"
                            : item?.points}
                        </b>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="tab-pane" id="list-4">
                <div className="tab_content">
                  <p>{ReactHtmlParser(allData?.item?.rules)}</p>
                </div>
              </div>
            </div>
          </div>
          <Advertisement
            screen={
              localStorage.getItem("apk_screens_list") === null
                ? { code: "no_results" }
                : JSON.parse(localStorage.getItem("apk_screens_list"))
                    .tournamentDetails
            }
            screen_div_class_name=".all_sub_screens_div_related_to_ads"
            screen_type="sub"
          />
        </div>
      )}

      {showRules === true && (
        <div className="tc_popup_bg">
          <div
            className="custom-popup rules custom_scroll show"
            id="contact_popup"
          >
            <div className="popup-head">
              <h3>Rules</h3>
              <button
                className="close-popup"
                onClick={() => setShowRules(false)}
              >
                <FiX />
              </button>
            </div>
            <div className="popup-body bg-black mt-3">
              <p>{ReactHtmlParser(allData?.item?.rules)}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default TournamentDetails;
