import React, { Fragment, useEffect, useState } from "react";
import AllGames from "../assets/images/all-games.svg";
import GameIconSmall1 from "../assets/images/game-icon-small3.png";
import { Link, useNavigate } from "react-router-dom";
import {
    getDailyRewards,
    getListofDailyRewards,
    getUserProfile,
    homebanner,
    getCollectDailyRewards,
    getUpdateProfile,
    apkScreensList,
    bannerAdLog,
    getUserInGameName,
    getTournamentList,
    indiggLogin,
    indiggRefreshToken,
    getApplyContestCode,
    getApplyEventCode,
    getApplyContestCodeType
  } from "../apis";
import { convertNumberToMillions, dateFormat } from "../component/common";
import url from "../constants/url";
import Loader from "../component/loader";
import { addTracker } from "../component/tracker";
import { ToastContainer, toast } from "react-toastify";
import { FiX } from "react-icons/fi";

import ContestStructure from "../component/ContestStructure";
import TournamentsStructure from "../component/tournaments-structure";

import { trackerEventId } from "../config/tracker";

function JoinViaPath(props) {
    const [loader, setLoader] = useState(false);
    const [joinData, setJoinData] = useState({});
    const [tournamentJoin, setTournamnetJoin] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);

        const urlShortCode = urlSearchParams.get("path");
        const urlType = urlSearchParams.get("type");
        const isNotify = urlSearchParams.get("isNotify");
  
        if (
          (urlShortCode != undefined &&
          urlShortCode != "undefined" &&
          urlShortCode != null &&
          urlShortCode != "null" &&
          urlShortCode != "") && (isNotify?.toString() !== "true")
        ) {
          if (JSON.parse(localStorage.getItem("loginData"))?.user_id) {
            joinViaInvitedCode(urlShortCode, urlType);
          } else {
          }
        }
        else if((urlShortCode != undefined &&
          urlShortCode != "undefined" &&
          urlShortCode != null &&
          urlShortCode != "null" &&
          urlShortCode != "") && (isNotify?.toString() === "true")) {
          // if (JSON.parse(localStorage.getItem("loginData"))?.user_id) {
            
            removeParamsFromUrl(['path','type','isNotify']);
            joinViaInvitedCodeType(urlShortCode, urlType);
          // } else {
          // }
        }
    });

    function removeParamsFromUrl(paramsToRemove) {
        // Get the current URL
        let url = new URL(window.location.href);
      
        // Get URLSearchParams object
        let params = url.searchParams;
      
        // Remove unwanted parameters
        paramsToRemove.forEach(param => params.delete(param));
      
        // Get the updated query string
        let updatedQueryString = params.toString();
      
        // Create a new URL with the updated query string
        let newUrl = url.origin + url.pathname + (updatedQueryString ? '?' + updatedQueryString : '');
      
        // Replace the current URL without triggering a page refresh
        window.history.replaceState(null, '', newUrl);
    }

    const joinViaInvitedCodeType = async (urlShortCode, urlType) => {
      setLoader(true);
      var user_data = JSON.parse(localStorage.getItem("loginData"));
  
      let payload = {
        search: {
          user: user_data?.user_id,
          type: urlType
        },
      };
  
      let res = await getApplyContestCodeType(urlShortCode.toUpperCase(), payload);
      if (res?.data && res?.success) {
        if(urlType != "contest") {
          navigate(`/tounament-details/${res?.data?._id}`);
        }
        else {
          navigate(`/contest-details/${res?.data?._id}`);
        }
      }
      else {
        var errormsg = res?.data?.errors?.[0]?.code == "scrime.validation.not_found" ? "Contest not found." : res.data.errors[0]?.msg
        toast.error(errormsg, {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoader(false);
      }
    };

    const joinViaInvitedCode = async (urlShortCode, urlType) => {
        if (
          urlType != undefined &&
          urlType != "undefined" &&
          urlType != null &&
          urlType != "null" &&
          urlType != "" &&
          urlType != "contest"
        ) {
          joinViaInvitedCodeTournament(urlShortCode);
        } else {
          joinViaInvitedCodeContest(urlShortCode);
        }
    };
    
    const joinViaInvitedCodeContest = async (urlShortCode) => {
        setLoader(true);
        var user_data = JSON.parse(localStorage.getItem("loginData"));
    
        let payload = {
          search: {
            user: user_data?.user_id,
          },
        };
    
        let res = await getApplyContestCode(urlShortCode.toUpperCase(), payload);
        if (res?.data && res?.success) {
          removeParamsFromUrl(['path','type']);
          setLoader(false);
    
          let data = {};
    
          if (res?.data?.isSingle) {
            localStorage.setItem("activeGameId", res?.data?.game);
            localStorage.setItem("activeGame", res?.data?.gameAry?.[0]?.name);
            data = res?.data;
          } else {
            localStorage.setItem("activeGameId", res?.item?.saasGameId);
            localStorage.setItem("activeGame", res?.item?.game?.name);
            data = {
              id: res?.item?._id,
              img: url.imageUrl + res?.item?.featuredImage?.default, // not present
              coin: url.imageUrl + res?.item?.currency?.img?.default,
              title: res?.item?.title,
              contestsList: res?.item?.contestsList, // not present
              totalPlayers: res?.item?.totalPlayers,
              isJoined: res?.item?.isJoined,
              totalJoinedPlayers: res?.item?.totalJoinedPlayers,
              shortCode: res?.item?.shortCode,
              gameName: res?.item?.game?.name, // not present
              levelNote: res?.item?.game?.settings?.levelNote, // not present
              currency: res?.item?.currency,
              gameId: res?.item?.game?._id, // not present
              winners: res?.item?.totalWinners,
              gameType: res?.item?.gameType?.name,
              gameTypePlayers: res?.item?.gameType?.players,
    
              hostedby: res?.item?.host?.name,
              hostrate: res?.item?.host?.rate,
              entryFee: res?.item?.entryFee,
              titles: res?.item?.titles,
              rules: res?.item?.rules, // not present
              allrounds: res?.item?.rounds, // not present
              round: res?.item?.rounds?.length, // not present
              winningCoin: res?.item?.totalWinningsDistributedCoin, // not present
              startDate: res?.item?.startDate, // not present
              endDate: res?.item?.endDate, // not present
              roundDatetime: res?.item?.rounds[0]?.matches, // not present
              prizepool: res?.item?.prizepool,
              prizePool: res?.item?.prizePool,
              pointpool: res?.item?.pointpool,
              createdAt: dateFormat(res?.item?.createdAt), // not present
              updatedAt: dateFormat(res?.item?.updatedAt), // not present
              saasGameId: res?.item?.saasGameId,
            };
          }
    
          if (res?.type === "scrim") {
            toast.error("Contest not found.", {
              position: "top-left",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            setJoinData(data);
            setTournamnetJoin(true);
          }
        } else {
          var errormsg = res?.data?.errors?.[0]?.code == "scrime.validation.not_found" ? "Contest not found." : res.data.errors[0]?.msg
          toast.error(errormsg, {
            position: "top-left",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          removeParamsFromUrl(['path','type']);
          setLoader(false);
        }
    };
    
    const joinViaInvitedCodeTournament = async (urlShortCode) => {
        setLoader(true);
        let data = {
          code: urlShortCode.toUpperCase(),
          user: JSON.parse(localStorage.getItem("loginData"))?.user_id,
        };
    
        let res = await getApplyEventCode(data);
        if (res?.item) {
          removeParamsFromUrl(['path','type']);
          setLoader(false);
          data = {};
          if (res?.item?.isSingle) {
            data = res?.item;
          } else {
            localStorage.setItem("activeGameId", res?.item?.gameAry[0]?._id);
            localStorage.setItem("activeGame", res?.item?.gameAry[0]?.name);
            data = {
              id: res?.item?._id,
              img: url.imageUrl + res?.item?.featuredImage?.default, // not present
              coin: url.imageUrl + res?.item?.currency?.img?.default,
              title: res?.item?.title,
              contestsList: res?.item?.contestsList, // not present
              totalPlayers: res?.item?.totalPlayers,
              isJoined: res?.item?.isJoined,
              totalJoinedPlayers: res?.item?.totalJoinedPlayers,
              shortCode: res?.item?.shortCode,
              gameName: res?.item?.gameAry[0]?.name, // not present
              levelNote: res?.item?.game?.settings?.levelNote, // not present
              currency: res?.item?.currency[0],
              gameId: res?.item?.gameAry[0]?._id, // not present
              winners: res?.item?.totalWinners,
              gameType: res?.item?.gameTypeAry?.name,
              gameTypePlayers: res?.item?.gameTypeAry?.players,
              gameTypeExtraPlayers: res?.item?.gameTypeAry?.extraPlayers, // not present
              hostedby: res?.item?.host?.name,
              hostrate: res?.item?.host?.rate,
              entryFee: res?.item?.entryFee,
              titles: res?.item?.titles,
              rules: res?.item?.rules, // not present
              allrounds: res?.item?.rounds, // not present
              round: res?.item?.rounds?.length, // not present
              winningCoin: res?.item?.totalWinningsDistributedCoin, // not present
              startDate: res?.item?.startDate, // not present
              endDate: res?.item?.endDate, // not present
              roundDatetime: res?.item?.rounds[0]?.matches, // not present
              prizepool: res?.item?.prizePoolInfo,
              prizePool: res?.item?.prizePool,
              pointpool: res?.item?.pointpool,
              createdAt: dateFormat(res?.item?.createdAt), // not present
              updatedAt: dateFormat(res?.item?.updatedAt), // not present
              saasGameId: res?.item?.gameAry[0]?._id,
            };
          }
          setJoinData(data);
          setTournamnetJoin(true);
        } else {
          var errormsg = res?.data?.errors?.[0]?.code == "scrime.validation.not_found" ? "Contest not found." : res.data.errors[0]?.msg
          toast.error(errormsg, {
            position: "top-left",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          removeParamsFromUrl(['path','type']);
          setLoader(false);
        }
    };

    const createAfterApplyCodePopup = () => {
        return (
          <div className="tc_popup_bg">
            <div className="custom-popup home-featured show" id="contact_popup">
              <div className="popup-head">
                <h3>
                  {joinData?.isSingle === true
                    ? joinData.title
                    : joinData?.gameName}
                </h3>
                <button
                  className="close-popup"
                  onClick={() => setTournamnetJoin(false)}
                >
                  {/* <img src={Close} alt="" /> */}
                  <FiX />
                </button>
              </div>
              <div className="popup-body mt-3">
                {joinData?.isSingle === true
                  ? createContestSingleBox(joinData, 1)
                  : createTournamentSingleBox(joinData, 1)}
              </div>
            </div>
          </div>
        );
    };
    
    const createContestSingleBox = (data, index) => {
        return <ContestStructure allData={data} key={index} />;
    };
    
    const createTournamentSingleBox = (data, index) => {
        return <TournamentsStructure allFeaturedData={data} key={index} />;
    };

    return (
        <Fragment>
            {tournamentJoin === true && createAfterApplyCodePopup()}
        </Fragment>
    );
}
export default JoinViaPath;