import instance from "./config/axios";
import instance_indigg from "./config/axios-indigg";
import instance_gj from "./config/axios-gamerji";
import routes from "./constants/routes";
import url, { base_url, eassUrl } from "./constants/url";

export const indiggTracker = (data) => {
  return instance.get(`${url.trackerUrl}${data}`).then((res) => {
    return res;
  });
};

export const indiggLogin = (data) => {
  return instance
    .get(`${url.indiggUrl}${routes.indiggSignin}?token=${data}`)
    .then((res) => {
      return res;
    });
};

export const indiggRefreshToken = (data) => {
  return instance_indigg
    .get(`${url.indiggUrl}${routes.indiggRefreshToken}?token=${data}`)
    .then((res) => {
      return res;
    });
};

export const indiggUserExists = async (data, username, game) => {
  return instance_indigg
    .get(
      `${url.indiggUrl}${routes.indiggUserExits}?token=${data}&username=${username}&game=${game}`
    )
    .then((res) => {
      return res;
    });
};

export const signIn = (data) => {
  return instance.post(`${url.authUrl}${routes.signin}`, data).then((res) => {
    return res;
  });
};

export const ValidatemailOTP = (data) => {
  return instance
    .post(`${url.authUrl}${routes.sendemailotp}`, data)
    .then((res) => {
      return res;
    });
};

export const emailOtp = (data) => {
  return instance.post(`${url.authUrl}${routes.emailotp}`, data).then((res) => {
    return res;
  });
};

export const signInInternational = (data) => {
  return instance
    .post(`${url.authUrl}${routes.signinInternational}`, data)
    .then((res) => {
      return res;
    });
};
export const signUpEmail = (data) => {
  return instance
    .post(`${url.authUrl}${routes.signupEmail}`, data)
    .then((res) => {
      return res;
    });
};

export const signInEmail = (data) => {
  return instance
    .post(`${url.authUrl}${routes.signinEmail}`, data)
    .then((res) => {
      return res;
    });
};

// World of eSports Starts
export const woeLiveStreamingsList = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.woeLiveStreamingsList}`, data)
    .then((res) => {
      return res;
    });
};

export const woeUsersList = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.woeUsersList}`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      //return res;
    });
};

export const woeBlogsList = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.woeBlogsList}`, data)
    .then((res) => {
      return res;
    });
};
// World of eSports Ends

// All Screens ID Starts
export const apkScreensList = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.apkScreensList}`, data)
    .then((res) => {
      return res;
    });
};
// All Screens ID Ends

// Sponsors Ads Banner Ads Starts
export const sponsorAdsList = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.sponsorAdsList}`, data)
    .then((res) => {
      return res;
    });
};

export const sponsorAdLog = (data) => {
  return instance
    .post(`${url.accountUrl}${routes.sponsorAdLog}`, data)
    .then((res) => {
      return res;
    });
};

export const bannerAdLog = (data) => {
  return instance
    .post(`${url.accountUrl}${routes.bannerAdLog}`, data)
    .then((res) => {
      return res;
    });
};
// Sponsors Ads Banner Ads Ends

// Apply Promo Code Starts
export const applyPromoCode = (data) => {
  return instance
    .post(`${url.accountUrl}${routes.applyPromoCode}`, data)
    .then((res) => {
      return res;
    });
};
// Apply Promo Code Ends

// How To Play Starts
export const howToPlay = (data) => {
  return instance
    .post(`${url.accountUrl}${routes.howToPlay}`, data)
    .then((res) => {
      return res;
    });
};
// How To Play Ends

// DOBNState Starts
export const updateDOBNState = (data) => {
  return instance
    .post(`${url.accountUrl}${routes.updateDOBNState}`, data)
    .then((res) => {
      return res;
    });
};

export const sendOTP = (data) => {
  return instance
    .post(`${url.accountUrl}${routes.sendOTP}`, data)
    .then((res) => {
      return res;
    });
};

export const validateOTP = (data) => {
  return instance
    .post(`${url.accountUrl}${routes.validateOTP}`, data)
    .then((res) => {
      return res;
    });
};
// DOBNState Ends

// Contests Starts
export const checkDateTime = (id) => {
  return instance
    .post(`${url.saasApiUrl}${routes.checkDateTime}/${id}`)
    .then((res) => {
      return res;
    });
};
// Contests Ends

// Customer Care Starts
export const userContestList = (data) => {
  return instance
    .post(`${url.saasApiUrl}${routes.userContestList}`, data)
    .then((res) => {
      return res;
    });
};
// Customer Care Ends

// Free Games Starts
export const html5SettingsList = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.html5SettingsList}`, data)
    .then((res) => {
      return res;
    });
};

export const html5CategoriesList = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.html5CategoriesList}`, data)
    .then((res) => {
      return res;
    });
};

export const html5GamesList = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.html5GamesList}`, data)
    .then((res) => {
      return res;
    });
};

export const html5GamesGet = (id) => {
  return instance
    .post(`${url.apiUrl}${routes.html5GamesGet}/${id}`)
    .then((res) => {
      return res;
    });
};

export const html5GamesUpdateView = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.html5GamesUpdateView}`, data)
    .then((res) => {
      return res;
    });
};
// Free Games Ends

// My Recent Transactions Starts
export const transactionsList = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.transactionsList}`, data)
    .then((res) => {
      return res;
    });
};

export const transactionsSendInvoiceEmail = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.transactionsSendInvoiceEmail}/${data}`)
    .then((res) => {
      return res;
    });
};

export const transactionsExport = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.transactionsExport}`, data)
    .then((res) => {
      return res;
    });
};
// My Recent Transactions Ends

// Payment Gateway Starts
export const paymentOptions = (data) => {
  return instance
    .post(`${url.accountUrl}${routes.paymentOptions}`, data)
    .then((res) => {
      return res;
    });
};

export const cfBankList = (data) => {
  return instance
    .post(`${url.accountUrl}${routes.cfBankList}`, data)
    .then((res) => {
      return res;
    });
};
// Payment Gateway Ends

// Verify Mobile & Email Starts
export const checkEmail = (data) => {
  return instance
    .post(`${url.accountUrl}${routes.checkEmail}`, data)
    .then((res) => {
      return res;
    });
};
// Verify Mobile & Email Ends

// My Videos Starts
export const userVideoRequestsList = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.userVideoRequestsList}`, data)
    .then((res) => {
      return res;
    });
};

export const userVideoRequestsVideoList = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.userVideoRequestsVideoList}`, data)
    .then((res) => {
      return res;
    });
};

export const userVideoRequestsCheckChannel = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.userVideoRequestsCheckChannel}`, data)
    .then((res) => {
      return res;
    });
};

export const userVideoRequestsCreate = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.userVideoRequestsCreate}`, data)
    .then((res) => {
      return res;
    });
};
// My Videos Ends

export const signUpInternational = (data) => {
  return instance
    .post(`${url.authUrl}${routes.signupInternational}`, data)
    .then((res) => {
      return res;
    });
};

export const checkAppUser = (data) => {
  return instance
    .post(`${url.publicUrl}${routes.checkAppUser}`, data)
    .then((res) => {
      return res;
    });
};

export const getAllStateList = (data) => {
  return instance
    .post(`${url.publicUrl}${routes.stateList}`, data)
    .then((res) => {
      return res;
    });
};

export const getAllFreeAvatar = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.avatarFree}`, data)
    .then((res) => {
      return res;
    });
};

export const getsignUpCode = (data) => {
  return instance
    .post(`${url.publicUrl}${routes.signupCode}`, data)
    .then((res) => {
      return res;
    });
};

export const countryList = (data) => {
  return instance
    .post(`${url.publicUrl}${routes.countries}`, data)
    .then((res) => {
      return res;
    });
};

export const geolocation = () => {
  return fetch("https://geolocation-db.com/json/")
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};

export const PrivacyData = (data) => {
  return instance.post(`${url.publicUrl}${routes.privacy}`).then((res) => {
    return res;
  });
};

export const TermsData = (data) => {
  return instance.post(`${url.publicUrl}${routes.terms}`).then((res) => {
    return res;
  });
};

export const allGames = (data) => {
  return instance.post(`${url.gamesUrl}${routes.list}`, data).then((res) => {
    return res;
  });
};

export const allIndiggGames = (data) => {
  return instance
    .post(`${url.indiggGamesUrl}${routes.gameList}`, data)
    .then((res) => {
      return res;
    });
};

export const gameTypes = (data) => {
  return instance
    .post(`${url.gamesTypesUrl}${routes.list}`, data)
    .then((res) => {
      return res;
    });
};

export const getTournamentList = (data) => {
  return instance
    .post(`${url.saasApiUrl}${routes.tournamentList}`, data)
    .then((res) => {
      return res;
    });
};

export const homebanner = (data) => {
  return instance.post(`${url.bannerUrl}${routes.list}`, data).then((res) => {
    return res;
  });
};

export const getevents = (body, id) => {
  //eventListUrl
  return instance
    .post(`${url.saasApiUrl}gamerjiTournament/${routes.get}${id}`, body)
    .then((res) => {
      return res;
    });
};

export const getWalletLimit = (data) => {
  return instance
    .post(`${url.accountUrl}${routes.walletusage}`, data)
    .then((res) => {
      return res;
    });
};

export const getUserProfile = () => {
  return null;
};
export const getOtherUserProfile = (data) => {
  return instance
    .post(`${url.accountUrl}${routes.profile}`, data)
    .then((res) => {
      return res;
    });
};
export const removeFolowUser = (data) => {
  return instance
    .post(`${url.accountUrl}${routes.followUser}`, data)
    .then((res) => {
      return res;
    });
};
export const onCreateContest = (data) => {
  return instance
    .post(`${url.saasApiUrl}${routes.indiggCreateContest}`, data)
    .then((res) => {
      return res;
    });
};
export const onCreateTournament = (data) => {
  //${url.apiUrl}${routes.createTournament}
  return instance
    .post(`${url.saasApiUrl}${routes.indiggTournamentJoin}`, data)
    .then((res) => {
      return res;
    });
};

export const onCreateScrim = (data) => {
  // return instance
  //   .post(`${SAAS_API_URL}${url.v2Url}${routes.joinScrim}`, data)
  //   .then((res) => {
  //     return res;
  //   });
};

export const getContestList = (data) => {
  return instance
    .post(`${url.saasApiUrl}${routes.contestlist}`, data)
    .then((res) => {
      return res;
    });
};
export const getContestDetails = (id) => {
  return instance.post(`${url.contestUrl}${routes.get}${id}`).then((res) => {
    return res;
  });
};
export const getOldContestDetails = (id) => {
  return instance.post(`${url.contestOldUrl}${routes.get}${id}`).then((res) => {
    return res;
  });
};
export const getContestEventByCode = (data) => {
  return instance
    .post(`${url.accountUrl}${routes.contesteventbycode}`, data)
    .then((res) => {
      return res;
    });
};

export const getContestMyList = (data) => {
  return instance
    .post(`${url.contestUrl}${routes.mylist}`, data)
    .then((res) => {
      return res;
    });
};

export const getUpdateProfile = (data) => {
  return instance
    .post(`${url.accountUrl}${routes.updateprofile}`, data)
    .then((res) => {
      return res;
    });
};

export const getDailyRewards = (data) => {
  return null;
};

export const getListofDailyRewards = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.listdailyreward}`, data)
    .then((res) => {
      return res;
    });
};

export const getCollectDailyRewards = (data) => {
  return instance
    .post(`${url.accountUrl}${routes.collectdailyreward}`, data)
    .then((res) => {
      return res;
    });
};

export const getCoinStoreList = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.coinStoreList}`, data)
    .then((res) => {
      return res;
    });
};
export const getCoinStoreDetails = (id) => {
  return instance
    .post(`${url.apiUrl}${routes.coinStoreGet}${id}`)
    .then((res) => {
      return res;
    });
};
export const requestCreateCoinStoreTransactions = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.createRazTransaction}`, data)
    .then((res) => {
      return res;
    });
};
export const requestCreateCFCoinStoreTransactions = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.createCashfreeTransaction}`, data)
    .then((res) => {
      return res;
    });
};
export const requestLevelList = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.levelsList}`, data)
    .then((res) => {
      return res;
    });
};
export const requestGameStats = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.gameStatistics}`, data)
    .then((res) => {
      return res;
    });
};

export const getAllAvatarList = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.avatarList}`, data)
    .then((res) => {
      return res;
    });
};

export const getAllRewardCategoryList = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.rewardCatList}`, data)
    .then((res) => {
      return res;
    });
};

export const getAllRewardProductMyList = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.rewardProdMyList}`, data)
    .then((res) => {
      return res;
    });
};

export const getAllLeaderboardsList = (data) => {
  return instance
    .post(`${url.saasApiUrl}${routes.leaderboardsList}`, data)
    .then((res) => {
      return res;
    });
};

export const howToJoinGame = (data) => {
  return instance.post(`${url.apiUrl}${routes.howToJoin}`, data).then((res) => {
    return res;
  });
};

export const getMyContestList = (data) => {
  return instance
    .post(`${url.saasApiUrl}${routes.myContestList}`, data)
    .then((res) => {
      return res;
    });
};
export const getMyOldContestList = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.myOldContestList}`, data)
    .then((res) => {
      return res;
    });
};

export const getMyEventsList = (data) => {
  //url.apiUrl
  return instance
    .post(`${url.saasApiUrl}${routes.myEventsList}`, data)
    .then((res) => {
      return res;
    });
};

export const getLeaderBoardList = (type, data) => {
  return instance
    .post(`${url.accountUrl}${routes.leaderboard}${type}`, data)
    .then((res) => {
      return res;
    });
};

export const getSearchUserList = (data) => {
  return instance_gj
    .post(`${url.apiUrl}${routes.searchUserList}`, data)

    .then((res) => {
      return res;
    });
};
export const getCollegeList = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.collegeList}`, data)
    .then((res) => {
      return res;
    });
};

export const getApplyReward = (data) => {
  return instance
    .post(`${url.accountUrl}${routes.applyReward}`, data)
    .then((res) => {
      return res;
    });
};
export const getClientSecret = (data) => {
  return instance
    .post(`${url.accountUrl}${routes.createPaymentIntent}`, data)
    .then((res) => {
      return res;
    });
};
export const createStripeTransactionWeb = (data) => {
  return instance
    .post(`${url.accountUrl}${routes.createStripeTransactionWeb}`, data)
    .then((res) => {
      return res;
    });
};
export const createStripePaymentIntentWeb = (data) => {
  return instance
    .post(`${url.accountUrl}${routes.createStripePaymentIntentWeb}`, data)
    .then((res) => {
      return res;
    });
};
export const getBuyAvatar = (data) => {
  return instance
    .post(`${url.accountUrl}${routes.buyAvatar}`, data)
    .then((res) => {
      return res;
    });
};

export const getAllPoints = (data) => {
  return instance.post(`${url.apiUrl}${routes.allPoints}`, data).then((res) => {
    return res;
  });
};

export const getAllPointsCategory = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.allPointsCategories}`, data)
    .then((res) => {
      return res;
    });
};

export const getContestRate = (data) => {
  return instance
    .post(`${url.accountUrl}${routes.getContestRate}`, data)
    .then((res) => {
      return res;
    });
};

export const contestRate = (data) => {
  return instance
    .post(`${url.accountUrl}${routes.contestRate}`, data)
    .then((res) => {
      return res;
    });
};

export const getScrimRate = (data) => {
  // return instance
  //   .post(`${SAAS_API_URL}${url.v2Url}${routes.rateScrim}`, data)
  //   .then((res) => {
  //     return res;
  //   });
};

export const getApplyEventCode = (data) => {
  //${url.accountUrl}
  return instance
    .post(`${url.saasApiUrl}${routes.applyEventCode}`, data)
    .then((res) => {
      return res;
    });
};

export const getTicketLists = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.ticketList}`, data)
    .then((res) => {
      return res;
    });
};

export const getGamesDetails = (id) => {
  return instance.get(`${url.gamesUrl}${routes.get}${id}`).then((res) => {
    return res;
  });
};

export const getGamesDetails1 = (id) => {
  return instance.post(`${url.apiUrl}games/${routes.get}${id}`).then((res) => {
    return res;
  });
};

export const getCategoryLists = (data) => {
  return instance
    .post(`${url.saasApiUrl}${routes.categoryList}`, data)
    .then((res) => {
      return res;
    });
};

export const getRankSummeryByGame = (data) => {
  return instance
    .post(`${url.accountUrl}${routes.rankSummeryByGame}`, data)
    .then((res) => {
      return res;
    });
};

export const getSearchGames = (data) => {
  return instance
    .post(`${url.saasApiUrl}${routes.searchGames}`, data)
    .then((res) => {
      return res;
    });
};

export const getContestByGame = (data) => {
  return instance
    .post(`${url.accountUrl}${routes.contestByGame}`, data)
    .then((res) => {
      return res;
    });
};
export const getFollowingList = (data) => {
  return instance
    .post(`${url.accountUrl}${routes.followings}`, data)
    .then((res) => {
      return res;
    });
};
export const getFollowerList = (data) => {
  return instance
    .post(`${url.accountUrl}${routes.followers}`, data)
    .then((res) => {
      return res;
    });
};

export const getUploadSS = (data) => {
  return instance.post(`${url.apiUrl}${routes.uploadSS}`, data).then((res) => {
    return res;
  });
};

export const getCreateComplaint = (data) => {
  return instance_gj
    .post(`${url.apiUrl}${routes.createTicket}`, data)
    .then((res) => {
      return res;
    });
};

export const getAllTicketChat = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.getTicketChat}${data}`, {})
    .then((res) => {
      return res;
    });
};

export const updateAllTicketChat = (id, data) => {
  return instance
    .post(`${url.apiUrl}${routes.updateTicketChat}${id}`, data)
    .then((res) => {
      return res;
    });
};
export const checkUserDetails = (data) => {
  return instance
    .post(`${url.saasApiUrl}${routes.checkUsers} `, data)
    .then((res) => {
      return res;
    });
};

export const getGenrateSignature = (data) => {
  return instance
    .post("https://www.gamerji.com/signature.php", data)
    .then((res) => {
      return res;
    });
};

export const onSignUp = (data) => {
  return instance.post(`${url.authUrl}${routes.signup}`, data).then((res) => {
    return res;
  });
};

export const getCampaign = (data) => {
  return instance
    .post(`${url.publicUrl}${routes.campaignsCompaniesDetails}`, data)
    .then((res) => {
      return res;
    });
};
export const getGoogleRecaptcha = (data) => {
  return instance
    .post(`${url.publicUrl}${routes.verifyRecaptcha}`, data)
    .then((res) => {
      return res;
    });
};
export const requestCreateCFCoinStoreTransactionWeb = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.createCashfreeTransactionWeb}`, data)
    .then((res) => {
      return res;
    });
};
export const requestCFLink = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.requestCashfreePaymentlink}`, data)
    .then((res) => {
      return res;
    });
};

export const getTransactionDetails = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.getTransactionDetails}`, data)
    .then((res) => {
      return res;
    });
};
export const requestLinkBankAccount = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.requestLinkBankAccount}`, data)
    .then((res) => {
      return res;
    });
};
export const requestLinkUpiAccount = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.requestLinkUpiAccount}`, data)
    .then((res) => {
      return res;
    });
};

export const requestVerifyVPA = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.requestVerifyVPA}`, data)
    .then((res) => {
      return res;
    });
};
export const requestWithdrawal = (data) => {
  return instance
    .post(`${url.apiUrl}${routes.requestWithdrawal}`, data)
    .then((res) => {
      return res;
    });
};

export const requestGoogleLogin = (data) => {
  return instance
    .post(`${url.authUrl}${routes.googleLogin}`, data)
    .then((res) => {
      return res;
    });
};

export const getUserInGameName = (data) => {
  return instance
    .post(`${url.saasApiUrl}${routes.getInGameNames}`, data)
    .then((res) => {
      return res;
    });
};
export const addUserInGameName = (data) => {
  return instance
    .post(`${url.saasApiUrl}${routes.addInGameName}`, data)
    .then((res) => {
      return res;
    });
};
export const getContestPlayerList = (id, data) => {
  return instance
    .post(`${url.saasApiUrl}${routes.contestPlayerList}${id}`, data)
    .then((res) => {
      return res;
    });
};

export const getApplyContestCode = (code, data) => {
  return instance
    .post(`${url.saasApiUrl}${routes.applyContestCode}${code}`, data)
    .then((res) => {
      return res;
    });
};

export const getApplyContestCodeType = (code, data) => {
  return instance
    .post(`${url.saasApiUrl}${routes.applyContestCodeType}${code}`, data)
    .then((res) => {
      return res;
    });
};

export const returnPaymentData = (body) => {
  return instance
    .post(`${url.publicUrl}${routes.returnPayment}`, body)
    .then((res) => {
      return res;
    });
};

export const getHowToCreateScrim = (body) => {
  // return instance
  //   .post(`${SAAS_API_URL}${url.v2Url}${routes.howToCreateScrim}`, body)
  //   .then((res) => {
  //     return res;
  //   });
};

//createPhonepeTransaction

export const createPhonepeTransaction = (body) => {
  return instance
    .post(`${base_url}${routes.createPhonepeTransaction}`, body)
    .then((res) => {
      return res;
    });
};
// https://gamerji-api-staging.gamerji.cloud/account/upadte-phonepay-transaction/
export const phonepeStatus = (body) => {
  return instance
    .post(`${base_url}api/account/upadte-phonepay-transaction`, body)
    .then((res) => {
      return res;
    });
};
export const getSettings = (body) => {
  // return instance
  //   .post(`${SAAS_API_URL}${url.v2Url}${routes.getSettings}`, body)
  //   .then((res) => {
  //     return res;
  //   });
  return null;
};
export const createXsollaTransaction = (body) => {
  return instance
    .post(`${base_url}api/account/create-xsolla-transaction`, body)
    .then((res) => {
      return res;
    });
};
export const xsollaStatus = (body) => {
  return instance
    .post(`${base_url}api/account/update-xsolla-transaction`, body)
    .then((res) => {
      return res;
    });
};
export const getAds = (body) => {
  // return instance
  //   .post(`${SAAS_API_URL}${url.v2Url}${routes.getAds}`, body)
  //   .then((res) => {
  //     return res;
  //   });
};
export const xsollaList = (body) => {
  return instance
    .post(`${url.apiUrl}${routes.xsollaList}`, body)
    .then((res) => {
      return res;
    });
};
export const updateCoin = (body) => {
  // return instance
  //   .post(`${SAAS_API_URL}${url.v2Url}${routes.updateCoin}`, body)
  //   .then((res) => {
  //     return res;
  //   });
};

// indigg apis call

export const getFeaturedTournamentList = (data) => {
  return instance
    .post(`${url.indiggSaasUrl}${routes.featuredTournament}`, data)
    .then((res) => {
      return res;
    });
};
