export const constants = {
  // Success Strings
  // edit-profile
  success_editprofile_updated_successfully: "Profile successfully updated!",
  error_Select_your_Gamerji_Avatar: "Select your Gamerji Avatar.",
  // error
  error_enter_phone: "Please enter mobile number",
  error_enter_valid_phone: "Please enter valid mobile number",
  error_Select_date_of_birth: "Select date of birth",
  error_Please_add_Gamerji_name: "Please add Gamerji name",
  error_Please_enter_a_valid_email_address:
    "Please enter a valid email address",
  error_Please_enter_a_valid_mobile_number:
    "Please enter a valid mobile number",
  error_Please_enter_invite_code: "Please enter invite code",
  error_Add_your_Gamerji_name: "Add your Gamerji name.",
  // withdrawal
  error_Please_Enter_Valid_Bank_Account_Details:
    "Please Enter Valid Bank Account Details",
  error_Please_enter_a_valid_withdrawal_amount:
    "Please enter a valid withdrawal amount.",
  error_Minimum_withdrawal_limit: "Minimum withdrawal limit:",
  error_Enter_IFSC_code: "Enter IFSC code",
  error_Enter_bank_name: "Enter bank name",
  error_Account_Number_is_Mismatched: "Account Number is Mismatched",
  error_Please_enter_your_account_number: "Please enter your account number",
  error_Please_enter_a_valid_number_while_confirming_account:
    "Please enter a valid number while confirming account",
  error_Please_Select_withdrawal_method: "Please Select withdrawal method.",
  error_Please_Link_your_UPI_Account: "Please Link your UPI Account.",
  error_Oops_something_went_wrong_please_try_again:
    "Oops something went wrong please try again!",
  error_Please_Link_your_Bank_Account: "Please Link your Bank Account.",
  error_Please_Enter_Maximum_Amount: "Please Enter Maximum Amount ",
  error_Please_Enter_Minimum_Amount_then: "Please Enter Minimum Amount then",
  error_Please_Select_At_leaset_one_Match: "Please select at least one Match!",
  error_Please_add_required_player: "Please add required player",
  error_Please_ask_user_to_add_game_name: "Please ask user to add game name!",
  error_Promo_Code_is_Empty: "Promo Code is Empty!!!",
  error_Please_select_at_least_one_more_game:
    "Please select at least one more game",
  error_Select_atleast_one_game: "Select atleast one game",
  error_Please_enter_a_valid_UPI_ID: "Please enter a valid UPI ID",
  error_Enter_UPI_ID: "Enter UPI ID",
  error_Enter_account_holders_name: "Enter account holders name",
  error_Please_Enter_Withdraw_Amount: "Please Enter Withdraw Amount",
  error_Please_add_required_player: "Please add required player",
  error_Please_Enter_Minimum_Amount: "Please Enter Minimum Amount",
  error_Please_confirm_the_account_number: "Please confirm the account number",
  success_Promo_code_applied_successfully: "Promo code applied successfully",
  success_UPI_Details_successfully_Linked: "UPI Details successfully Linked!",
  success_Bank_Account_Details_successfully_Linked:
    "Bank Account Details successfully Linked!",
  success_Withdrawal_request_submitted_successfully:
    "Withdrawal request submitted successfully",
  success_Favourite_Game_updated_successfully:
    "Favourite Game updated successfully",
  success_Payment_successfull: "Payment successfull",
  info_Copied: "Copied!",
  error_User_not_found: "User not found!",
  error_Please_enter_the_phone_number: "Please enter the phone number!",
  success_Avatar_buy_successfully: "Avatar buy successfully!",
  success_Redeem_Reward_successfully: "Redeem Reward successfully!",
  success_Please_enter_Gamerji_username: "Please enter user name",
  //  tournament
  error_Please_ask_user_to_add_game_name: "Please ask user to add game name!",
  success_Game_Username_updated_successfully:
    "Game Username updated successfully!",
  error_Oops_This_tournament_has_already_started:
    "Oops! This tournament has already started. Please try to join another tournament.",
  error_enter_other_than_yours:
    "These are your details, please enter relevant details apart from yours",
  error_enter_already_added: "Already added this user id",
  error_enter_already_added_email: "Already added this email",
  error_duo_squad_tournament_in_maintenance:
    "Duo/Squad tournaments are currently under maintenance. Stay tuned for updates!",
};
