import axios from "axios";
import url from "../constants/url";
import helperFunc from "../helperFuncs";
// import useToken from "../hooks/useToken";

let loginUserData = localStorage.getItem("loginData");
loginUserData = JSON.parse(loginUserData);

var header = loginUserData?.user_id
  ? { user_id: loginUserData?.user_id }
  : { "user-type": "appUser" };
header["company-code"] = "IG";
if (helperFunc.getCountryId()) header["country"] = helperFunc.getCountryId();
// console.log("headers",header);
const instance = axios.create({
  baseURL: url.apiUrl,
  timeout: 30000,
  headers: header,
});

instance.interceptors.request.use(
  function (config) {
    if (!!config.data) {
      for (let key of Object.keys(config?.data)) {
        if (config.data[key] === "") config.data[key] = null;
      }
    }
    return config;
  },
  function (error) {
    return error; //Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // console.log("response===axi", response);

    if (response.status === 200) {
      return response.data;
    }
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // console.log("error===axi", error);

    if (error?.response?.status === 401) {
      // localStorage.clear();
      // window.location.href = '/sign-in';
    }
    return error?.response;
  }
);

export default instance;
