import React, { useEffect, useState } from "react";
import "./assets/css/bootstrap.min.css";
import "./assets/css/all.min.css";
import "./assets/css/font.css";
import "./assets/css/style.css";
import "./assets/css/new_style.css";
import "./assets/css/new_theam.css";
import "./assets/css/responsive.css";
import { useLocation, Routes, Route } from "react-router-dom";

import HomePage from "./screens/index";
import { Col } from "react-bootstrap";
// import SignIn from "./screens/signin";

import ContestDetails from "./screens/contest-details";
import ContestDetailsOld from "./screens/contest-detail-old";

import Tournaments from "./screens/tournaments";
import { routehelp } from "./config/routehelp";
import SingleGame from "./screens/single-game";

import MyContest from "./screens/my-contest";
import TournamentDetails from "./screens/tournament-details";

import { initializeApp } from "firebase/app";

import { getSettings } from "./apis";
// var mCustomScrollbar = require("malihu-custom-scrollbar-plugin");
export const authUser = (props) => {
  return (
    <Route
      exact
      path={props.path}
      element={
        localStorage.getItem("accessToken") === null ? (
          <HomePage />
        ) : (
          props.element
        )
      }
    />
  );
};

function App() {
  const [nav, setNav] = useState(false);
  const location = useLocation();
  const navMenuClick = () => {
    setNav(!nav);
  };

  var firebaseConfig = {
    apiKey: "AIzaSyBw-xOChQWFv8oS8HF56HffCyc9FaW0I9w",
    authDomain: "https://web.gamerji.com",
    projectId: "fantasyji-esports",
    storageBucket: "fantasyji-esports.appspot.com",
    messagingSenderId: "454874517092",
    appId: "1:454874517092:ios:18d91115f592bfa854d971",
  };

  const fetchData = async () => {
    let data = {
      company: "634082e312598b8e50ef0d78",
    };
    let res = await getSettings(data);
    if (res?.success) {
      if (res?.country?.isXsollaPayment)
        localStorage.setItem("isDefault", res?.country?.isXsollaPayment);
      else {
        localStorage.setItem("isDefault", false);
      }
    } else localStorage.setItem("isDefault", false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  initializeApp(firebaseConfig);

  return (
    <div
      className={`wrapper ${
        (window?.location?.pathname === "/" ||
          window?.location?.pathname === "/more" ||
          window?.location?.pathname === "/ticket-no" ||
          window?.location?.pathname === "/tournaments" ||
          window?.location?.pathname === "/profile" ||
          window?.location?.pathname === "/world-of-esports" ||
          window?.location?.pathname === "/coin-store") &&
        localStorage.getItem("gamerjiToken") !== null &&
        "with-footer"
      } menumain ${nav === true && "navigation"}`}
      id="mobile"
    >
      <div className="row no-gutters d-flex justify-content-center">
        <Col md={5} lg={3}>
          <Routes>
            <Route
              exact
              path={routehelp.default}
              element={<HomePage navMenuClick={navMenuClick} />}
            />
            <Route exact path={routehelp.singlegame} element={<SingleGame />} />
            <Route
              exact
              path={routehelp.tournaments}
              element={<Tournaments />}
            />
            <Route exact path={routehelp.mycontest} element={<MyContest />} />
            <Route
              exact
              path={routehelp.tournamentdetail}
              element={<TournamentDetails />}
            />
            <Route
              exact
              path={routehelp.contestdetails}
              element={<ContestDetails />}
            />
            <Route
              exact
              path={routehelp.contestdetailsold}
              element={<ContestDetailsOld />}
            />
          </Routes>

          {(location.pathname === "/" ||
            location.pathname === "/tournaments") &&
            localStorage.getItem("gamerjiToken") !== null && <></>}
        </Col>
      </div>
    </div>
  );
}

export default App;
