import React from "react";
import { Link } from "react-router-dom";
import {
  onCreateContest,
  checkUserDetails,
  onCreateTournament,
  indiggUserExists,
} from "../apis";
import Previous from "../assets/images/previous.svg";
import rightArrow from "../assets/images/right-arrow.svg";
import url from "../constants/url";
import squadDummy from "../assets/images/squad-dummy.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../component/loader";
import Advertisement from "../component/advertisement";
import { constants } from "../constants/constants";
import { Captioncard } from "./Scrims/Captioncard";
import { SquadPlayerCard } from "./squadPlayerCard";
import validator from "validator";

import { addTracker } from "../component/tracker";

import { trackerEventId } from "../config/tracker";
export default class SquadRegistrationTournaments extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user_data: [],
      players_data: [],
      teamName: "",
      total_players: 0,
      extra_players: 0,
      uniqueIgn: "",
      selectedContest: "",
      disabledCards: [],
      loader: false,
    };

    this.onChangePhoneNo = this.onChangePhoneNo.bind(this);
    this.onChangeInNameGame = this.onChangeInNameGame.bind(this);
    this.onClickApply = this.onClickApply.bind(this);
    this.onClickRemove = this.onClickRemove.bind(this);
    this.onClickSaveTeam = this.onClickSaveTeam.bind(this);
  }

  addTrackData = async (string) => {
    if (localStorage.getItem("loginData")) {
      let userData = JSON.parse(localStorage.getItem("loginData"));
      await addTracker(`userid=${userData?.userId}&${string}`);
    }
  };

  findUserName = () => {
    let activeGameId = localStorage.getItem("activeGameId");
    let gameNames = JSON.parse(localStorage.getItem("userInGameNames"));
    if (gameNames) {
      let index = gameNames.findIndex((obj) => obj.game === activeGameId);
      if (index >= 0) {
        return gameNames[index].userINGameName || "";
      } else {
        return "";
      }
    }
  };

  handleInputChange = (event) => {
    const value = event.target.value;
    this.setState({ teamName: value });
  };

  onChangeInNameGame(e, index) {
    var players_data = [...this.state.players_data];
    players_data[index].inGameName = e.target.value;
    this.setState({ players_data: players_data });
  }

  checkMobileWithTeam(number, index) {
    let record = this.state.players_data.filter(
      (obj) => obj?.playerPhone === number
    );
    if (record.length > 1) {
      return true;
    } else {
      return false;
    }
  }

  onClickRemoveEmail = (index) => {
    var players_dataTemp = [...this.state.players_data];

    players_dataTemp[index] = {
      userId: "",
      inGameName: "",
      gamerJiName: "",
      country: "",
      mobileNo: "",
      isClickOnApplyPlayer: false,
      email: "",
    };

    this.setState({ players_data: players_dataTemp });
    this.setState((prevState) => ({
      disabledCards: prevState.disabledCards.filter(
        (cardIndex) => cardIndex !== index
      ),
    }));
  };

  onClickAddEmail = async (index) => {
    if (
      this.state.players_data[index]?.email === undefined ||
      !this.state.players_data[index].email
    ) {
      this.showError("Please enter the email!");
    } else if (!validator.isEmail(this.state.players_data[index]?.email)) {
      this.showError(constants.error_Please_enter_a_valid_email_address);
    } else if (
      this.state.user_data?.item?.email === this.state.players_data[index].email
    ) {
      this.showError(constants.error_enter_other_than_yours);
    } else if (this.checkMobileWithTeam(this.state.players_data[index].email)) {
      this.showError(constants.error_enter_already_added);
    } else {
      let payload = {
        search: {
          phone: this.state.players_data[index].email,
          //   game:scrim_data.game._id,
        },
      };
      let res = await checkUserDetails(payload);

      if (res && res.success === true) {
        if (res.data.length > 0) {
          var players_data = this.state.players_data;
          players_data[index] = {
            user_id: res?.data[0]?.user,
            gamerjiName: res?.data[0]?.gamerjiName,
            levelImage: res?.data[0]?.userLevel?.level?.featuredImage?.default,
            inGameName: res?.data[0]?.userINGameName,
            isClickOnApplyPlayer: true,
            playerPhone: players_data[index].email,
            email: players_data[index].email,
          };

          this.setState({ players_data: players_data });
          this.setState((prevState) => ({
            disabledCards: [...prevState.disabledCards, index],
          }));
        } else {
          this.showError("Please ask user to add game name!");
        }
      } else {
        this.showError(res.data.errors[0]?.msg);
      }
    }
  };

  componentDidMount() {
    let allFeaturedData = JSON.parse(localStorage.getItem("joinTounament"));
    var uniqueI = "";

    var gameid =
      window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ];

    // this.addTrackData(
    //   `gameCatalogName=${localStorage.getItem(
    //     "activeGame"
    //   )}&gameCatalogId=${localStorage.getItem("activeGameId")}&tournamentId=${
    //     allFeaturedData?._id
    //   }&eventId=${trackerEventId?.Team_Registration_Shown}`
    // );

    var game_ID =
      gameid === "all" ? allFeaturedData?.gameId : allFeaturedData?.game?._id;

    uniqueI = this.findUserName();

    var total_players = this.props.total_players;
    var extra_players = this.props.extra_players;
    var optional_players = parseInt(total_players) + parseInt(extra_players);

    var players_data = [];
    for (let i = 0; i < optional_players; i++) {
      players_data.push({
        user_id: "",
        gamerjiName: "",
        levelImage: "",
        inGameName: "",
        isClickOnApplyPlayer: false,
        phone: "",
      });
    }
    this.setState({
      user_data: JSON.parse(localStorage.getItem("loginData")),
      players_data: players_data,
      total_players: total_players,
      extra_players: extra_players,
      uniqueIgn: uniqueI,
      selectedContest: this.props.selectedContest,
    });
  }

  onChangePhoneNo(e, index) {
    var players_data = this.state.players_data;
    players_data[index].playerPhone = e.target.value;
    this.setState({ players_data: players_data });
  }

  async onClickApply(index) {
    if (
      this.state.players_data[index].playerPhone === "" ||
      this.state.players_data[index].playerPhone === undefined
    ) {
      this.showError("Please enter user id");
    } else if (
      this.state.players_data[index].inGameName === "" ||
      this.state.players_data[index].inGameName === undefined
    ) {
      this.showError("Please enter in game name");
    } else if (
      this.state.user_data?.item?.phone ===
      this.state.players_data[index].playerPhone
    ) {
      this.showError(constants.error_enter_other_than_yours);
    } else {
      // let data = { phone: this.state.players_data[index].playerPhone, "dialingCode": this.state.user_data?.item?.phoneCode };
      let allFeaturedData = JSON.parse(localStorage.getItem("joinTounament"));
      let accessToken = localStorage.getItem("accessToken");
      let gameId = localStorage.getItem("activeGameId");
      let response = await indiggUserExists(
        accessToken,
        this.state.players_data[index].playerPhone,
        gameId
      );

      if (response && response?.success) {
        if (
          response?.data?.user_id != undefined &&
          response?.data?.user_id != "undefined" &&
          response?.data?.user_id != null &&
          response?.data?.user_id != "null" &&
          response?.data?.user_id != ""
        ) {
          var players_data = this.state.players_data;
          players_data[index] = {
            user_id: response?.data?.user_id,
            inGameName:
              players_data[index].inGameName || response?.data?.userINGameName,
            isClickOnApplyPlayer: true,
            playerPhone: players_data[index].playerPhone,
          };

          this.setState({ players_data: players_data });
          this.setState((prevState) => ({
            disabledCards: [...prevState.disabledCards, index],
          }));
        } else {
          this.showError("Please ask user to add game name!");
        }
      } else {
        this.showError(response?.data?.errors?.[0]?.msg || response?.data?.msg);
      }
    }
  }

  onClickRemove(index) {
    var players_data = this.state.players_data;
    players_data[index] = {
      user_id: "",
      gamerjiName: "",
      levelImage: "",
      inGameName: "",
      isClickOnApplyPlayer: false,
      playerPhone: "",
    };
    this.setState((prevState) => ({
      disabledCards: prevState.disabledCards.filter(
        (cardIndex) => cardIndex !== index
      ),
    }));
    this.setState({ players_data: players_data });
  }

  async onClickSaveTeam() {
    this.setState({ loader: true });
    // this.setState({ loader: true });
    this.setState((prevState) => ({
      ...prevState,
      loader: true,
    }));

    console.log(">>>player data", this.state.players_data);
    var gameid =
      window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ];
    if (this.state.teamName.trim() === "") {
      if (this.state.teamName.trim() === "") {
        this.showError("Team name is required");
      } else {
        this.showError("Please enter players detail!");
      }
      return;
    }
    var all_players_id = [];
    let players = [];
    this.state.players_data.forEach((element) => {
      if (
        element?.user_id !== "" &&
        element?.user_id !== null &&
        element?.user_id !== "null" &&
        element?.user_id !== undefined &&
        element?.user_id !== "undefined"
      ) {
        var player = {
          // phone: element.playerPhone,
          // in_game_name: element.inGameName,
          // user_id: element.user_id,
          // gamerjiName: element.gamerjiName,
          // email: element.gamerjiName,
          gamerjiName: element.playerPhone,
          in_game_name: element.inGameName,
          user_id: element.user_id,
        };
        players.push(player);
      }
      if (
        element?.user_id !== "" &&
        element?.user_id !== null &&
        element?.user_id !== "null" &&
        element?.user_id !== undefined &&
        element?.user_id !== "undefined"
      ) {
        all_players_id.push(element?.user_id);
      }
    });

    let allFeaturedData = JSON.parse(localStorage.getItem("joinTounament"));
    if (all_players_id.length >= this.state.total_players) {
      let data = {};
      var temp_allTeamsIds = all_players_id;
      var user_data = JSON.parse(localStorage.getItem("loginData"));
      if (
        gameid === "all" ||
        gameid === "more" ||
        gameid === "" ||
        gameid === "?"
      ) {
        data = {
          team: players,
          contest: this.state.selectedContest,
          isActive: true,
          event: allFeaturedData?.id,
          type: "event",
          uniqueIGN: this.state.uniqueIgn,
          teamName: this.state.teamName,
          user: JSON.parse(localStorage.getItem("loginData"))?.user_id,
          currencyCode: allFeaturedData?.currency?.inCurrency?._id,
          indigg_access_token: localStorage.getItem("accessToken"),
          // phoneNo: user_data?.item?.phone,
        };
      } else {
        data = {
          team: players,
          contest: this.state.selectedContest,
          isActive: true,
          event: allFeaturedData?.id,
          type: "event",
          uniqueIGN: this.state.uniqueIgn,
          teamName: this.state.teamName,
          user: JSON.parse(localStorage.getItem("loginData"))?.user_id,
          currencyCode: allFeaturedData?.currency?.inCurrency?._id,
          indigg_access_token: localStorage.getItem("accessToken"),
        };
      }

      let res = await onCreateTournament(data);

      if (res && res?.success === true) {
        if (gameid === "all" || gameid === "more" || gameid == "") {
          window.location.href = "/tounament-details/" + allFeaturedData?.id; //routehelp.contestdetails;
        } else {
          window.location.href = "/tounament-details/" + allFeaturedData?.id; //routehelp.contestdetails;
        }
      } else {
        // this.setState({ loader: false });
        this.setState((prevState) => ({
          ...prevState,
          loader: false,
        }));
        this.showError(res?.data?.errors?.[0]?.msg);
      }
    } else {
      // this.setState({ loader: false });
      this.setState((prevState) => ({
        ...prevState,
        loader: false,
      }));
      this.showError("Please enter players detail!");
    }
  }

  showError(error) {
    toast.error(error, {
      position: "top-left",
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  render() {
    console.log(this.state.players_data);
    return (
      <div
        className="custom-popup join-team custom_scroll show  "
        id="contact_popup"
        style={{ padding: "0px", height: "100vh" }}
      >
        {this.state.loader ? <Loader /> : <></>}
        {/* {window.location.pathname === '/' ?  <ToastContainer /> : "" } */}
        <div className="header">
          <div className="back-btn">
            <Link to="#" onClick={() => this.props.backButtonSetShowJoinTeam()}>
              <img src={Previous} alt="" />
            </Link>
          </div>
          <h2>Team Registrations</h2>
          <div className="wallet-btn"></div>
        </div>

        <div
          className="body bottom-btn-body"
          style={{
            width: "100%",
            padding: 0,
            margin: 0,
            top: 65,
            position: "absolute",
          }}
        >
          <div className="all_sub_screens_div_related_to_ads">
            <div className="squad_registration_body_scrollable_component mb-4">
              <div className="width relative">
                <div className="scrim-team-name-title">Team name :</div>
                <input
                  className="width scrim-team-name"
                  type="text"
                  maxLength={64}
                  onChange={this.handleInputChange}
                />
              </div>

              <Captioncard />

              <SquadPlayerCard
                total_players={this.state.total_players}
                extra_players={this.state.extra_players}
                selectedContest={this.state.selectedContest}
                backButtonSetShowJoinTeam={this.backButtonSetShowJoinTeam}
                playersData={this.state.players_data}
                onClickApply={this.onClickApply}
                onChangePhone={this.onChangePhoneNo}
                onChangeInNameGame={this.onChangeInNameGame}
                onClickRemove={this.onClickRemove}
                onClickAddEmail={this.onClickAddEmail}
                onClickRemoveEmail={this.onClickRemoveEmail}
                disabledCards={this.state.disabledCards}
              />
              <div className="row squad_registration_caption_player_row">
                <button
                  style={{ width: "100%", margin: "0px 0px 0px 0px" }}
                  className="btn btn-dark main-btn"
                  onClick={() => this.onClickSaveTeam()}
                >
                  Save Team{" "}
                </button>
              </div>
            </div>
          </div>
          <Advertisement
            screen={
              localStorage.getItem("apk_screens_list") === null
                ? { code: "no_results" }
                : JSON.parse(localStorage.getItem("apk_screens_list"))
                    .squadRegistration
            }
            screen_div_class_name=".all_sub_screens_div_related_to_ads"
            screen_type="sub"
          />
        </div>
      </div>
    );
  }
}

// export default SquadRegistration;
