import React, { useEffect, useState } from "react";
import Lottie from "reactjs-lottie";
//import Rule from "../assets/images/rule.svg";
import Rule from "../assets/images/rules_eye_icon_blue.png";
import ReactHtmlParser from "react-html-parser";
import Close from "../assets/images/close.svg";
import Rupee from "../assets/images/rupee.svg";
import Previous from "../assets/images/previous.svg";
import {
  dateFormat,
  dateFullFormat,
  getTournamentFullDateTime,
  getTournamentDateTime,
  getProfiledateFormat,
  checkDobNstateConditions,
} from "./common";
import rightArrow from "../assets/images/right-arrow.svg";
import successCheck from "../assets/images/success.json";
import pay from "../assets/images/pay.svg";
import squadDummy from "../assets/images/squad-dummy.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import {
  getWalletLimit,
  onCreateContest,
  checkUserDetails,
  onCreateTournament,
  getUserInGameName,
} from "../apis";
import { routehelp } from "../config/routehelp";
import { Link, useNavigate } from "react-router-dom";
import url from "../constants/url";
import DobNStates from "../screens/dobnstate";
import SquadRegistration from "../screens/SquadRegistration";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

import Advertisement from "../component/advertisement";
import SquadRegistrationTournaments from "../screens/SquadRegistrationTournaments";
import { constants } from "../constants/constants";

import { FiX } from "react-icons/fi";

import { addTracker } from "../component/tracker";

import { trackerEventId } from "../config/tracker";

import Loader from "../component/loader";

function TournamentsStructure(props) {
  // let allFeaturedData = props?.allFeaturedData;

  // DobNState Screen Name
  const dobNstateScreenName = "tournaments-contests";

  const [showdobnstate, setShowdobnstate] = useState(false);
  const [showRules, setShowRules] = useState(false);
  const [showJoin, setShowJoin] = useState(false);
  const [showJoinTeam, setShowJoinTeam] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [coinStore, setCoinStore] = useState(false);
  const [showWinners, setShowWinners] = useState(false);
  const [coin, setCoin] = useState(0);
  const [firstRoundMatchId, setFirstRoundMatchId] = useState("");
  const [contest, setContest] = useState("");
  const [uniqueIgn, setUniqueIgn] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [rounds, setRounds] = useState([]);
  const [finaleData, setFinaleData] = useState(null);
  const [walletUsage, setWalletUsageData] = useState(null);
  const [allFeaturedData, setAllFeaturedData] = useState([]);
  const [joinTeamData, setJoinTeamData] = useState([]);
  const [allTeamsIds, setAllTeamsIds] = useState([]);
  const [numPlayers, setNumPlayers] = useState([]);
  const [playerPhone, setPlayerPhone] = useState([]);
  const [selectedPlayerData, setSelectedPlayerData] = useState([]);
  const [total_players, setTotal_players] = useState(0);
  const [extra_players, setExtra_players] = useState(0);
  const [progressWidth, setProgressWidth] = useState(0);
  const [userData, setUserData] = useState(null);
  const [currentName, setcurrentName] = useState("");
  const navigate = useNavigate();
  const [timings, setTimings] = useState([]);
  const [isFromJoinViaCode, setIsFromJoinViaCode] = useState(false);
  const [loader, setLoader] = useState(false);

  const addTrackData = async (string) => {
    if (localStorage.getItem("loginData")) {
      let userData = JSON.parse(localStorage.getItem("loginData"));
      await addTracker(`userid=${userData?.userId}&${string}`);
    }
  };

  useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem("loginData")));
    var user_data = JSON.parse(localStorage.getItem("loginData"));
    // console.log("user_data", user_data);
    // console.log("props?.allFeaturedData==555>", props?.allFeaturedData);

    setIsFromJoinViaCode(props.isFromJoinViaCode);
    if (props?.allFeaturedData) {
      // console.log("props ==>", props);
      var per =
        (100 * props?.allFeaturedData?.totalJoinedPlayers) /
          props?.allFeaturedData?.totalPlayers +
        "%";
      setProgressWidth(per);
      setAllFeaturedData(props?.allFeaturedData);

      addTrackData(
        `gameCatalogName=${localStorage.getItem(
          "activeGame"
        )}&gameCatalogId=${localStorage.getItem("activeGameId")}&tournamentId=${
          props?.allFeaturedData?.id
        }&eventId=${trackerEventId?.Tournament_Popup_Shown}`
      );

      var finale_index = props?.allFeaturedData?.allrounds?.findIndex(
        (val) => val?.isFinalRound?.toLowerCase() === "yes"
      );
      // console.log("finale_index: ", finale_index);
      setFinaleData(
        finale_index !== -1
          ? props?.allFeaturedData?.allrounds?.[finale_index]?.matches?.[0]
          : []
      );

      setTournamnetTime();
    }
  }, [props?.allFeaturedData]);

  // navText = () => {
  //   return [
  //     <span key={'1_1'} class='lnr lnr-arrow-up'></span>,
  //     <span key={'2_2'} class='lnr lnr-arrow-down'></span>
  //   ];
  // }
  const setTournamnetTime = () => {
    {
      props?.allFeaturedData?.contestsList?.map((item1, i) => {
        var tempContest = item1;
        // console.log("tempContest=>", tempContest);
        {
          props?.allFeaturedData?.roundDatetime?.map((item, index) => {
            // console.log("Date-Time: ", item);
            var today = new Date();
            var cdate = moment(item?.date).format("y-MM-DD");
            var ctime = moment(item?.time).format("HH:mm:ss");
            var contestDateTime = moment(cdate + " " + ctime);
            if (item.matchId == tempContest.code) {
              // console.log("Date-Time: inner ", item);
              // console.log("Date-Time: contestDateTime ", contestDateTime);
              // console.log("Date-Time: today ", today);

              if (today < new Date(contestDateTime)) {
                // console.log("Date-Time: inner 1 ", item);
                // setTimings([...timings, ...item])
                timings.push(item);
                // return (
                //   <label onClick={() => onSelectTime(item)} key={index}>
                //     <input type="radio" name="radio" />
                //     <span></span>
                //     <p>{getTournamentFullDateTime(item?.date, item?.time)}</p>
                //   </label>
                // );
              }
            }
          });
        }
      });
    }
  };

  const getjoin = async () => {
    setFirstRoundMatchId("");
    localStorage.setItem("joinTounament", JSON.stringify(allFeaturedData));
    localStorage.setItem("activeGameId", allFeaturedData?.gameId);
    localStorage.setItem("activeGame", allFeaturedData?.gameName);
    var tempRound =
      allFeaturedData.roundDatetime?.[allFeaturedData.roundDatetime.length - 1];

    // const tempRound = round1.matches[round1.matches.length - 1]

    var today = new Date();
    var cdate = moment(tempRound.date).format("y-MM-DD");
    var ctime = moment(tempRound.time).format("HH:mm:ss");
    var contestDateTime = moment(cdate + " " + ctime);

    var isShowTournament = true;
    if (today > new Date(contestDateTime)) {
      isShowTournament = false;
    }
    // console.log('isShowTournament: ', isShowTournament);
    // console.log('isShowTournament: ', today);
    // console.log('isShowTournament: ', new Date(contestDateTime));

    if (isShowTournament) {
      var uniqueI = "";
      // let games = JSON.parse(
      //   localStorage.getItem("gamerjiLogindetails")
      // )?.item?.gameNames.find((val) => val?.game === allFeaturedData.gameId);

      localStorage.setItem("tournamentId", allFeaturedData.id);

      let gamesInNameGame = JSON.parse(
        localStorage.getItem("userInGameNames")
      )?.find((val) => val?.game === localStorage.getItem("activeGameId"));

      // localStorage.setItem("activeGameId", allFeaturedData.gameId);
      uniqueI = gamesInNameGame?.userINGameName;

      // console.log('ids::', JSON.parse(localStorage.getItem("gamerjiLogindetails"))?.item?.gameNames, games, allFeaturedData.gameId)

      setUniqueIgn(uniqueI);

      // console.log("code",allFeaturedData.currency)
      let data = {
        event: allFeaturedData.id,
        type: "event",
        currencyCode: allFeaturedData?.currency?.inCurrency?._id,
        // currencyCode: selectedTournament.currency?._id,
        entryFee: allFeaturedData?.entryFee,
      };
      // let response = await getWalletLimit(data);
      // console.log("T Wallet Response: ", response);
      // if (response) {
      //   setCoinStore(
      //     response?.item?.winningAmount >= response?.item?.entryFee
      //       ? false
      //       : true
      //   );
      //   setWalletUsageData(response?.item);
      //   setCoin(response?.item?.winningAmount);
      //   console.log("Coin: ", response?.item?.winningAmount);
      // }

      var temp_dobns_data = JSON.parse(localStorage.getItem("loginData"));
      setCoin(temp_dobns_data?.walletBalance || 0);

      // if (uniqueI === "" || uniqueI === undefined) {
      if (checkDobNstateConditions(dobNstateScreenName)) {
        setShowdobnstate(true);
        // props.dobnstate(true);
      } else {
        addTrackData(
          `gameCatalogName=${localStorage.getItem(
            "activeGame"
          )}&gameCatalogId=${localStorage.getItem("activeGameId")}&tournamentId=${
            allFeaturedData?.id
          }&eventId=${trackerEventId?.Join_Tournament_Popup_Shown}`
        );
        setShowdobnstate(false);
        setcurrentName(uniqueI);
        setShowJoin(true);
      }
    } else {
      toast.error(constants.error_Oops_This_tournament_has_already_started, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const onJoinTournament = async () => {
    setShowConfirm(false);
    setLoader(true);
    if (coinStore == false) {
      //uniqueIgn
      let games = JSON.parse(localStorage.getItem("userInGameNames"))?.find(
        (val) => val?.game === allFeaturedData?.gameId
      );
      var uniqueI = games?.userINGameName;
      setUniqueIgn(uniqueI);
      // console.log("uniqueI=games=>", games);
      var user_data = JSON.parse(localStorage.getItem("loginData"));
      let data = {
        event: allFeaturedData?.id,
        contest: contest,
        isActive: true,
        type: "event",
        currencyCode: allFeaturedData?.currency?.inCurrency?._id,
        inGameUserName: currentName,
        uniqueIGN: currentName,
        user: JSON.parse(localStorage.getItem("loginData"))?.user_id,
        indigg_access_token: localStorage.getItem("accessToken"),
      };
      // {
      //   event: allFeaturedData?.id,
      //   contest: contest,
      //   isActive: true,
      //   type: "event",
      //   uniqueIGN:
      //     uniqueIgn === null || uniqueIgn === ""
      //       ? localStorage.getItem("uniqueIGName")
      //       : uniqueIgn,
      // };

      // Squad Registration
      if (
        allFeaturedData?.gameTypePlayers &&
        parseInt(allFeaturedData?.gameTypePlayers) > 1
      ) {
        var numArr = [];

        var selectedPlayerArr = [];
        for (var i = 1; i < parseInt(allFeaturedData?.gameTypePlayers); i++) {
          numArr.push(i);

          selectedPlayerArr.push({
            num: i,
            user_id: null,
            gamerjiName: null,
            levelImage: null,
            inGameName: null,
          });

          // addTrackData(
          //   `gameCatalogName=${localStorage.getItem(
          //     "activeGame"
          //   )}&gameCatalogId=${localStorage.getItem("activeGameId")}&tournamentId=${
          //     allFeaturedData?.id
          //   }&eventId=${trackerEventId?.Team_Registration_Shown}`
          // );

          setSelectedPlayerData(selectedPlayerArr);
          setNumPlayers(numArr);
          setShowJoinTeam(true);
          setTotal_players(selectedPlayerArr.length);
          setExtra_players(allFeaturedData?.gameTypeExtraPlayers);

          setShowJoinTeam(true);
          setLoader(false);
        }
        // else {
        //   toast.error(constants.error_duo_squad_tournament_in_maintenance, {
        //     position: "top-left",
        //     autoClose: 2000,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        //   });
        // }
        // props.showJoinTeam(true, selectedPlayerArr, contest);
      } else {
        let res = await onCreateTournament(data);
        if (res) {
          // setCreateData(res?.item);routehelp.tournamentdetail
          if (res?.success) {
            setLoader(false);
            console.log("joined");
            // window.location.href = `/tounament-details/${allFeaturedData?.id}`;
            navigate(`/tounament-details/${allFeaturedData?.id}`);
          } else {
            setLoader(false);
            console.log("er", res?.data.errors[0]?.msg);
            toast.error(res?.data.errors[0]?.msg, {
              position: "top-left",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

            // window.location.reload(false);
          }
        } else {
          setLoader(false);
          console.log("error", res.error);
          toast.error(res.errors?.[0]?.msg, {
            position: "top-left",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    } else {
      setLoader(false);
      window.location.href = routehelp.coinstore;
    }
  };

  const onSelectTime = (item) => {
    setFirstRoundMatchId(item.matchId);
    setSelectedDate(getTournamentDateTime(item?.date, item?.time));
    let temp = [];
    var round = item.matchId;
    var contestid = "";
    contestid = allFeaturedData?.contestsList?.find(
      (val) => val?.code === round
    )?._id;

    setContest(contestid);
    allFeaturedData?.allrounds?.map((item1, index) => {
      if (index !== 0) {
        item1?.matches?.map((item2, index) => {
          if (item2.contest.findIndex((val) => val === round) !== -1) {
            round = item2.matchId;
            temp.push({
              round: item1.name,
              date: item2.date,
              time: item2.time,
            });
          }
        });
      }
    });
    setRounds(temp);
  };

  const onNext = () => {
    if (firstRoundMatchId !== "") {
      setShowJoin(false);
      setShowConfirm(true);
    } else {
      toast.error(constants.error_Please_Select_At_leaset_one_Match, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  let findUserName = (gameid) => {
    let gameNames = JSON.parse(localStorage.getItem("userInGameNames"));
    if (gameNames) {
      let index = gameNames.findIndex((obj) => obj.game == gameid);
      if (index > -1) {
        setcurrentName(gameNames[index].userINGameName);
        // return gameNames[index].userINGameName || ""
      } else {
        return "";
      }
    }
  };

  const getdobnstate = (tag) => {
    // setShowjoinpopup(tag);
    var gameid = localStorage.getItem("activeGameId");
    findUserName(gameid);

    setShowdobnstate(tag);

    addTrackData(
      `gameCatalogName=${localStorage.getItem(
        "activeGame"
      )}&gameCatalogId=${localStorage.getItem("activeGameId")}&tournamentId=${
        allFeaturedData?.id
      }&eventId=${trackerEventId?.IGN_Popup_Shown}`
    );

    setShowJoin(true);
    // var gameid =
    //   window.location.href.split("/")[
    //   window.location.href.split("/").length - 1
    //   ];

    // let data = {}

    // gameid === 'all' ? data = { contest: selectedFeaturedData._id, type: "event" }
    // : data = { contest: selectedFeaturedData._id, type: "contest" };

    // let data = { contest: selectedFeaturedData._id, type: "contest" };
    // let response = await getWalletLimit(data);
    // console.log('Wallet Response: ', response);
    // if (response) {
    //   setCoinStore(
    //     response?.item?.coinAmount > response?.item?.entryFee ? false : true
    //   );

    //   setCoin(response?.item?.coinAmount);
    //   setWalletUsageData(response?.item);
    // }
  };

  const cancelDobNstate = () => {
    setShowdobnstate(false);
    // setShowjoinpopup(false);
    setShowJoin(false);
  };

  const backButtonSetShowJoinTeam = () => {
    setShowJoinTeam(false);
  };

  return loader ? (
    <Loader />
  ) : (
    <>
      <div
        className="contest-list contest-tournament-list"
        id="tournament_main"
      >
        {/* {props?.joinCode === false && */}
        {/* {window.location.pathname === '/' && <ToastContainer />} */}
        {/* DOBNSTATE Popup */}
        {showdobnstate ? (
          <DobNStates
            submitDobNstate={getdobnstate}
            cancelDobNstate={cancelDobNstate}
            screen={dobNstateScreenName}
            gameId={localStorage.getItem("activeGameId")}
          />
        ) : (
          ""
        )}

        {/* Tournament/all (all box), Join Via Invite Code (Codes for tournaments) Popup Box  */}
        <div className="box">
          <h3 className="rulesBudget">
            {/* <marquee>{allFeaturedData?.title}</marquee> */}
            {allFeaturedData?.title?.length > 30 ? (
              <marquee behavior="scroll" scrollamount="4">
                {allFeaturedData?.title}
              </marquee>
            ) : (
              allFeaturedData?.title
            )}
            <span className="pointer" onClick={() => setShowRules(true)}>
              {/* <img
                src={Rule}
                alt=""
                style={{ height: "15px", marginRight: "3px" }}
              />{" "} */}
              View Rules
            </span>
          </h3>
          <div className="row tournament_contest_row">
            <div className="col-4 pr-1">
              <div className="img">
                <img
                  src={allFeaturedData?.img}
                  style={{ maxHeight: "135px" }}
                  alt=""
                />
                <div className="img-caption">
                  <h5>{allFeaturedData?.gameType}</h5>
                </div>
              </div>
            </div>
            <div className="col-8">
              <div className="inner_row pt-0 ">
                <div className="inner_item inner_item inner_item_medium">
                  Date{" "}
                  <b>
                    {getProfiledateFormat(allFeaturedData?.startDate)}
                    {/* to{" "}{dateFormat(allFeaturedData?.endDate)} */}
                  </b>
                </div>
                <div className="inner_item inner_item_medium">
                  Rounds <b>{allFeaturedData?.allrounds?.length}</b>
                </div>
                <div className="inner_item inner_item_medium">
                  ID <b>{allFeaturedData?.shortCode}</b>
                </div>
              </div>
              <div className="inner_row bg-primary-linear-gradient">
                {allFeaturedData?.titles?.map((item, index) => {
                  if (item?.name !== "-" && item?.isSelection) {
                    return (
                      <div
                        className="inner_item"
                        key={index}
                        style={{ width: "40%" }}
                      >
                        {item?.name?.length > 15
                          ? item?.name?.substring(0, 15 - 3) + "..."
                          : item?.name}{" "}
                        <b>
                          {item?.value?.length > 15
                            ? item?.value?.substring(0, 15 - 3) + "..."
                            : item?.value}
                        </b>
                      </div>
                    );
                  }
                })}
              </div>
              <div className="inner_row">
                <div className="inner_item inner_item_medium">
                  Prize Pool{" "}
                  <b>
                    {allFeaturedData?.currency?.outCurrency?.code && (
                      <img
                        src={
                          url.imageUrl +
                          allFeaturedData.currency.outCurrency.img.default
                        }
                        alt=""
                        className="coin_img"
                      />
                    )}
                    {/* <img src={allFeaturedData?.coin} className="coin_img" />{" "} */}{" "}
                    {allFeaturedData?.prizePool}
                  </b>
                </div>
                <div className="inner_item inner_item_medium">
                  Winners{" "}
                  <b className="pointer" onClick={() => setShowWinners(true)}>
                    {" "}
                    {allFeaturedData?.winners}{" "}
                    <i className="fas fa-chevron-down"></i>
                  </b>
                </div>
                <div className="inner_item inner_item_medium">
                  Join Using <br />
                  {allFeaturedData?.entryFee > 0 ? (
                    <b>
                      {allFeaturedData?.currency?.inCurrency?.code && (
                        <img
                          src={
                            url.imageUrl +
                            allFeaturedData.currency.inCurrency.img.default
                          }
                          alt=""
                          className="coin_img"
                        />
                      )}{" "}
                      {allFeaturedData?.entryFee}
                    </b>
                  ) : (
                    <b>Free</b>
                  )}
                </div>
              </div>
              <div className="avalible_wrap">
                <div className="inner">
                  <span style={{ width: progressWidth }}></span>
                </div>
                <p className="d-flex justify-content-between">
                  <span>
                    {allFeaturedData?.totalPlayers -
                      allFeaturedData?.totalJoinedPlayers}{" "}
                    players remaining
                  </span>
                  <span>
                    {allFeaturedData?.totalJoinedPlayers} player joined
                  </span>
                </p>
              </div>
            </div>
          </div>

          {/* <div className="inner pl-2">
              <span>Hosted By</span>
              <b>
                {" "}
                <marquee>
                  {allFeaturedData?.hostedby} (
                  {Number(allFeaturedData?.hostrate?.toFixed(2))}&nbsp;
                  <i
                    className="fas fa-star text-dark"
                    style={{ fontSize: "80%" }}
                  ></i>
                  )
                </marquee>
              </b>
            </div> */}
          {allFeaturedData?.isJoined !== true &&
          allFeaturedData?.totalJoinedPlayers >=
            allFeaturedData?.totalPlayers ? (
            <div className="contest_foot p-2 d-flex justify-content-center align-items-center pointer">
              <div className="inner ">
                <a className="">FULL</a>
              </div>
            </div>
          ) : allFeaturedData?.isJoined === true ? (
            <Link
              to={`/tounament-details/${allFeaturedData?.id}`}
              className="contest_foot p-2 d-flex justify-content-center align-items-center pointer"
            >
              <div className="inner ">
                {/* <Link
                  to={`/tounament-details/${allFeaturedData?.id}`}
                  className="d-flex justify-content-center align-items-center"
                > */}
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "25px" }}
                >
                  <div className="inner ">
                    <a className="contest_joining_popup">JOINED</a>
                  </div>
                </div>

                {/* <div
                    style={{ width: "20px", height: "25px", marginLeft: "5px" }}
                  >
                    <Lottie
                      options={{
                        animationData: successCheck,
                        w: "20px",
                      }}
                    />
                  </div> */}
                {/* </Link> */}
              </div>
            </Link>
          ) : (
            <div
              className="contest_foot p-2 d-flex justify-content-center align-items-center pointer"
              onClick={() => getjoin()}
            >
              <div className="inner ">
                <a className="contest_joining_popup">JOIN NOW</a>
              </div>
            </div>
          )}
        </div>
        {/* } */}

        {/* Winning Breakup - Tournaments */}
        {showWinners === true && (
          <div className="tc_popup_bg">
            <div
              className="custom-popup rules custom_scroll show"
              id="contact_popup"
            >
              <div className="popup-head">
                <h3>Winning Breakup</h3>
                <button
                  className="close-popup"
                  onClick={() => setShowWinners(false)}
                >
                  {/* <img src={Close} alt="" /> */}
                  <FiX />
                </button>
              </div>
              <div className="popup-body">
                <div className="all_sub_screens_div_related_to_ads">
                  {allFeaturedData?.pointpool &&
                    allFeaturedData?.pointpool?.length !== 0 && (
                      <div
                        className="list-group main-tab w-90"
                        id="list-tab"
                        role="tablist"
                      >
                        <a
                          className="list-group-item list-group-item-action active"
                          id="list-home-list"
                          data-toggle="list"
                          href="#list-1"
                        >
                          Prize Pool
                        </a>
                        <a
                          className="list-group-item list-group-item-action"
                          id="list-profile-list"
                          data-toggle="list"
                          href="#list-2"
                        >
                          Points
                        </a>
                      </div>
                    )}

                  <div className="tab-content" id="nav-tabContent">
                    <div className="tab-pane active" id="list-1">
                      <div className="prize_pool mb-3 text-center p-2 w-90">
                        <p>Prize Pool</p>
                        <h6>
                          {allFeaturedData?.currency?.outCurrency?.code && (
                            <img
                              src={
                                url.imageUrl +
                                allFeaturedData.currency.outCurrency.img.default
                              }
                              alt=""
                              className="coin_img_big"
                            />
                          )}{" "}
                          {allFeaturedData?.prizePool}
                        </h6>
                      </div>
                      <div className="table-responsive total_table total_table_border mb-3 w-90">
                        <table>
                          <tbody>
                            {allFeaturedData?.prizepool?.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    Rank:{" "}
                                    {item?.rankTo !== "0" &&
                                    item?.rankTo != item?.rankFrom
                                      ? item?.rankFrom + "-" + item?.rankTo
                                      : item?.rankFrom}
                                  </td>
                                  <th className="text-right">
                                    {allFeaturedData?.currency?.outCurrency
                                      ?.code && (
                                      <img
                                        src={
                                          url.imageUrl +
                                          allFeaturedData.currency?.outCurrency
                                            ?.img?.default
                                        }
                                        alt=""
                                        className="coin_img"
                                      />
                                    )}{" "}
                                    {item?.amount}
                                  </th>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {allFeaturedData?.pointpool && (
                      <div className="tab-pane" id="list-2">
                        <div className="table-responsive total_table total_table_border mb-3 w-90">
                          <table>
                            <tbody>
                              {allFeaturedData?.pointpool?.map(
                                (item, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>
                                        Rank:{" "}
                                        {item?.rankTo !== "0" &&
                                        item?.rankTo != item?.rankFrom
                                          ? item?.rankFrom + "-" + item?.rankTo
                                          : item?.rankFrom}
                                      </td>
                                      <th className="text-right">
                                        {item?.point}
                                      </th>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <Advertisement
                  screen={
                    localStorage.getItem("apk_screens_list") === null
                      ? { code: "no_results" }
                      : JSON.parse(localStorage.getItem("apk_screens_list"))
                          .prizePoolPopup
                  }
                  screen_div_class_name=".all_sub_screens_div_related_to_ads"
                  screen_type="sub"
                />
              </div>
            </div>
          </div>
        )}

        {/* Rules - Tournaments */}
        {showRules === true && (
          <div className="tc_popup_bg">
            <div
              className="custom-popup rules custom_scroll show"
              id="contact_popup"
            >
              <div className="popup-head">
                <h3>Rules</h3>
                <button
                  className="close-popup"
                  onClick={() => setShowRules(false)}
                >
                  {/* <img src={Close} alt="" /> */}
                  <FiX />
                </button>
              </div>
              <div className="popup-body mt-3">
                <p>{ReactHtmlParser(allFeaturedData?.rules)}</p>
              </div>
            </div>
          </div>
        )}

        {/* Join Popup - Click on Join Now (any box) - tournamentTimingPopup */}
        {showJoin === true && (
          <div className="tc_popup_bg">
            <div
              className="custom-popup rules custom_scroll join show"
              id="contact_popup"
            >
              <div className="popup-head">
                {/* <marquee> */}
                <h3>
                  {allFeaturedData?.title?.length > 30 ? (
                    <marquee behavior="scroll" scrollamount="4">
                      {allFeaturedData?.title}
                    </marquee>
                  ) : (
                    allFeaturedData?.title
                  )}
                </h3>
                {/* </marquee> */}
                <button
                  className="close-popup"
                  onClick={() => setShowJoin(false)}
                >
                  {/* <img src={Close} alt="" />*/}
                  <FiX />
                </button>
              </div>
              <div className="popup-body">
                <div className="all_sub_screens_div_related_to_ads">
                  <b>
                    {/* {console.log('Test: ', allFeaturedData)} */}
                    {allFeaturedData?.gameName} - {allFeaturedData?.gameType}
                  </b>
                  <p className="error">
                    {dateFullFormat(allFeaturedData?.startDate)} to{" "}
                    {dateFullFormat(allFeaturedData?.endDate)}
                  </p>
                  <p
                    // className="round_time mb-3"
                    className="mb-3"
                    style={{ fontSize: "14px", fontWeight: "600" }}
                  >
                    Select 1st Round Time
                  </p>
                  <div className="radio_wrap custom_scroll">
                    {timings?.map((item, index) => {
                      {
                        /*   var tempContest = item1
                      console.log("tempContest=>", tempContest); */
                      }
                      // {allFeaturedData?.roundDatetime?.map((item, index) => {
                      //   console.log('Date-Time: ', item)
                      //   var today = new Date();
                      //   var cdate = moment(item?.date).format('y-MM-DD');
                      //   var ctime = moment(item?.time).format('HH:mm:ss');
                      //   var contestDateTime = moment(cdate + ' ' + ctime);
                      //   if (item.matchId == allFeaturedData?.contestsList[i].code) {
                      //     console.log('Date-Time: inner ', item)
                      //     console.log('Date-Time: contestDateTime ', contestDateTime)
                      //     console.log('Date-Time: today ', today)

                      //   if (today < new Date(contestDateTime)) {
                      //     console.log('Date-Time: inner 1 ', item)

                      return (
                        <label onClick={() => onSelectTime(item)} key={index}>
                          <input type="radio" name="radio" />
                          <span></span>
                          <p>
                            {getTournamentFullDateTime(item?.date, item?.time)}
                          </p>
                        </label>
                      );
                      //     }
                      //     }
                      //   })
                      // }
                    })}
                  </div>
                  {firstRoundMatchId === "" ? (
                    <b className="select_msg my-3">
                      {" "}
                      Select match to check next round timings{" "}
                    </b>
                  ) : (
                    <div className="rounds">
                      <OwlCarousel
                        className="owl-carousel owl-theme"
                        autoWidth={false}
                        items={1}
                        loop
                        margin={0}
                        // stagePadding={50}
                        autoplay={false}
                        dots={false}
                        nav={true}
                        // navText={[
                        //   <i className="fa fa-arrow-left" aria-hidden="true">hhh</i>,
                        //   <i className="fa fa-arrow-right" aria-hidden="true"></i>,
                        // ]}
                      >
                        {rounds?.map((item, index) => {
                          return (
                            <div className="item" key={index}>
                              <p className="round_name">
                                <b>{item?.round}</b>
                              </p>
                              <p className="round_name">
                                <b>
                                  {getTournamentFullDateTime(
                                    item?.date,
                                    item?.time
                                  )}
                                </b>
                              </p>
                            </div>
                          );
                        })}
                      </OwlCarousel>
                    </div>
                  )}
                  <p className="finale mt-3">
                    <b className="finaleText">
                      FINALE will be scheduled at{" "}
                      {/* {console.log("finaleData::", finaleData)} */}
                      {getTournamentFullDateTime(
                        finaleData?.date,
                        finaleData?.time
                      )}
                      {/* {getTournamentFullDateTime(
                        allFeaturedData?.endDate,
                        allFeaturedData?.endDate
                      )} */}
                    </b>
                  </p>

                  <button
                    className="btn btn-dark main-btn next"
                    onClick={() => onNext()}
                  >
                    NEXT{" "}
                    {/* <img src={rightArrow} alt="" className="mCS_img_loaded" /> */}
                  </button>
                </div>
                <Advertisement
                  screen={
                    localStorage.getItem("apk_screens_list") === null
                      ? { code: "no_results" }
                      : JSON.parse(localStorage.getItem("apk_screens_list"))
                          .tournamentTimingPopup
                  }
                  screen_div_class_name=".all_sub_screens_div_related_to_ads"
                  screen_type="sub"
                />
              </div>
            </div>
          </div>
        )}

        {/* Join Popup - Click on Join Now (any box) - joinContestWalletPopup */}
        {showConfirm === true && (
          <div className="tc_popup_bg">
            <div
              className="custom-popup rules confirm custom_scroll join show"
              id="contact_popup"
            >
              <div className="popup-head">
                {/* <marquee> */}
                <h3>
                  {allFeaturedData?.title?.length > 30 ? (
                    <marquee behavior="scroll" scrollamount="4">
                      {allFeaturedData?.title}
                    </marquee>
                  ) : (
                    allFeaturedData?.title
                  )}
                </h3>
                {/* </marquee> */}
                <button
                  className="close-popup"
                  onClick={() => setShowConfirm(false)}
                >
                  {/* <img src={Close} alt="" /> */}
                  <FiX />
                </button>
              </div>
              <div className="popup-body">
                <div className="all_sub_screens_div_related_to_ads">
                  <p className="game_det">
                    <span>
                      {allFeaturedData?.gameName} - {allFeaturedData?.gameType}
                    </span>
                    <span className="color-dark">{selectedDate}</span>
                  </p>
                  {/* <div className="round_time"> */}
                  <div className="confirmation-tournament">
                    <p className="color-dark">Confirmation</p>
                    {/* {(allFeaturedData.currency?.inCurrency?.code == "coin" ||
                      allFeaturedData.currency?.inCurrency?.code ==
                        "diamond") && (
                      <p className="color-grey-3">
                        Wallet Balance ={" "}
                        <img
                          src={
                            url.imageUrl +
                            allFeaturedData.currency.inCurrency.img.default
                          }
                          style={{ width: "15px" }}
                        />{" "}
                        {coin}{" "}
                      </p>
                    )}
                    {(allFeaturedData.currency?.inCurrency?.code == "inr" ||
                      allFeaturedData.currency?.inCurrency?.code == "INR") && (
                      <p>Balance = ₹ {walletUsage.walletBalance} </p>
                    )} */}
                    {allFeaturedData.currency?.inCurrency?.code && (
                      <p className="color-grey-3">
                        Wallet Balance ={" "}
                        <img
                          src={
                            url.imageUrl +
                            allFeaturedData.currency.inCurrency.img.default
                          }
                          style={{ width: "15px" }}
                        />{" "}
                        {coin}{" "}
                      </p>
                    )}
                  </div>
                  <div className="fee">
                    <p>
                      <b>Entry Fee</b>
                      {allFeaturedData.entryFee === 0 ? (
                        <b>Free</b>
                      ) : allFeaturedData.currency?.inCurrency?.code == "inr" ||
                        allFeaturedData.currency?.inCurrency?.code == "INR" ? (
                        <b>
                          {"₹ "} {allFeaturedData.entryFee}
                        </b>
                      ) : (
                        <b>
                          <img
                            src={
                              url.imageUrl +
                              allFeaturedData.currency.inCurrency.img.default
                            }
                            style={{ width: "15px" }}
                          />{" "}
                          {allFeaturedData.entryFee}
                        </b>
                      )}
                    </p>
                    <p
                      className="d-flex"
                      style={{ justifyContent: "space-between" }}
                    >
                      <span>
                        <img src={pay} alt="" className="mCS_img_loaded mr-2" />
                        To Pay
                      </span>
                      {allFeaturedData.entryFee === 0 ? (
                        <b>Free</b>
                      ) : allFeaturedData.currency?.inCurrency?.code == "inr" ||
                        allFeaturedData.currency?.inCurrency?.code == "INR" ? (
                        <b>
                          {"₹ "}
                          {allFeaturedData.entryFee}
                        </b>
                      ) : (
                        <b>
                          <img
                            src={
                              url.imageUrl +
                              allFeaturedData.currency.inCurrency.img.default
                            }
                            style={{ width: "15px" }}
                          />{" "}
                          {allFeaturedData.entryFee}
                        </b>
                      )}
                    </p>
                  </div>
                  {allFeaturedData?.currency?.joiningNote != "" && (
                    <p className="terms_join" style={{ fontSize: "14px" }}>
                      {allFeaturedData?.currency?.joiningNote}
                    </p>
                  )}
                  {allFeaturedData.levelNote != "" && (
                    <p className="level_note">{allFeaturedData.levelNote}</p>
                  )}
                  <button
                    className="btn btn-dark main-btn next"
                    onClick={() => onJoinTournament()}
                  >
                    JOIN TOURNAMENT{" "}
                    {/* <img src={rightArrow} alt="" className="mCS_img_loaded" /> */}
                  </button>
                </div>
                <Advertisement
                  screen={
                    localStorage.getItem("apk_screens_list") === null
                      ? { code: "no_results" }
                      : JSON.parse(localStorage.getItem("apk_screens_list"))
                          .joinContestWalletPopup
                  }
                  screen_div_class_name=".all_sub_screens_div_related_to_ads"
                  screen_type="sub"
                />
              </div>
            </div>
          </div>
        )}

        {/* SQAUD registrations */}
        {showJoinTeam === true && (
          <SquadRegistrationTournaments
            selectedPlayerData={selectedPlayerData}
            selectedContest={contest}
            backButtonSetShowJoinTeam={backButtonSetShowJoinTeam}
            total_players={total_players}
            extra_players={extra_players}
          />
        )}
      </div>
    </>
  );
}

export default TournamentsStructure;
