// const base_url = "http://35.154.8.149/leagueopsv2api/";
// const client_base_url = "https://web.gamerji.com/";
// const client_base_url = "https://gamerji-pwa-staging.gamerji.cloud/";
const client_base_url = "https://gamerji-pwa-testing.gamerji.cloud/";
//const client_base_url = "http://localhost:3006/"; //##localhost## comment by kuldeep
// const base_url = "https://api.gamerji.tech/";
//const base_url = "https://gamerji-api-staging.gamerji.cloud/";
//https://www.gamerji.me:9171/api/
// const base_url = "http://13.127.144.132/leagueopsv2api/";//https://www.gamerji.me:9171/api/
// export const base_url = "https://gamerji-api-staging.gamerji.cloud/"; //stagging
// const base_url = "http://localhost:9171/"; //##localhost## comment by kuldeep
// export const base_url = "https://api.gamerji.tech/"; //prod
export const base_url = "https://gamerji-api-testing.gamerji.cloud/"; //testing

// export const SAAS_API_URL = 'http://35.154.8.149/saasapidiamond/api/' //local
// export const SAAS_API_URL =
//   "https://gamerji-api-by-eaas-staging.gamerji.cloud/"; //staging
// export const SAAS_API_URL =  "https://gamerji-api-by-eaas-testing.gamerji.cloud/"; //testing
// export const SAAS_API_URL = "http://localhost:9155/"; //##localhost## comment by kuldeep
// 
export const SAAS_API_URL = 'https://api-by-eaas.gamerji.tech/' //prod
//const base_url = "http://35.154.8.149/leagueopsv2api/";
//const base_url = "http://localhost:9171/leagueopsv2api/";//https://www.gamerji.me:9171/api/
const s3_base_url = "https://gamerji-uploads.s3.amazonaws.com";

export const indigg_base_url = "https://indigg-api-production.gamerji.cloud/"; //Production
// export const indigg_base_url = "https://indigg-api-testing.gamerji.cloud/"; //testing
// export const indigg_base_url = "https://indigg-api-staging.gamerji.cloud/"; //staging


export const indigg_saas_base_url = "https://indigg-api-by-eaas-production.gamerji.cloud/"; //Production
// export const indigg_saas_base_url = "https://gamerji-api-by-eaas-testing.gamerji.cloud/"; //testing
// export const indigg_saas_base_url = "https://gamerji-api-by-eaas-staging.gamerji.cloud/"; //staging

export const indigg_refresh_token_url =
  "https://stage-auth-service.devindigg.com/"; //staging

// export const indigg_saas_base_url =
//   "https://gamerji-api-by-eaas-staging.gamerji.cloud/"; //staging
// export const indigg_saas_base_url =  "https://gamerji-api-by-eaas-testing.gamerji.cloud/"; //testing
// export const indigg_saas_base_url = "http://localhost:9155/"; //staging

export const indigg_gamerji_url = "https://stage-gamerji.devindigg.com/";

const url = {
  siteUrl: "https://indigg-pwa-testing.gamerji.cloud",
  // siteUrl:"https://gamerji-pwa-staging.gamerji.cloud/",
  apiUrl: base_url + "api/",
  indiggUrl: indigg_base_url,
  indiggRefreshTokenUrl: indigg_refresh_token_url,
  indiggGamerjiUrl: indigg_gamerji_url,
  indiggSaasUrl: indigg_saas_base_url + "api/indigg/",
  accountUrl: base_url + "api/account/",
  contestUrl: indigg_saas_base_url + "api/contest/",
  contestOldUrl: base_url + "api/contest/",
  authUrl: base_url + "api/auth/",

  publicUrl: base_url + "api/public/",
  eventUrl: base_url + "api/events/",
  eventListUrl: base_url + "api/events/",
  bannerUrl: base_url + "api/banners/",
  usersUrl: base_url + "api/users/",
  gamesUrl: indigg_saas_base_url + "api/game/",
  indiggGamesUrl: indigg_saas_base_url + "api/indigg/game/",
  gamesTypesUrl: indigg_saas_base_url + "api/v2/gameType/",
  uploadUrl: base_url + "api/common/upload-image",
  uploadVideoUrl: base_url + "api/common/upload-video",
  //imageUrl: base_url + "uploads/",+=
  imageUrl: s3_base_url + "/",
  returnUrl: client_base_url + "payment-status/",
  returnMobileUrl: client_base_url + "pg-payment-status/",
  returnWebUrl: client_base_url + "pg-payment-status-web/",
  saasApiUrl: indigg_saas_base_url + "api/",
  v2Url: "api/v2/",
  trackerUrl:
    "https://tracker.indi.gg/?gameId=de2b2997-e272-4922-935b-5de79c01cc82",
  shareUrl: "https://indiggcommunity.page.link/?apn=com.indiggcommunity&link=https://indi.gg/gamerji?path="
  //shareUrl: "http://localhost:3006?path="
};

export default url;
