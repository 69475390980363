import React, { Fragment, useEffect, useState, useRef } from "react";
import Previous from "../assets/images/previous.svg";
import Rule from "../assets/images/rules_eye_icon_blue.png";
import CopyIcon from "../assets/images/copy-icon.svg";
import ReactHtmlParser from "react-html-parser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import {
  getContestMyList,
  getGamesDetails,
  getMyContestList,
  getMyEventsList,
  getMyOldContestList,
} from "../apis";
import {
  dateFormat,
  goback,
  formatAMPM,
  getTournamentDateTime,
  getProfiledateFormat,
} from "../component/common";
import url from "../constants/url";
import { routehelp } from "../config/routehelp";
import Close from "../assets/images/close.svg";
import NoContests from "../assets/images/Group_8748.png";
import NoTournaments from "../assets/images/Group_8752.png";
import Loader from "../component/loader";
import { handleTextAnimation } from "../component/textAnimation";
import { constants } from "../constants/constants";
import { howToJoinGame } from "../apis";

import { FiX } from "react-icons/fi";

import Header from "../component/header";

import JoinViaPath from "./JoinViaPath";

function MyContest(props) {
  const [eventList, setEventList] = useState([]);
  const [newContestList, setNewContestList] = useState([]);
  const [contestList, setContestList] = useState([]);
  const [eventData, setEventData] = useState(null);
  const [contestData, setContestData] = useState(null);
  const [showRules, setShowRules] = useState(false);
  const [showWinners, setShowWinners] = useState(false);
  const [allWinners, setAllWinners] = useState([]);
  const [isSaasContest, setIsSaasContest] = useState([]);
  const [rulesData, setRulesData] = useState("");
  const [loader, setLoader] = useState(false);
  const [limitData, setlimitData] = useState(10);
  const [limitDataEvent, setlimitDataEvent] = useState(10);
  const [selectedTab, setSelectedTab] = useState(1);
  const [page, setPage] = useState(1);
  const [gameData, setGameData] = useState("all");
  const navigate = useNavigate();
  let game = JSON.parse(localStorage.getItem("Game_Details"));

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoader(true);
    if (localStorage.getItem("currentPage")) {
      let allpage = [...JSON.parse(localStorage.getItem("currentPage"))];
      var count = allpage.findIndex((d) => d === window.location.pathname);
      count === -1 && allpage.push(window.location.pathname);
      localStorage.setItem("currentPage", JSON.stringify(allpage));
    } else {
      let allpage = [];
      allpage.push(window.location.pathname);
      localStorage.setItem("currentPage", JSON.stringify(allpage));
    }

    await getContestList(1);
    await getEventList(limitDataEvent);
  };

  const getContestList = async (page) => {
    setPage(page);
    var user_data = JSON.parse(localStorage.getItem("loginData"));

    var payload = {
      search: { game: localStorage.getItem("activeGameId") },
    };
    payload.search.user = user_data?.user_id;
    payload.search.status = [
      "waiting",
      "started",
      "inprogress",
      "inProcess",
      "review",
      "cancelled",
      "completed",
    ];

    payload.pagination = {
      pageNo: page,
      recordPerPage: 10,
      sortBy: "dateTime",
      sortDirection: "desc",
    };

    let res = await getMyContestList(payload);
    if (res) {
      setContestData(res);
      if (res.data && res.success) {
        var tempArr = res.data.map((item) => {
          let tempItem = { ...item };
          tempItem.isSaasContest = true;
          return tempItem;
        });

        setNewContestList([...newContestList, ...tempArr]);
      }
      setLoader(false);
    }
  };

  const getOldContestList = async (limit) => {
    var payload = {
      game: localStorage.getItem("activeTournamnetId"),
      limit: limit,
      skip: 0,
    };
    let res = await getMyOldContestList(payload);
    if (res && res.list) {
      setContestData(res);
      var tempArr = res.list.map((item) => {
        let tempItem = { ...item };
        return tempItem;
      });

      tempArr.sort((a, b) => {
        return new Date(b.dateTime) - new Date(a.dateTime);
      });

      setContestList([...contestList, ...tempArr]);
      setLoader(false);
    }
  };
  const getEventList = async (limit) => {
    let res1 = await getMyEventsList({
      game: localStorage.getItem("activeGameId"),
      limit: limit,
      sortBy: "startDate",
      sort: "desc",
      user: JSON.parse(localStorage.getItem("loginData"))?.user_id,
    });

    setLoader(false);
    if (res1 && res1.list) {
      setEventList(res1?.list);
      setEventData(res1);
    }
  };

  const getWinnerClick = (item, e, isSaasContest) => {
    setShowWinners(true);
    setAllWinners(item);
    setIsSaasContest(isSaasContest);
    if (e && e.stopPropagation) e.stopPropagation();
  };

  const getRulesClick = (item, e) => {
    setShowRules(true);
    setRulesData(item?.rules);
    if (e && e.stopPropagation) e.stopPropagation();
  };

  const openContestDetails = (id) => {
    navigate(`/contest-details/${id}`);
  };
  const openOldContestDetails = (id) => {
    window.location.href = `/contestdetails/${id}`;
  };

  const openEventDetails = (id) => {
    navigate(`/tounament-details/${id}`);
  };

  const getLoadMore = async (type) => {
    setLoader(true);
    if (type === "contest") {
      setlimitData(limitData + 10);
      getContestList(page + 1);
    } else {
      setlimitDataEvent(limitDataEvent + 10);
      await getEventList(limitDataEvent + 10);
    }
  };

  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      // do componentDidMount logic
      mounted.current = true;
    } else {
      // do componentDidUpdate logic
      handleTextAnimation();
    }
  });

  return (
    <div className="inner-wrap" id="my_contest_main_inner">
      <JoinViaPath />
      <ToastContainer />
      <Header
        title={`${localStorage.getItem("activeGame")} Matches`}
        navMenuClick={() => navigate(-1)}
      />
      <div
        className="list-group contest-tab main_tabs mt-4 mb-0"
        id="list-tab"
        role="tablist"
      >
        <a
          className="list-group-item list-group-item-action active"
          id="list-home-list"
          data-toggle="list"
          href="#list-1"
          onClick={() => {
            setSelectedTab(1);
          }}
        >
          CONTESTS
        </a>
        <a
          className="list-group-item list-group-item-action"
          id="list-profile-list"
          data-toggle="list"
          href="#list-2"
          onClick={() => {
            setSelectedTab(2);
          }}
        >
          TOURNAMENTS
        </a>
      </div>

      <div
        className="body mCustomScrollbar _mCS_1 bottom-btn-body mycontest_main pt-0 mt-0"
        id={`${showRules === true || showWinners === true ? "popup_show" : ""}`}
      >
        <div className="all_sub_screens_div_related_to_ads">
          <div className="tab-content h-100" id="nav-tabContent">
            {selectedTab === 1 && (
              <div
                className={`tab-pane ${selectedTab === 1 && "active"} h-100`}
                id="list-1"
              >
                {newContestList.length > 0 &&
                  newContestList?.map((item, index) => {
                    var contest = item.contestData;

                    var per =
                      (100 * contest?.totalJoinedPlayers) /
                        contest?.totalPlayers +
                      "%";

                    let ContestTitleWithGameType =
                      contest?.title +
                      " (" +
                      contest?.gameTypeData[0].name +
                      ")";
                    return (
                      <div
                        className="contest-list contest-tournament-list game_tounament mb-3 margin-bottom-2-5"
                        key={index}
                      >
                        <p className="status_contest bg-dark text-white">
                          {contest?.status}
                        </p>
                        <div
                          className="box"
                          onClick={() => openContestDetails(contest?._id)}
                          style={{ cursor: "pointer", maxHeight: "13.5rem" }}
                        >
                          <h3
                            className="mt-2"
                            style={{ fontSize: "16px", fontWeight: "800" }}
                          >
                            {/* <marquee> */}
                            {ContestTitleWithGameType?.length > 30 ? (
                              <marquee behavior="scroll" scrollamount="4">
                                {ContestTitleWithGameType}
                              </marquee>
                            ) : (
                              ContestTitleWithGameType
                            )}

                            {/* </marquee> */}
                            <span
                              className="pointer"
                              style={{ marginTop: "-20px" }}
                            >
                              ID: {contest?.shortCode}
                            </span>
                          </h3>
                          <div
                            className="row tournament_contest_row"
                            style={{ marginBottom: "-20px" }}
                          >
                            <div className="col-12">
                              <div className="inner_row bg-dark">
                                <div className="inner_item">
                                  Date{" "}
                                  <b>{getProfiledateFormat(contest?.date)}</b>
                                </div>
                                <div className="inner_item">
                                  Time{" "}
                                  <b>{formatAMPM(new Date(contest?.time))}</b>
                                </div>
                                {contest?.titles?.map((item1, index1) => {
                                  if (item1?.name !== "-") {
                                    return (
                                      <div className="inner_item" key={index1}>
                                        {item1?.name?.length > 15
                                          ? item1?.name?.substring(0, 15 - 3) +
                                            "..."
                                          : item1?.name}{" "}
                                        <b>
                                          {item1?.value?.length > 15
                                            ? item1?.value?.substring(
                                                0,
                                                15 - 3
                                              ) + "..."
                                            : item1?.value}
                                        </b>
                                      </div>
                                    );
                                  }
                                })}
                              </div>
                              <div className="inner_row">
                                <div className="inner_item inner_item_medium">
                                  Prize Pool <br />{" "}
                                  <b>
                                    {contest?.currency?.[0]?.outCurrency
                                      ?.code == "INR" && "₹"}
                                    {contest?.currency?.[0]?.outCurrency
                                      ?.code && (
                                      <img
                                        src={
                                          url.imageUrl +
                                          contest?.currency?.[0]?.outCurrency
                                            ?.img?.default
                                        }
                                        alt=""
                                        className="coin_img"
                                      />
                                    )}{" "}
                                    {contest?.prizePool}
                                  </b>
                                </div>
                                <div className="inner_item inner_item_medium">
                                  {contest.winningModel === "perRank"
                                    ? "Winners"
                                    : "Per Kill"}{" "}
                                  {contest.winningModel === "perRank" ? (
                                    <b
                                      className="pointer"
                                      onClick={(e) =>
                                        getWinnerClick(contest, e, true)
                                      }
                                    >
                                      {" "}
                                      {contest?.totalWinners}{" "}
                                      <i className="fas fa-chevron-down"></i>
                                    </b>
                                  ) : (
                                    <b className="">
                                      {contest?.currency?.[0]?.outCurrency
                                        ?.code == "INR" ? (
                                        "₹"
                                      ) : (
                                        <img
                                          src={
                                            url.imageUrl +
                                            contest?.currency?.[0]?.outCurrency
                                              ?.img?.default
                                          }
                                          alt=""
                                          className="coin_img"
                                        />
                                      )}{" "}
                                      {contest?.killPoints}{" "}
                                    </b>
                                  )}
                                </div>
                                <div className="inner_item inner_item_medium">
                                  Join Using <br />{" "}
                                  {contest?.entryFee > 0 ? (
                                    <b>
                                      {contest?.currency?.[0]?.inCurrency
                                        ?.code == "INR" && "₹"}
                                      {contest?.currency?.[0]?.inCurrency
                                        ?.code && (
                                        <img
                                          src={
                                            url.imageUrl +
                                            contest?.currency?.[0]?.inCurrency
                                              ?.img?.default
                                          }
                                          alt=""
                                          className="coin_img"
                                        />
                                      )}{" "}
                                      {contest?.entryFee}
                                    </b>
                                  ) : (
                                    <b>Free</b>
                                  )}
                                </div>
                              </div>
                              <div className="avalible_wrap">
                                <div className="inner">
                                  <span
                                    style={{
                                      width: per,
                                    }}
                                  ></span>
                                </div>
                                <p className="d-flex justify-content-between text-light">
                                  <span className="">
                                    {contest?.totalPlayers -
                                      contest?.totalJoinedPlayers}{" "}
                                    players remaining
                                  </span>
                                  <span className="">
                                    {contest?.totalJoinedPlayers} player joined
                                  </span>
                                </p>
                              </div>
                              <div className="contest_foot my_contest_foot_info contest_details_foot contest_single text-light p-2">
                                <div className="inner inner-text pl-4">
                                  <span>Room ID</span>
                                  <b style={{ fontSize: "12px" }}>
                                    {" "}
                                    {contest?.roomId === ""
                                      ? "-"
                                      : contest?.roomId}
                                  </b>
                                </div>
                                <div className="inner inner-text pl-4">
                                  <span>Password</span>
                                  <b style={{ fontSize: "12px" }}>
                                    {" "}
                                    {contest?.roomPassword === ""
                                      ? "-"
                                      : contest?.roomPassword}
                                  </b>
                                </div>
                                {item?.column > 0 && (
                                  <div className="inner inner-text pl-4">
                                    <span>Slot</span>
                                    <b
                                      className="d-flex justify-content-center align-items-center"
                                      style={{
                                        width: "20px",
                                        fontSize: "12px",
                                        backgroundColor: "var(--dark)",
                                        borderRadius: "4px",
                                      }}
                                    >
                                      {item?.column}
                                    </b>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                    // }
                  })}
                {loader ? (
                  <Loader />
                ) : contestList.length > 0 ? (
                  contestList?.map((item, index) => {
                    var isSaasContest = item.isFromSaasContest ? true : false;

                    if (!isSaasContest && !item.isSaasContest) {
                      var per =
                        (100 * item?.totalJoinedPlayers) / item?.totalPlayers +
                        "%";
                      return (
                        <div
                          className="contest-list contest-tournament-list game_tounament mb-3"
                          key={index}
                        >
                          <p className="status_contest bg-primary">
                            {item?.status}
                          </p>
                          <div
                            className="box"
                            onClick={() => openOldContestDetails(item?._id)}
                            style={{ cursor: "pointer", maxHeight: "13.5rem" }}
                          >
                            <h3
                              className="mt-2"
                              style={{ fontSize: "16px", fontWeight: "800" }}
                            >
                              {item?.title?.length > 30 ? (
                                <marquee behavior="scroll" scrollamount="4">
                                  {item?.title}
                                </marquee>
                              ) : (
                                item?.title
                              )}
                              <span className="pointer">
                                ID: {item?.shortCode}
                              </span>
                            </h3>
                            <div
                              className="row tournament_contest_row"
                              style={{ marginBottom: "-20px" }}
                            >
                              <div className="col-12">
                                <div className="inner_row">
                                  <div className="inner_item">
                                    Date{" "}
                                    <b>{getProfiledateFormat(item?.date)}</b>
                                  </div>
                                  <div className="inner_item">
                                    Time{" "}
                                    <b>{formatAMPM(new Date(item?.time))}</b>
                                  </div>
                                  {item?.titles?.map((item1, index1) => {
                                    if (item1?.name !== "-") {
                                      return (
                                        <div
                                          className="inner_item"
                                          key={index1}
                                        >
                                          {item1?.name?.length > 15
                                            ? item1?.name?.substring(
                                                0,
                                                15 - 3
                                              ) + "..."
                                            : item1?.name}{" "}
                                          <b>
                                            {item1?.value?.length > 15
                                              ? item1?.value?.substring(
                                                  0,
                                                  15 - 3
                                                ) + "..."
                                              : item1?.value}
                                          </b>
                                        </div>
                                      );
                                    }
                                  })}
                                </div>
                                <div className="inner_row bg-dark">
                                  <div className="inner_item inner_item_medium">
                                    Prize Pool <br />{" "}
                                    <b>
                                      {item?.currency?.code == "INR" && "₹"}
                                      {item?.currency?.code && (
                                        <img
                                          src={
                                            url.imageUrl +
                                            item?.currency?.img?.default
                                          }
                                          alt=""
                                          className="coin_img"
                                        />
                                      )}{" "}
                                      {item?.prizePool}
                                    </b>
                                  </div>
                                  <div className="inner_item inner_item_medium">
                                    {item.killPoints === ""
                                      ? "Winners"
                                      : "Per Kill"}{" "}
                                    {item.killPoints === "" ? (
                                      <b
                                        className="pointer"
                                        onClick={(e) =>
                                          getWinnerClick(item, e, isSaasContest)
                                        }
                                      >
                                        {" "}
                                        {item?.totalWinners}{" "}
                                        <i className="fas fa-chevron-down"></i>
                                      </b>
                                    ) : (
                                      <b className="">
                                        <img
                                          src={
                                            url.imageUrl +
                                            item?.currency?.img?.default
                                          }
                                          alt=""
                                          className="coin_img"
                                        />{" "}
                                        {item?.killPoints}{" "}
                                      </b>
                                    )}
                                  </div>
                                  <div className="inner_item inner_item_medium">
                                    Join Using <br />{" "}
                                    {item?.entryFee > 0 ? (
                                      <b>
                                        {item?.currency?.code == "INR" && "₹"}
                                        {item?.currency?.code && (
                                          <img
                                            src={
                                              url.imageUrl +
                                              item?.currency?.img?.default
                                            }
                                            alt=""
                                            className="coin_img"
                                          />
                                        )}{" "}
                                        {item?.entryFee}
                                      </b>
                                    ) : (
                                      <b>Free</b>
                                    )}
                                  </div>
                                </div>
                                <div className="avalible_wrap">
                                  <div className="inner">
                                    <span
                                      style={{
                                        width: per,
                                      }}
                                    ></span>
                                  </div>
                                  <p className="d-flex justify-content-between text-light">
                                    <span className="">
                                      {item?.totalPlayers -
                                        item?.totalJoinedPlayers}{" "}
                                      players remaining
                                    </span>
                                    <span className="">
                                      {item?.totalJoinedPlayers} player joined
                                    </span>
                                  </p>
                                </div>
                                <div className="contest_foot contest_details_foot bg-secondary text-light p-2">
                                  <div className="inner inner-text pl-4">
                                    <span>Room ID</span>
                                    <b style={{ fontSize: "12px" }}>
                                      {" "}
                                      {item?.roomId === "" ? "-" : item?.roomId}
                                    </b>
                                  </div>
                                  <div className="inner inner-text pl-4">
                                    <span>Password</span>
                                    <b style={{ fontSize: "12px" }}>
                                      {" "}
                                      {item?.roomPassword === ""
                                        ? "-"
                                        : item?.roomPassword}
                                    </b>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    } else {
                      var contest = item;
                      if (item.isSaasContest) contest = item.contestData;

                      var per =
                        (100 * contest?.totalJoinedPlayers) /
                          contest?.totalPlayers +
                        "%";
                      return (
                        <div
                          className="contest-list contest-tournament-list game_tounament mb-3"
                          key={index}
                        >
                          <p className="status_contest bg-primary">
                            {contest?.status}
                          </p>
                          <div
                            className="box"
                            onClick={() => openContestDetails(contest?._id)}
                            style={{ cursor: "pointer" }}
                          >
                            <h3 style={{ fontSize: "16px", fontWeight: "800" }}>
                              {/* <marquee>{contest?.title}</marquee> */}
                              {contest?.title?.length > 30 ? (
                                <marquee behavior="scroll" scrollamount="4">
                                  {contest?.title}
                                </marquee>
                              ) : (
                                contest?.title
                              )}
                              <span className="pointer">
                                ID: {contest?.shortCode}
                              </span>
                            </h3>
                            <div
                              className="row tournament_contest_row"
                              style={{ marginBottom: "-20px" }}
                            >
                              <div className="col-12">
                                <div className="inner_row">
                                  <div className="inner_item">
                                    Date{" "}
                                    <b>{getProfiledateFormat(contest?.date)}</b>
                                  </div>
                                  <div className="inner_item">
                                    Time{" "}
                                    <b>{formatAMPM(new Date(contest?.time))}</b>
                                  </div>
                                  {contest?.titles?.map((item1, index1) => {
                                    if (item1?.name !== "-") {
                                      return (
                                        <div
                                          className="inner_item"
                                          key={index1}
                                        >
                                          {item1?.name?.length > 15
                                            ? item1?.name?.substring(
                                                0,
                                                15 - 3
                                              ) + "..."
                                            : item1?.name}{" "}
                                          <b>
                                            {item1?.value?.length > 15
                                              ? item1?.value?.substring(
                                                  0,
                                                  15 - 3
                                                ) + "..."
                                              : item1?.value}
                                          </b>
                                        </div>
                                      );
                                    }
                                  })}
                                </div>
                                <div className="inner_row bg-dark">
                                  <div className="inner_item inner_item_medium">
                                    Prize Pool <br />{" "}
                                    <b>
                                      {contest?.currency?.[0]?.outCurrency
                                        ?.code == "INR" && "₹"}
                                      {contest?.currency?.[0]?.outCurrency
                                        ?.code && (
                                        <img
                                          src={
                                            url.imageUrl +
                                            contest?.currency?.[0]?.outCurrency
                                              ?.img?.default
                                          }
                                          alt=""
                                          className="coin_img"
                                        />
                                      )}{" "}
                                      {contest?.prizePool}
                                    </b>
                                  </div>
                                  <div className="inner_item inner_item_medium">
                                    {/* {contest.killPoints === "" ? "Winners" : "Per Kill"}{" "} */}
                                    {contest.winningModel === "perRank"
                                      ? "Winners"
                                      : "Per Kill"}{" "}
                                    {contest.winningModel === "perRank" ? (
                                      <b
                                        className="pointer"
                                        onClick={(e) =>
                                          getWinnerClick(
                                            contest,
                                            e,
                                            isSaasContest
                                          )
                                        }
                                      >
                                        {" "}
                                        {contest?.totalWinners}{" "}
                                        <i className="fas fa-chevron-down"></i>
                                      </b>
                                    ) : (
                                      <b className="">
                                        {contest?.currency?.[0]?.outCurrency
                                          ?.code == "INR" ? (
                                          "₹"
                                        ) : (
                                          <img
                                            src={
                                              url.imageUrl +
                                              contest?.contestFor
                                                ?.outCurrencyInfo?.img?.default
                                            }
                                            alt=""
                                            className="coin_img"
                                          />
                                        )}{" "}
                                        {contest?.killPoints}{" "}
                                      </b>
                                    )}
                                  </div>
                                  <div className="inner_item inner_item_medium">
                                    Join Using <br />{" "}
                                    {contest?.entryFee > 0 ? (
                                      <b>
                                        {contest?.currency?.[0]?.inCurrency
                                          ?.code == "INR" && "₹"}
                                        {contest?.currency?.[0]?.inCurrency
                                          ?.code && (
                                          <img
                                            src={
                                              url.imageUrl +
                                              contest?.currency?.[0]?.inCurrency
                                                ?.img?.default
                                            }
                                            alt=""
                                            className="coin_img"
                                          />
                                        )}{" "}
                                        {contest?.entryFee}
                                      </b>
                                    ) : (
                                      <b>Free</b>
                                    )}
                                  </div>
                                </div>
                                <div className="avalible_wrap">
                                  <div className="inner">
                                    <span
                                      style={{
                                        width: per,
                                      }}
                                    ></span>
                                  </div>
                                  <p className="d-flex justify-content-between text-light">
                                    <span className="">
                                      {contest?.totalPlayers -
                                        contest?.totalJoinedPlayers}{" "}
                                      players remaining
                                    </span>
                                    <span className="">
                                      {contest?.totalJoinedPlayers} player
                                      joined
                                    </span>
                                  </p>
                                </div>
                                <div className="contest_foot contest_details_foot bg-secondary text-light p-2">
                                  <div className="inner inner-text pl-4">
                                    <span>Room ID</span>
                                    <b style={{ fontSize: "12px" }}>
                                      {" "}
                                      {contest?.roomId === ""
                                        ? "-"
                                        : contest?.roomId}
                                    </b>
                                  </div>
                                  <div className="inner inner-text pl-4">
                                    <span>Password</span>
                                    <b style={{ fontSize: "12px" }}>
                                      {" "}
                                      {contest?.roomPassword === ""
                                        ? "-"
                                        : contest?.roomPassword}
                                    </b>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })
                ) : contestList === null ? (
                  <Loader />
                ) : (
                  // No Contents
                  contestList.length === 0 &&
                  newContestList.length === 0 && (
                    <div className="no_tournament">
                      <img src={NoContests} style={{ height: "50%" }} />
                    </div>
                  )
                )}

                {contestData?.totalPages > page && (
                  <div className="load_more text-center mb-3">
                    <button
                      className="btn btn-dark text-white mt-3"
                      onClick={() => getLoadMore("contest")}
                    >
                      {loader === true ? "Loading ..." : "Load More"}
                    </button>
                  </div>
                )}

                {newContestList.length > 10}
              </div>
            )}

            {selectedTab === 2 && (
              <div
                className={`tab-pane ${
                  selectedTab === 2 && "active"
                } h-100 tournament-mylist`}
                id="list-2"
              >
                {eventList.length > 0 ? (
                  eventList?.map((item, index) => {
                    var per =
                      (100 * item?.totalJoinedPlayers) / item?.totalPlayers +
                      "%";
                    return (
                      <div
                        className="contest-list contest-tournament-list mb-3 "
                        id="tournament_main"
                        key={index}
                        style={{ marginTop: `${index !== 0 ? "50px" : ""}` }}
                      >
                        <p className="status_contest bg-dark text-white">
                          {item?.status}
                        </p>
                        <div
                          className="box"
                          onClick={() => openEventDetails(item?._id)}
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <h3 style={{ fontSize: "16px", fontWeight: "800" }}>
                            <div style={{ marginTop: "10px" }}>
                              {String(item?.title)?.length > 0 &&
                                (item?.title?.length > 30 ? (
                                  <marquee behavior="scroll" scrollamount="4">
                                    {item?.title}
                                  </marquee>
                                ) : (
                                  item?.title
                                ))}
                            </div>

                            <span
                              className="pointer"
                              onClick={(e) => getRulesClick(item, e)}
                            >
                              <img
                                src={Rule}
                                alt=""
                                style={{ height: "15px", marginRight: "3px" }}
                              />{" "}
                              Rules
                            </span>
                          </h3>
                          <div
                            className="row tournament_contest_row"
                            style={{ marginBottom: "-40px" }}
                          >
                            <div className="col-4 pr-1">
                              <div className="img" style={{ height: "100%" }}>
                                <img
                                  style={{ height: "100% !important" }}
                                  src={
                                    url?.imageUrl + item?.featuredImage?.default
                                  }
                                  alt=""
                                />
                                <div className="img-caption">
                                  <h5>{item?.gameTypeAry?.name}</h5>
                                </div>
                              </div>
                            </div>
                            <div className="col-8">
                              <div className="inner_row pt-0">
                                <div className="inner_item inner_item_medium ">
                                  Date{" "}
                                  <b>{getProfiledateFormat(item?.startDate)}</b>
                                </div>
                                <div className="inner_item inner_item_medium">
                                  Rounds <b>{item?.rounds?.length}</b>
                                </div>
                                <div className="inner_item inner_item_medium">
                                  ID <b>{item?.shortCode}</b>
                                </div>
                              </div>
                              <div
                                className="inner_row bg-primary-linear-gradient"
                                style={{ borderRadius: "15px 0 0 15px" }}
                              >
                                {item?.titles?.map((item, index) => {
                                  if (item?.name !== "-") {
                                    return (
                                      <div
                                        className="inner_item w-50"
                                        key={index}
                                      >
                                        {item?.name?.length > 15
                                          ? item?.name?.substring(0, 15 - 3) +
                                            "..."
                                          : item?.name}{" "}
                                        <b>
                                          {item?.value?.length > 15
                                            ? item?.value?.substring(
                                                0,
                                                15 - 3
                                              ) + "..."
                                            : item?.value}
                                        </b>
                                      </div>
                                    );
                                  }
                                })}
                              </div>
                              <div className="inner_row">
                                <div className="inner_item inner_item_medium">
                                  Prize Pool{" "}
                                  <b>
                                    {item.currency?.outCurrency?.code ==
                                      "inr" ||
                                      (item.currency?.outCurrency?.code ==
                                        "INR" &&
                                        "₹")}
                                    {item?.currency?.outCurrency?.code && (
                                      <img
                                        src={
                                          url.imageUrl +
                                          item?.currency?.outCurrency?.img
                                            ?.default
                                        }
                                        alt=""
                                        className="coin_img"
                                      />
                                    )}{" "}
                                    {item?.prizePool}
                                  </b>
                                </div>
                                <div className="inner_item inner_item_medium">
                                  {"Winner"}
                                  <b
                                    className="pointer"
                                    onClick={(e) =>
                                      getWinnerClick(item, e, false)
                                    }
                                  >
                                    {" "}
                                    {item?.totalWinners}{" "}
                                    <i className="fas fa-chevron-down"></i>
                                  </b>
                                </div>
                                <div className="inner_item inner_item_medium">
                                  Join Using <br />{" "}
                                  {item?.entryFee > 0 ? (
                                    <b>
                                      {item?.currency?.inCurrency?.code ==
                                        "INR" && "₹"}
                                      {item?.currency?.inCurrency?.code && (
                                        <img
                                          src={
                                            url.imageUrl +
                                            item?.currency?.inCurrency?.img
                                              ?.default
                                          }
                                          alt=""
                                          className="coin_img"
                                        />
                                      )}{" "}
                                      {item?.entryFee}
                                    </b>
                                  ) : (
                                    <b>Free</b>
                                  )}
                                </div>
                              </div>
                              <div className="avalible_wrap">
                                <div className="inner">
                                  <span style={{ width: per }}></span>
                                </div>
                                <p className="d-flex justify-content-between ">
                                  <span className="">
                                    {item?.totalPlayers -
                                      item?.totalJoinedPlayers}{" "}
                                    players remaining
                                  </span>
                                  <span className="">
                                    {item?.totalJoinedPlayers} player joined
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            className="contest_foot contest_details_foot bg-black-4 text-light"
                            style={{
                              display: "block",
                              top: "58px",
                              borderRadius: "5px 5px 15px 15px",
                            }}
                          >
                            <p
                              className="text-light text-center py-1 m-0 game-border-bottom"
                              style={{
                                fontWeight: "600",
                              }}
                            >
                              {item?.contestsList[item?.contestsList.length - 1]
                                ?.title +
                                " - " +
                                dateFormat(
                                  item?.contestsList[
                                    item?.contestsList.length - 1
                                  ]?.date
                                ) +
                                " " +
                                formatAMPM(
                                  new Date(
                                    item?.contestsList[
                                      item?.contestsList.length - 1
                                    ]?.time
                                  )
                                )}
                            </p>

                            <div className="d-flex p-2">
                              <div className="inner inner-text pl-4">
                                <span>Room ID</span>
                                <b style={{ fontSize: "12px" }}>
                                  {" "}
                                  {item?.contestsList[
                                    item?.contestsList.length - 1
                                  ]?.roomId === ""
                                    ? "-"
                                    : item?.contestsList[
                                        item?.contestsList.length - 1
                                      ]?.roomId}
                                </b>
                              </div>
                              <div className="inner inner-text pl-4">
                                <span>Password</span>
                                <b style={{ fontSize: "12px" }}>
                                  {" "}
                                  {item?.contestsList[
                                    item?.contestsList.length - 1
                                  ]?.roomPassword === ""
                                    ? "-"
                                    : item?.contestsList[
                                        item?.contestsList.length - 1
                                      ]?.roomPassword}
                                </b>
                              </div>
                              {item?.contestsList[item?.contestsList.length - 1]
                                ?.column !== "0" && (
                                <div className="inner inner-text pl-4">
                                  <span>Slot</span>
                                  <b
                                    className="d-flex justify-content-center align-items-center"
                                    style={{
                                      width: "20px",
                                      fontSize: "12px",
                                      backgroundColor: "var(--dark)",
                                      borderRadius: "4px",
                                    }}
                                  >
                                    {" "}
                                    {item?.contestsList[
                                      item?.contestsList.length - 1
                                    ]?.column === ""
                                      ? "-"
                                      : item?.contestsList[
                                          item?.contestsList.length - 1
                                        ]?.column}{" "}
                                  </b>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : eventList === null ? (
                  <Loader />
                ) : (
                  // No Tournaments
                  <div className="no_tournament">
                    <img src={NoTournaments} style={{ height: "50%" }} />
                  </div>
                )}
                {eventData?.count > eventList?.length && (
                  <div className="load_more text-center mb-3">
                    <button
                      className="btn btn-dark text-white mt-5"
                      onClick={() => getLoadMore("event")}
                    >
                      {loader === true ? "Loading ..." : "Load More"}
                    </button>
                  </div>
                )}
              </div>
            )}

            {showRules === true && (
              <div
                className="custom-popup rules custom_scroll show"
                id="contact_popup"
              >
                <div className="popup-head">
                  <h3>Rules</h3>
                  <button
                    className="close-popup"
                    onClick={() => setShowRules(false)}
                  >
                    <FiX />
                  </button>
                </div>
                <div className="popup-body mt-3">
                  <p>{ReactHtmlParser(rulesData)}</p>
                </div>
              </div>
            )}
            {showWinners === true && (
              <div
                className="custom-popup rules custom_scroll show"
                id="contact_popup"
              >
                <div className="popup-head">
                  <h3>Winning Breakup</h3>
                  <button
                    className="close-popup"
                    onClick={() => setShowWinners(false)}
                  >
                    <FiX />
                  </button>
                </div>
                <div className="popup-body mt-3">
                  <div
                    className="all_sub_screens_div_related_to_ads"
                    style={{ marginTop: "0px" }}
                  >
                    {allWinners?.pointpool &&
                      allWinners?.pointpool?.length !== 0 && (
                        <div
                          className="list-group main-tab w-90"
                          id="list-tab"
                          role="tablist"
                        >
                          <a
                            className="list-group-item list-group-item-action active"
                            id="list-home-list"
                            data-toggle="list"
                            href="#prize-1"
                          >
                            Prize Pool
                          </a>
                          <a
                            className="list-group-item list-group-item-action"
                            id="list-profile-list"
                            data-toggle="list"
                            href="#point-2"
                          >
                            Points
                          </a>
                        </div>
                      )}

                    <div className="tab-content" id="nav-tabContent">
                      <div className="tab-pane active" id="prize-1">
                        <div className="prize_pool mb-3 text-center p-2 w-90">
                          <h5>Prize Pool</h5>
                          <h6>
                            {isSaasContest ? (
                              <>
                                {allWinners?.currency?.[0]?.outCurrency?.code ==
                                  "INR" && "₹"}

                                {allWinners?.currency?.[0]?.outCurrency && (
                                  <img
                                    src={
                                      url.imageUrl +
                                      allWinners?.currency?.[0]?.outCurrency
                                        ?.img?.default
                                    }
                                    alt=""
                                    className="coin_img_big"
                                  />
                                )}
                              </>
                            ) : (
                              <>
                                {selectedTab == 1 ? (
                                  <>
                                    {allWinners?.currency?.inCurrency?.cType ==
                                      "in" && "₹"}

                                    {allWinners?.currency?.outCurrency
                                      ?.code && (
                                      <img
                                        src={
                                          url.imageUrl +
                                          allWinners?.currency?.inCurrency?.img
                                            ?.default
                                        }
                                        alt=""
                                        className="coin_img"
                                      />
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {allWinners?.currency?.inCurrency?.cType ==
                                      "in" && "₹"}

                                    {allWinners?.currency.outCurrency?.code && (
                                      <img
                                        src={
                                          url.imageUrl +
                                          allWinners?.currency?.outCurrency?.img
                                            ?.default
                                        }
                                        alt=""
                                        className="coin_img"
                                        style={{
                                          width: "19px",
                                          height: "18px",
                                          marginRight: "5px",
                                        }}
                                      />
                                    )}
                                  </>
                                )}{" "}
                              </>
                            )}{" "}
                            {allWinners?.prizePool}
                          </h6>
                        </div>
                        <div className="table-responsive total_table total_table_border mb-3 w-90">
                          <table>
                            <tbody>
                              {isSaasContest
                                ? allWinners?.prizePoolInfo?.map(
                                    (item1, index1) => {
                                      return (
                                        <tr key={index1}>
                                          <td>
                                            Rank:{" "}
                                            {item1?.rankTo !== "0" &&
                                            item1?.rankTo != item1?.rankFrom
                                              ? item1?.rankFrom +
                                                "-" +
                                                item1?.rankTo
                                              : item1?.rankFrom}
                                          </td>
                                          <th className="text-right">
                                            {allWinners?.contestFor
                                              ?.outCurrencyInfo?.code ==
                                              "INR" && "₹"}
                                            {allWinners?.contestFor
                                              ?.outCurrencyInfo?.code && (
                                              <img
                                                src={
                                                  url.imageUrl +
                                                  allWinners?.contestFor
                                                    ?.outCurrencyInfo?.img
                                                    ?.default
                                                }
                                                alt=""
                                                className="coin_img"
                                              />
                                            )}{" "}
                                            {item1?.amount}
                                          </th>
                                        </tr>
                                      );
                                    }
                                  )
                                : allWinners?.prizePoolInfo?.map(
                                    (item1, index1) => {
                                      return (
                                        <tr key={index1}>
                                          <td>
                                            Rank:{" "}
                                            {item1?.rankTo !== "0" &&
                                            item1?.rankTo != item1?.rankFrom
                                              ? item1?.rankFrom +
                                                "-" +
                                                item1?.rankTo
                                              : item1?.rankFrom}
                                          </td>
                                          <th className="text-right">
                                            {allWinners?.currency?.code ==
                                              "INR" && "₹"}
                                            {allWinners?.contestFor
                                              ?.outCurrencyInfo?.code && (
                                              <img
                                                src={
                                                  url.imageUrl +
                                                  allWinners?.contestFor
                                                    ?.outCurrencyInfo?.img
                                                    ?.default
                                                }
                                                alt=""
                                                className="coin_img"
                                              />
                                            )}{" "}
                                            {item1?.amount}
                                          </th>
                                        </tr>
                                      );
                                    }
                                  )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {allWinners?.pointpool && (
                        <div className="tab-pane" id="point-2">
                          <div className="table-responsive total_table total_table_border mb-3 w-90">
                            <table>
                              <tbody>
                                {allWinners?.pointpool?.map((item1, index1) => {
                                  return (
                                    <tr key={index1}>
                                      <td>
                                        Rank:{" "}
                                        {item1?.rankTo !== "0"
                                          ? item1?.rankFrom +
                                            "-" +
                                            item1?.rankTo
                                          : item1?.rankFrom}
                                      </td>
                                      <th className="text-right">
                                        {item1?.point}
                                      </th>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyContest;
