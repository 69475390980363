import React, { Fragment, useEffect, useState } from "react";
import TournamentTitle from "../assets/images/tournament-title.svg";
import {
  getFeaturedTournamentList,
  checkUserDetails,
  onCreateContest,
  getApplyEventCode,
  getUserInGameName,
} from "../apis";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { dateFormat } from "../component/common";
import url from "../constants/url";
import Loader from "../component/loader";
import Close from "../assets/images/close.svg";
import Rupee from "../assets/images/rupee.svg";
import Coin from "../assets/images/coin.png";
import TournamentsStructure from "../component/tournaments-structure";
import { Link } from "react-router-dom";
import Previous from "../assets/images/previous.svg";
import squadDummy from "../assets/images/squad-dummy.png";
import rightArrow from "../assets/images/right-arrow.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { constants } from "../constants/constants";

import { FiX } from "react-icons/fi";

function Featured(props) {
  const [allData, setAllData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [showContacts, setShowContacts] = useState(false);
  const [showjoinpopup, setShowjoinpopup] = useState(true);
  const [showJoinTeam, setShowJoinTeam] = useState(false);
  const [allFeaturedData, setAllFeaturedData] = useState({});
  const [userData, setUserData] = useState(null);
  const [selectedPlayerData, setSelectedPlayerData] = useState([]);
  const [allTeamsIds, setAllTeamsIds] = useState([]);
  const [uniqueIgn, setUniqueIgn] = useState("");
  const [playerPhone, setPlayerPhone] = useState([]);
  const [selectedContest, setSelectedContest] = useState(null);

  useEffect(() => {
    fetchData();
  }, [props?.showData]);

  useEffect(() => {
    if (props?.joinpopup === false) {
      showFeatureTounaments(JSON.parse(localStorage.getItem("joinTounament")));
      setShowContacts(true);
      setShowjoinpopup(false);
    }
  }, [props.joinpopup]);

  let findUserName = () => {
    let activeGameId = localStorage.getItem("activeGameId");
    let gameNames = JSON.parse(localStorage.getItem("userInGameNames"));
    if (gameNames) {
      let index = gameNames?.findIndex((obj) => obj?.game === activeGameId);
      if (index >= 0) {
        return gameNames[index].userINGameName || "";
      } else {
        return "";
      }
    }
  };

  const fetchData = async () => {
    setLoader(true);

    let response = await getFeaturedTournamentList();
    if (response) {
      let temp = [];
      response?.data?.length &&
        response?.data?.map(async (item, index) => {
          temp.push({
            allData: item,
            img: url.imageUrl + item?.featuredImage?.default,
            coin: url.imageUrl + item?.currency?.[0]?.outCurrency?.img?.default,
            id: item?._id,
            title: item?.title,
            gameId: item?.gameAry?._id,
            gameName: item?.gameAry?.name,
            gameType: item?.gameTypeAry?.name,
            gameTypePlayers: item?.gameTypeAry?.players, // not present
            gameTypeExtraPlayers: item?.gameTypeAry?.extraPlayers, // not present
            createdAt: dateFormat(item?.createdAt),
            updatedAt: dateFormat(item?.updatedAt), // not present
            prizePool: item.prizePool,
          });
        });
      setAllData(temp);
      setLoader(false);
    }
  };

  const addPlayers = async (phone, phoneCode, index) => {
    let data = { phone: phone, dialingCode: phoneCode };
    let response = await checkUserDetails(data);
    if (response && response?.success == true) {
      var selectedPlayerArr = selectedPlayerData;
      selectedPlayerArr[index] = {
        user_id: response?.item?._id,
        gamerjiName: response?.item?.gamerjiName,
        levelImage: response?.level?.featuredImage?.default,
        inGameName: response?.item?.gameNames.find(
          (val) => val?.game === allFeaturedData.id
        ),
      };
      setSelectedPlayerData(selectedPlayerArr);
      let temp = [...allTeamsIds];
      temp.push(response?.item?._id);
      setAllTeamsIds(temp);
    } else {
      toast.error(constants.error_Please_enter_a_valid_mobile_number, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const showFeatureTounaments = async (data) => {
    localStorage.setItem("joinTounament", JSON.stringify(data));
    let response = await getApplyEventCode({
      code: data?.allData.shortCode.toUpperCase(),
      user: JSON.parse(localStorage.getItem("loginData"))?.user_id,
    });

    if (response?.success) {
      localStorage.setItem("activeGame", response?.item?.gameAry[0]?.name);
      localStorage.setItem("tournamentId", response?.item?._id);
      localStorage.setItem("activeGameId", response?.item?.gameAry[0]?._id);
      localStorage.setItem(
        "activeGameType",
        JSON.stringify(response?.item?.gameTypeAry)
      );

      getUserInGameNameInfo();

      let tempContestList = [];

      for (let i = 0; i < response?.item?.contestsList.length; i++) {
        if (
          response?.item?.contestsList[i]?.totalJoinedPlayers <
          response?.item?.contestsList[i]?.totalPlayers
        ) {
          tempContestList.push(response?.item?.contestsList[i]);
        }
      }

      let temp = {
        id: response?.item?._id,
        img: url.imageUrl + response?.item?.featuredImage?.default,
        coin: url.imageUrl + response?.item?.currency?.img?.default,
        title: response?.item?.title,
        contestsList: tempContestList, // not present
        totalPlayers: response?.item?.totalPlayers, // not present
        isJoined: response?.item?.isJoined, // not present
        totalJoinedPlayers: response?.item?.totalJoinedPlayers, // not present
        shortCode: response?.item?.shortCode, // not present
        gameName: response?.item?.gameAry[0]?.name,
        gameId: response?.item?.gameAry[0]?._id,
        levelNote: response?.item?.game?.settings?.levelNote, // not present
        winners: response?.item?.totalWinners, // not present
        gameType: response?.item?.gameTypeAry?.name,
        gameTypePlayers: response?.item?.gameTypeAry?.players, // not present
        gameTypeExtraPlayers: response?.item?.gameTypeAry?.extraPlayers, // not present

        hostedby: response?.item?.host?.name, // not present
        hostrate: response?.item?.host?.rate, // not present
        entryFee: response?.item?.entryFee, // not present
        titles: response?.item?.titles, // not present
        rules: response?.item?.rules, // not present
        allrounds: response?.item?.rounds, // not present
        round: response?.rounds?.item?.length, // not present
        winningCoin: response?.item?.totalWinningsDistributedCoin, // not present
        startDate: response?.item?.startDate,
        endDate: response?.item?.endDate,
        prizepool: response?.item?.prizePoolInfo, // not present
        prizePool: response?.item?.prizePool,
        pointpool: response?.item?.pointpool, // not present
        currency: response?.item?.currency[0],
        roundDatetime: response?.item?.rounds[0]?.matches, // not present
        inGameName: findUserName(),
        createdAt: dateFormat(response?.item?.createdAt),
        updatedAt: dateFormat(response?.item?.updatedAt), // not present
      };
      setAllFeaturedData(temp);
      setShowContacts(true);
    }
  };

  const getUserInGameNameInfo = async () => {
    let payload = {
      search: {
        user: JSON.parse(localStorage.getItem("loginData"))?.user_id,
      },
    };

    let userInGameNameResponse = await getUserInGameName(payload);
    localStorage.setItem(
      "userInGameNames",
      JSON.stringify(userInGameNameResponse.data)
    );
  };

  const setPhoneNo = (phone, index) => {
    var phoneArr = playerPhone;
    phoneArr[index] = phone;
    setPlayerPhone(phoneArr);
  };

  const getdobnstate = (val) => {
    props.dobnstate(val);
    setShowjoinpopup(val);
  };

  useEffect(() => {
    props.showContacts(showContacts);
  }, [showContacts]);

  const GetshowJoinTeam = (val, data, contest) => {
    let games = JSON.parse(
      localStorage.getItem("gamerjiLogindetails")
    )?.item?.gameNames.find((val) => val?.game === allFeaturedData?.gameId);
    var uniqueI = games?.uniqueIGN;
    setUniqueIgn(uniqueI);
    setSelectedContest(contest);
    setSelectedPlayerData(data);
    setShowJoinTeam(val);
  };

  const createDuoTournament = async () => {
    debugger;
    let allFeaturedData = JSON.parse(localStorage.getItem("joinTounament"));
    if (allTeamsIds.length == parseInt(allFeaturedData.gameTypePlayers) - 1) {
      let data = {
        team: allTeamsIds,
        contest: selectedContest,
        isActive: true,
        event: allFeaturedData?.id,
        type: "event",
        uniqueIGN: uniqueIgn,
        indigg_access_token: localStorage.getItem("accessToken"),
      };
      let res = await onCreateContest(data);
      if (res) {
        window.location.href = "/tournament-details/" + allFeaturedData?.id; //routehelp.contestdetails;
      }
    } else {
      toast.error(constants.error_Please_add_required_player, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const ref = React.useRef();

  const tempTest = (width) => {
    return true;
  };

  let colorsOne = ["#FFC609", "#43F2FF", "#09FFC4"];

  return (
    <>
      <ToastContainer />
      {allData?.length > 0 ? (
        <Fragment>
          <h2 className="icon-title">
            <span className="icon">
              <img src={TournamentTitle} alt="" />
            </span>
            Featured Tournaments
          </h2>
          {loader ? (
            ""
          ) : (
            <div className="tournament_items">
              <OwlCarousel
                className="owl-carousel owl-theme"
                autoWidth={false}
                items={3}
                loop={false}
                margin={0}
                autoplay={false}
                dots={false}
                nav={true}
              >
                {allData?.map((item, index) => {
                  let gameTitle = item?.gameName + " - " + item?.gameType;
                  return (
                    <div className="item" key={index}>
                      <div
                        className="d-block pointer"
                        onClick={() => showFeatureTounaments(item)}
                      >
                        <div className="box">
                          <div className="img">
                            <img src={item.img} alt="" />

                            <div className="img-caption">
                              <div className="marquee-div">
                                <h3 className="">
                                  {item?.title?.length > 15 ? (
                                    <marquee behavior="scroll" scrollamount="4">
                                      {item?.title}
                                    </marquee>
                                  ) : (
                                    item?.title
                                  )}
                                </h3>
                              </div>
                              <div className="marquee-div">
                                <h6>
                                  {gameTitle?.length > 15 ? (
                                    <marquee behavior="scroll" scrollamount="4">
                                      {gameTitle}
                                    </marquee>
                                  ) : (
                                    gameTitle
                                  )}
                                </h6>
                              </div>
                            </div>
                          </div>
                          <h5 className="text-center coin_featured pt-1">
                            <img src={item.coin} alt="" />
                            <span>{item.prizePool}</span>
                          </h5>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </OwlCarousel>
            </div>
          )}

          {showContacts === true && (
            <div className="tc_popup_bg">
              <div
                className="custom-popup home-featured show"
                id="contact_popup"
              >
                <div className="popup-head bg-light">
                  <h3>{allFeaturedData?.gameName}</h3>
                  <button
                    className="close-popup"
                    onClick={() => setShowContacts(false)}
                  >
                    <FiX />
                  </button>
                </div>
                <div className="popup-body">
                  <TournamentsStructure allFeaturedData={allFeaturedData} />
                </div>
              </div>
            </div>
          )}
        </Fragment>
      ) : (
        <></>
      )}
    </>
  );
}
export default Featured;
